
import React, { useState } from 'react';
import { signupFields } from '../components/constants/formFields'
import { selectEstado } from '../components/constants/estados'
import FormAction from "./FormAction";
import SingleInput from "./SingleInput"
import Checkbox from './Checkbox'
import Select from 'react-select'
import { CiCircleCheck } from 'react-icons/ci'
import { formatarTelefone } from "./Form/helper";
import { FaArrowLeft } from 'react-icons/fa'
import Modal from './Modal'
import TermosCondicoesDeUso from './ModalContent/TermosCondicoesDeUso'

const fields=signupFields;
let fieldsState={};
const selectCidade = [];

fields.forEach(field => fieldsState[field.id]='');

// function formatarCpf(cpf) {
//     return cpf.replace(/\D/g, '')
//       .replace(/(\d{3})(\d)/, '$1.$2')
//       .replace(/(\d{3})(\d)/, '$1.$2')
//       .replace(/(\d{3})(\d)/, '$1-$2')
//       .replace(/(-\d{2})\d+?$/, '$1')
// }

const selectTipoCliente = [
    { value: 'nacional', label: 'Nacional' },
    { value: 'internacional', label: 'Internacional' },
  ]

export default function Cadastro(){
    console.log(selectEstado);
    const [tipoCliente, setTipoCliente] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [cidade, setCidade] = useState("");
    const [estado, setEstado] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [cargo, setCargo] = useState("");
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [termos, setTermos] = useState(false);
    const [comunicacao_email, setComunicacaoEmail] = useState(true);
    const [comunicacao_whatsapp, setComunicacaoWhatsapp] = useState(true);
    const [comunicacao_sms, setComunicacaoSMS] = useState(true);

    // const [signupState,setSignupState]=useState(fieldsState);
    const [signupErrorState,setSignupErrorState]=useState(fieldsState);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusDaConfirmacao, setStatusDaConfirmacao] = useState(false);

    const [estadoSelecionado, setEstadoSelecionado] = useState("");
    const [cidadeSelecionada, setCidadeSelecionada] = useState("");

    // const navigate = useNavigate();

    // const handleChange=(e)=>setSignupState({...signupState,[e.target.id]:e.target.value});

    const [showModalTermos, setOpenModalTermos] = useState(false);
    const openModalTermos = () => {
        setOpenModalTermos(true);
    }
    const closeModalTermos = () => {
        setOpenModalTermos(false);
    }

    const handleCheckbox = () => {
        setTermos(!termos);
    }

    const handleCheckboxEmail = () => {
        setComunicacaoEmail(!comunicacao_email);
    }

    const handleCheckboxWhatsapp = () => {
        setComunicacaoWhatsapp(!comunicacao_whatsapp);
    }

    const handleCheckboxSMS = () => {
        setComunicacaoSMS(!comunicacao_sms);
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        createAccount();
    }

    async function createAccount() {
        setLoading(true);
        const signupData = {
            name:name,
            email:email,
            password:password,
            confirm_password:confirmpassword,
            cidade:cidade,
            estado:estado,
            telefone:telephone,
            cargo:cargo,
            empresa:empresa,
            localidade:tipoCliente,
            termo_uso:termos,
            comunicacao_email:comunicacao_email,
            comunicacao_whatsapp:comunicacao_whatsapp,
            comunicacao_sms:comunicacao_sms,
        };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(signupData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            if(res.response.success === true){
                setAlert(false);
                setStatusDaConfirmacao(true);

            }else{
                setAlert(true);
                let camposErro = Object.keys(res.response.data);
                let mensagemErro = Object.values(res.response.data);
                let cont = 0;
                
                signupErrorState['cidade'] = '';
                signupErrorState['email'] = '';
                signupErrorState['estado'] = '';
                signupErrorState['telefone'] = '';
                signupErrorState['name'] = '';
                signupErrorState['localidade'] = '';
                signupErrorState['cargo'] = '';
                signupErrorState['empresa'] = '';
                signupErrorState['termo_uso'] = '';
                signupErrorState['password'] = '';
                signupErrorState['confirm_password'] = '';

                setSignupErrorState(signupErrorState);
                if(camposErro.length > 0){
                    camposErro.map((item) => {
                        if(typeof mensagemErro[cont] !== "string"){
                            signupErrorState[item] = mensagemErro[cont];
                        } else{
                            signupErrorState[item] = mensagemErro[cont].trim();
                        }
                        cont++;
                    });
                }
                setSignupErrorState(signupErrorState);         
                setAlertMsg(res.response.message);
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const definirCidades = (estado, buscar = '') => {
        if(estado !== ''){
            setLoading(true);
            setCidade('');
            setCidadeSelecionada('');
            fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                while(selectCidade.length > 0) {
                    selectCidade.pop();
                }
                data.map((items =>
                    selectCidade.push({value:items.nome, label:items.nome})
                ));
                // if(buscar !== ''){
                //     const itemCidade = selectCidade.find(({ value: v }) => v === buscar ); 
                //     //setCidadeSelecionada(itemCidade);
                // }
            }).catch((err) => {
                setAlert(true);
                setAlertMsg("Error in connection.");
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };

//   const createAccount=async ()=>{
//     //INTEGRAÇÃO CADASTRO AQUI
    
//   }
    return(
        <>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                {(statusDaConfirmacao) ?
                    (<>
                        <div className='shadow-xl shadow-emerald-100 border border-emerald-100 px-6'>
                            <CiCircleCheck className='text-[#4DEE9E] text-7xl flex justify-center mx-auto -mt-8'/>
                            <h1 className='flex justify-center mt-4 text-4xl font-semibold text-[#414141] mb-3'>Cadastro realizado!</h1>
                            <span className='text-center text-sm justify-center flex text-[#414141]'>Seu cadastro foi realizado com sucesso!</span>
                            <span className='text-center text-sm justify-center flex text-[#414141] mb-3'>Verifique seu e-mail antes de realizar seu primeiro login.</span>
                        </div>
                    </>
                ) : ''}
                <div style={{display:(alert? "block": "none")}} className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">{alertMsg}</div>
            
                <div style={{display:(!statusDaConfirmacao? "block": "none")}} className="">    
                    <Select 
                        className="mb-2 mr-3"
                        placeholder="Cliente Nacional ou Internacional?"
                        isClearable={false} 
                        options={selectTipoCliente}
                        onChange={(tipoCliente) => setTipoCliente(tipoCliente.value)}
                        />
                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['localidade'] !== '' ? "block": "none") }}>{signupErrorState['localidade'] !== '' ? signupErrorState['localidade'] : ''}</span>
                    <div className="grid grid-cols-2">
                        <div className='mr-3 col-span-full'>
                            <SingleInput label="Nome Completo"
                                        name="username"
                                        type="text"
                                        customClass={(alert && signupErrorState['name'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={name}
                                        onChange={(v) => setName(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['name'] !== '' ? "block": "none") }}>{signupErrorState['name'] !== '' ? signupErrorState['name'] : ''}</span>
                        </div>
                        <div className='mr-3'>
                            <SingleInput label="Empresa"
                                        name="empresa"
                                        type="text"
                                        customClass={(alert && signupErrorState['empresa'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={empresa}
                                        onChange={(v) => setEmpresa(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['empresa'] !== '' ? "block": "none") }}>{signupErrorState['empresa'] !== '' ? signupErrorState['empresa'] : ''}</span>
                        </div>
                        <div className='mr-3'>
                            <SingleInput label="Cargo"
                                        name="cargo"
                                        type="text"
                                        customClass={(alert && signupErrorState['cargo'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={cargo}
                                        onChange={(v) => setCargo(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['cargo'] !== '' ? "block": "none") }}>{signupErrorState['cargo'] !== '' ? signupErrorState['cargo'] : ''}</span>
                        </div>
                        <div className='mr-3 col-span-full'>
                            <SingleInput label="E-mail"
                                        name="email"
                                        type="email"
                                        customClass={(alert && signupErrorState['email'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={email}
                                        onChange={(v) => setEmail(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['email'] !== '' ? "block": "none") }}>{signupErrorState['email'] !== '' ? signupErrorState['email'] : ''}</span>

                            <SingleInput label="Telefone"
                                        name="telephone"
                                        type="tel"
                                        customClass={(alert && signupErrorState['telefone'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={telephone}
                                        onChange={(v) => setTelephone(formatarTelefone(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['telefone'] !== '' ? "block": "none") }}>{signupErrorState['telefone'] !== '' ? signupErrorState['telefone'] : ''}</span>

                            {(tipoCliente === 'nacional') ? 
                            <>
                                <Select 
                                    className="mb-8"
                                    placeholder="Selecione um Estado"
                                    options={selectEstado.map((guest, index) => {
                                    return {
                                        label: guest.label,
                                        value: guest.value,
                                        key: index
                                    };
                                    })}
                                    onChange={(item) => {
                                        setEstado(item.value);
                                        setEstadoSelecionado(item);
                                        definirCidades(item.value);
                                    }}
                                    value={estadoSelecionado}
                                />
                                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['estado'] !== '' ? "block": "none") }}>{signupErrorState['estado'] !== '' ? signupErrorState['estado'] : ''}</span>

                                <Select 
                                    className="mb-8"
                                    placeholder="Selecione uma Cidade"
                                    isClearable={false} 
                                    options={selectCidade}
                                    onChange={(item) => {
                                        setCidade(item.value);
                                        setCidadeSelecionada(item);
                                    }}
                                    value={cidadeSelecionada}
                                />
                                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['cidade'] !== '' ? "block": "none") }}>{signupErrorState['cidade'] !== '' ? signupErrorState['cidade'] : ''}</span>
                                </>
                                :
                                <>
                                    <SingleInput label="Estado"
                                    name="estado"
                                    type="text"
                                    customClass={(alert && signupErrorState['estado'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                    value={estado}
                                    onChange={(v) => setEstado(v.target.value)} />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['estado'] !== '' ? "block": "none") }}>{signupErrorState['estado'] !== '' ? signupErrorState['estado'] : ''}</span>

                                    <SingleInput label="Cidade"
                                    name="cidade"
                                    type="text"
                                    customClass={(alert && signupErrorState['cidade'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                    value={cidade}
                                    onChange={(v) => setCidade(v.target.value)} />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['cidade'] !== '' ? "block": "none") }}>{signupErrorState['cidade'] !== '' ? signupErrorState['cidade'] : ''}</span>
                                </>
                            }            
                            <SingleInput label="Senha"
                                        name="password"
                                        type="password"
                                        customClass={(alert && signupErrorState['password'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={password}
                                        onChange={(v) => setPassword(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['password'] !== '' ? "block": "none") }}>{signupErrorState['password'] !== '' ? signupErrorState['password'] : ''}</span>

                            <SingleInput label="Repetir senha"
                                        name="confirm-password"
                                        type="password"
                                        customClass={(alert && signupErrorState['confirm_password'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={confirmpassword}
                                        onChange={(v) => setConfirmPassword(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && signupErrorState['confirm_password'] !== '' ? "block": "none") }}>{signupErrorState['confirm_password'] !== '' ? signupErrorState['confirm_password'] : ''}</span>

                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por e-mail</div> }
                                    value={comunicacao_email}
                                    onChange={handleCheckboxEmail}/>
                            </div>
                            
                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por WhatsApp</div> }
                                    value={comunicacao_whatsapp}
                                    onChange={handleCheckboxWhatsapp}/>
                            </div>
                            
                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por SMS</div> }
                                    value={comunicacao_sms}
                                    onChange={handleCheckboxSMS}/>
                            </div>
                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Confirmo que li e aceito os <a onClick={openModalTermos}>Termos e Condições de Uso</a>{showModalTermos && <Modal className='lg:max-w-screen-lg' closeModal={closeModalTermos} conteudo={TermosCondicoesDeUso}/>}</div> }
                                    value={termos}
                                    onChange={handleCheckbox}/>
                            </div>
                        </div>
                    </div>

                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && !termos ? "block": "none") }}>{signupErrorState['termo_uso'] !== '' ? signupErrorState['termo_uso'] : ''}</span>
                
                    <FormAction handleSubmit={handleSubmit} text="Criar conta" loading={loading}/>

                </div>
            </form>
            <a className={(!statusDaConfirmacao ? "link-voltar w-full flex justify-center py-3 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-[#A4A4A4] hover:text-white bg-[#D9D9D9] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" : "hidden")} href="/">
                <FaArrowLeft className="mx-2 icon-link-voltar mt-1" />  Voltar
            </a>
        </>
    )
}