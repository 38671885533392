import React, { useState, useEffect, useRef } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FcCancel } from 'react-icons/fc'
import { TbNoteOff } from 'react-icons/tb';

import BoletoRowExpand from '../BoletoRowExpand.jsx'
import { formatarDataPtBr } from '../Tabela/Tabela'
import Tabela, {StatusTag} from '../ExpandTabela/ExpandTabela.jsx'
import Pagination from '../Tabela/Pagination'
import FilterData from '../Tabela/FilterData'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoTopBorder from '../Alert/InfoTopBorder'
import Modal from '../Modal'
import FormVincularEmpresa from '../Form/FormVincularEmpresa';
import { selectFinanceiroOrderBy } from '../constants/filtro'

export default function BoletoNF(){
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limitPage, setLimitPage] = useState(10);
    const [totalItens, setTotalItens] = useState('');
    const [search, setSearch] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [sortType, setSortType] = useState('');
    const [beginSearch, setBeginSearch] = useState(false);
    const [concluido, setConcluido] = useState(false);
    const [company, setCompany] = useState('');
    const [agency, setAgency] = useState('');
    const [showModalRedirect, setShowModalRedirect] = useState(false);
    const [tempoRedirecionamento, setTempoRedirecionamento] = useState(3);
    const countRef = useRef(null);
    countRef.current = tempoRedirecionamento; 
    const [notas, setNotas] = useState(() => {
        setLoading(true);
        setBeginSearch(false);
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/invoice/'+limitPage+'?page=1',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setTotalItens(data.data.total_notas);
            setNotas(data.data.notas.data);
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    });

    useEffect(() => {
        setLoading(true);
        setBeginSearch(false);
        setNotas({});
        const token = localStorage.getItem("token");

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/invoice/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType+'&company='+company+'&agency='+agency, {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setTotalItens(result.data.total_notas);
            setNotas(result.data.notas.data);
            return result.data.notas;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [currentPage]);

    useEffect(() => {
        setLoading(true);
        setBeginSearch(true);
        setNotas({});
        
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/companies/invoice/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType+'&company='+company+'&agency='+agency,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setTotalItens(result.data.total_notas);
            setNotas(result.data.notas.data);
            return result.data.notas;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [search, orderBy, sortType, company, agency, limitPage]);

    const [ipUser, setIPUser] = useState(() => {
      fetch('https://api.ipify.org/?format=json')
      .then(response => response.json())
      .then(data => {
          if(typeof(data.ip) != 'undefined'){
              setIPUser(data.ip);
          } else{
              setIPUser('');
          }
      })
      .catch((err) => {
          setIPUser('');
      })
      .finally(() => {
          //
      })
    });

    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

    const columns = React.useMemo(
        () => [
          {
            Header: "Série",
            accessor: "serie",
            width: 30
          },
          {
            Header: "Número",
            accessor: "nota_fiscal",
            width: 80
          },
          {
            Header: "Prestador",
            accessor: "emissor",
          },
          {
            Header: "Tomador",
            accessor: "nome",
          },
          {
            Header: "Data de emissão",
            accessor: "data_emissao",
            width: 100,
            // Cell: formatarDataPtBr,
          },
          {
            Header: "Total",
            accessor: "valor",
            width: 120
          },
          {
            Header: "Status",
            accessor: "status_nf",
            width: 100,
            Cell: StatusTag,
          },
          {
            Header: " ",
            accessor: "download_nf",
            // Cell: btnBaixarNF,
            Cell: ({ row }) => ( 
              (row.original.serie === 'NFS') ? 
              
              <button className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded' onClick={() => redirectNFSe(row.original.link_nfse)}>
                  NFS-e
              </button>
              // <a className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded' href={row.original.link_nfse} target="_blank">
              //     NFS-e
              // </a> 
              :
              (row.original.download_nf !== 'nonexistent') ? 
              <button onClick={getFile} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded' data-tipo="nota-fiscal" data-arquivo={row.original.download_nf} data-filename={row.original.nome_arquivo_nota}>
                  Baixar NF
              </button>
              : 
              <button className='px-4 py-1 text-white bg-gray-600 rounded focus:outline-none disabled:opacity-100' disabled>
                  Indisponível
              </button>
            ),
          },
          {
            Header: () => null,
            id: "expander",
            Cell: ({ row }) => (
              <button {...row.getToggleRowExpandedProps({
                title: "Visualizar boletos",
              })} className='bg-red-500 hover:bg-red-700 text-white font-semibold py-1 px-1 border rounded'>
                {row.isExpanded ? (
                    'Ocultar boletos'
                ) : (
                    'Exibir boleto'
                )}
              </button>
            )
          },
        ], []);
        
        const details = React.useMemo(
          () =>[
            // {
            //   Header: "ID",
            //   accessor: "id",
            // },
            {
              Header: "Título",
              accessor: "titulo",
            },
            {
              Header: "Data de Emissão",
              accessor: "data_emissao_nf",
              Cell: formatarDataPtBr
            },
            {
              Header: "Vencimento",
              accessor: "data_vencimento",
              Cell: formatarDataPtBr
            },
            {
              Header: "Valor",
              accessor: "valor_titulo",
            },
            // {
            //   Header: "Status",
            //   accessor: "situacao",
            //   // Cell: StatusTag,
            // },
            {
              Header: "Forma pagamento",
              accessor: "forma_pagamento",
              // Cell: StatusTag,
            },
            {
              Header: "Status",
              accessor: "status_pagamento",
              Cell: ({ row }) => (
                <>
                  {( row.original.situacao )}
                </>
              ),
            },
            {
              Header: " ",
              accessor: "download_boleto",
              Cell: ({ row }) => (
                <button onClick={getFile} disabled={row.original.situacao !== 'Título em Aberto' || row.original.download_boleto === 'nonexistent'} className='font-semibold py-1 rounded btn bg-blue' data-tipo="boleto" data-arquivo={row.original.download_boleto} data-filename={row.original.nome_arquivo_boleto} title={(row.original.situacao === 'Título em Aberto' && row.original.download_boleto === 'nonexistent') ? 'Indisponível no momento' : (row.original.situacao === 'Título Cancelado' ? 'Título Cancelado' : row.original.situacao)} >
                  {(row.original.situacao === 'Título em Aberto' ? ((row.original.download_boleto === 'nonexistent') ? <TbNoteOff className="text-red-600 text-2xl" /> : 'Baixar boleto') : (row.original.situacao === 'Título Cancelado' ?  <FcCancel className='text-red-600 text-2xl'/> : <AiFillCheckCircle className='text-green-600 text-2xl'/>))}
                </button>
              ),
            },
          ]
        )
        const subTable = React.useCallback(
          ({ row }) =>
            row.original.titulos.length > 0 ? (
              <BoletoRowExpand
                columns={details}
                data={row.original.titulos}
              />
            ) : (
              "Não há informações a serem exibidas."
            ),
          [details]
        );
      
        const expandedRows = React.useMemo(() => {
          if (notas) {
            let arr;
            let d = notas.notas;
            if (totalItens > 0) {
              arr = d.map((sid, ind) => {
                return { [ind]: true };
              });
            }
            return arr;
          }
        }, []);

    function atualizarContador(){
        if(tempoRedirecionamento > 0){
            setTempoRedirecionamento((tempoRedirecionamento - 1));
        }
    }

    function redirectNFSe(url){
        setShowModalRedirect(true);
        const timer = setInterval(() => {
            if(countRef.current === 0){
                setShowModalRedirect(false);
                window.open(url, '_blank').focus();
                setTempoRedirecionamento(3);
                clearInterval(timer);
            } else{
                setTempoRedirecionamento((prevCount) => prevCount - 1);
            }
        }, 1000);
    }

    function getFile(e){
        const token = localStorage.getItem("token");
        const idArquivo = e.target.dataset.arquivo;
        const tipoDocumento = e.target.dataset.tipo;
        const textoAnterior = e.target.textContent;
        const nomeArquivo = e.target.dataset.filename;
        const postData = {
            ip: ipUser,
            user_agent: window.navigator.userAgent,
        };
        e.target.textContent = 'Aguarde...';
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/documento/'+tipoDocumento+'/' + idArquivo, {
            method: 'POST',
            responseType: 'arraybuffer',
            headers : {
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        })
        .then(response => {
          if(response.status === '404'){
              toast.error('Arquivo indisponível no momento.', {autoClose:3000})  
          } else{
              response.blob().then(blob => {
                  const fileURL = window.URL.createObjectURL(blob);
                  let alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = nomeArquivo;
                  alink.click();
                  toast.success('Download realizado com sucesso!', {autoClose:3000})
              })
          }
          })
        .catch((error) => {
            toast.error('Não foi possível realizar o seu download no momento.', {autoClose:3000})
        })
        .finally(() => {
            e.target.textContent = textoAnterior;
        });

    }

    return(
      <div className="min-h-screen bg-white text-gray-900">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="mt-4 w-auto">
              <FilterData 
                  totalItens={totalItens} 
                  searchChangeHandler={setSearch}
                  orderByChangeHandler={setOrderBy}
                  sortTypeChangeHandler={setSortType}
                  selectOrderBy={selectFinanceiroOrderBy}
                  showSelectEmpresas={true}
                  showSelectAgencia={true}
                  searchByCompanyHandler={setCompany}
                  searchByAgencyHandler={setAgency}
              />
              {notas && Object.keys(notas).length > 0 ? (
                  <>
                      <Tabela 
                        columns={columns} 
                        data={notas}
                        renderRowSubComponent={subTable}
                        expandedRowObj={expandedRows} 
                        selectEmpresas={true}
                        limitSize={limitPage}
                        />
                        
                        <Pagination
                            totalRows={totalItens}
                            pageChangeHandler={setCurrentPage}
                            pageLimitChangeHandler={setLimitPage}
                            rowsPerPage={limitPage}
                            page={currentPage}
                            searchInput={beginSearch}
                        />
                  </>
              ) : (loading) ?
                  <span>
                      Carregando informações...
                  </span>
              : 
              (notas && Object.keys(notas).length === 0)
              ?
                  <span>
                      Nenhuma informação foi encontrada
                  </span>
              :
                  (
                    <>
                        <InfoTopBorder 
                        titulo="Seu usuário está sem vínculo com uma Empresa." 
                        texto="Para acessar todos os recursos e funcionalidades disponíveis, é necessário vincular-se, por favor, clique no botão abaixo e preencha as informações necessárias."
                        textoBotao="Vincular Empresa"
                        actionBotao={openModal}
                        />{showModal && <Modal closeModal={closeModal} conteudo={FormVincularEmpresa}/>}
                    </>
                  )
            }
          </div>
          <div>
              <ToastContainer />
          </div>
          {showModalRedirect ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">
                        Você será redirecionado
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModalRedirect(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Atenção em <b>{tempoRedirecionamento}</b> segundos você será redirecionado para a página do documento.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </main>
      </div>
    )
}