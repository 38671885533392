import { React, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
function Protected({ test, children }) {
    const [loading, setLoading] = useState(false);
    // const [isSignedIn, setIsSignedIn] = localStorage.getItem('token');
    const logged = (localStorage.getItem('token')) ? true : false;
    const [resp, setResp] = useState(null);

    // useEffect(() => {
    //     (async () => {
    //         setLoading(true);
    //         const token = localStorage.getItem('token');
    //         if(!token) {
    //             setIsSignedIn(false);
    //         } else{
    //             fetch(process.env.REACT_APP_API_BACK_URL + '/api/user',{
    //                 headers : {
    //                     'Content-Type': 'application/json',
    //                     'Accept': 'application/json',
    //                     'Authorization': "Bearer " + token
    //                 }
    //             })
    //             .then((response) => {
    //                 return response.json();
    //             })
    //             .then((data) => {
    //                 if(typeof(data.success) != 'undefined'){
    //                     setIsSignedIn(data.success);
    //                 } else{
    //                     setIsSignedIn(false);
    //                 }
    //             })
    //             .catch((err) => {
    //                 // Erro
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //         }
    //     })();
    // }, []);

    // useEffect(() => {
    //     setLoading(true);
    //     const token = localStorage.getItem('token');
    //     if(!token) {
    //         setIsSignedIn(false);
    //     } else{
    //         fetch(process.env.REACT_APP_API_BACK_URL + '/api/user',{
    //             headers : {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': "Bearer " + token
    //             }
    //         })
    //         .then((response) => {
    //             return response.json();
    //         })
    //         .then((data) => {
    //             if(typeof(data.success) != 'undefined'){
    //                 setIsSignedIn(data.success);
    //             } else{
    //                 setIsSignedIn(false);
    //             }
    //         })
    //         .catch((err) => {
    //             // Erro
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    //     }
    // }, []);
    if(!loading){
        if (!logged) {
            return <Navigate to="/" replace />
            // window.location.href = '/';
        }
        return children
    }
}
export default Protected