export function formatarTelefone(telefone) {
    if(telefone){
        return telefone.replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1')
    }
    return telefone;
}

export function formatarCnpj(cnpj){
    return cnpj
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') 
}

export function formatarCpf(cpf) {
    return cpf.replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatarCep(cep){
    return cep.replace(/\D/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
}

export function getTipoUsuario(tipo){
    let grupo = '';
    switch(tipo){
        case 1:
            grupo = 'Administrador';
            break;
        case 2:
            grupo = 'Cliente';
            break;
        case 3:
            grupo = 'Prospect';
            break;
        case 4:
            grupo = 'Fornecedor';
            break;
        case 5:
            grupo = 'Agência';
            break;
        case 6:
            grupo = 'Afiliada';
            break;
        case 7:
            grupo = 'Admin de Cliente';
            break;
        default:
            grupo = 'Prospect';
            break;
  }

  return grupo;
}