import React, { useState, useEffect } from "react"
import Select from 'react-select'
import SingleInput from "./SingleInput"
import FormAction from "./FormAction"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatarTelefone, formatarCep, formatarCpf } from "./Form/helper";
import Modal from './Modal'
import FormAdminCliente from "./Form/FormAdminCliente"

const selectTipoUsuario = [
    { value: 1, label: 'Administrador' },
    { value: 2, label: 'Cliente' },
    { value: 3, label: 'Prospect' },
    { value: 4, label: 'Fornecedor' },
    { value: 5, label: 'Agência' },
    { value: 6, label: 'Afiliada' },
    { value: 7, label: 'Administrador de Cliente' },
]

const selectCidade = [];
  
const selectEstado = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
]

export default function AttDadosSenha(){
    const [loading, setLoading] = useState(true);
    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [cargo, setCargo] = useState("");

    const [cep, setCep] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero_endereco, setNumeroEndereco] = useState("");
    const [complemento, setComplemento] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [cidadeSelecionada, setCidadeSelecionada] = useState("");
    const [estado, setEstado] = useState("");
    const [estadoSelecionado, setEstadoSelecionado] = useState("");
    const [tipoUsuario, setTipoUsuario] = useState("");
    const [tipoUsuarioSelecionado, setTipoUsuarioSelecionado] = useState("");

    const [senhaatual, setSenhaAtual] = useState("");
    const [novasenha, setNovaSenha] = useState("");
    const [confirmarsenha, setConfirmarSenha] = useState("");
    
    const [updateErrorState,setUpdateErrorState]=useState({});
    const [mensagemErro,setMensagemErroState]=useState({});
    const [alert, setAlert] = useState(false);

    const [ipUser, setIPUser] = useState("");

    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        updateUsuario()
    }
    
    const getIpUser = async () => {
        const res = fetch('https://api.ipify.org/?format=json')
                    .then(response => response.json())
                    .then(data => {
                        setIPUser(data.ip);
                    });
    };

    const updateUsuario=(e)=>{
        setLoading(true);
        const token = localStorage.getItem("token");
        const idUser = window.location.href.split('/')[6];
        //Atualizar dados do usuário
        const updateData = {
            name:nome,
            email:email,
            cidade:cidade,
            estado:estado,
            telefone:telefone,
            cargo:cargo,
            endereco:endereco,
            numero_endereco:numero_endereco,
            bairro:bairro,
            cep:cep,
            complemento:complemento,
            cpf:cpf,
            group:tipoUsuario,
            ip: ipUser
        };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/edit/user/' + idUser, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(updateData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setLoading(false);
            setAlert(true);
            if(res.response.success === true){
                toast.success(res.response.message, {timer: 3000, theme: "colored"})
            } else{
                toast.error(res.response.message, {timer: 3000, theme: "colored"})
            }
        }));
    }

    const handlePassSubmit=(e)=>{
        e.preventDefault();
        updateSenha()
    }

    const updateSenha=(e)=>{
        //Atualizar senha do usuário
        setLoading(true);
        const token = localStorage.getItem("token");
        const updatePass = {
        senha_atual:senhaatual,
        nova_senha:novasenha,
        nova_senha_confirmation:confirmarsenha
        };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/update-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(updatePass)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setLoading(false);
            setAlert(true);
            if(res.response.success === true){
                toast.success(res.response.message, {autoClose:3000})
            } else{
                updateErrorState['senha_atual'] = '';
                updateErrorState['nova_senha'] = '';
                updateErrorState['nova_senha_confirmation'] = '';
                
                setMensagemErroState(updateErrorState);
                setMensagemErroState(res.response.data);
                toast.warning(res.response.message, {autoClose:3000, theme:'colored'})
            }
        }));
    }

    const definirCidades = (estado, buscar = '') => {
        setLoading(true);
        setCidadeSelecionada('');
        let response = fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            while(selectCidade.length > 0) {
                selectCidade.pop();
            }
            data.map((items =>
                selectCidade.push({value:items.nome, label:items.nome})
            ));
            if(buscar !== ''){
                const itemCidade = selectCidade.find(({ value: v }) => v === buscar ); 
                setCidadeSelecionada(itemCidade);
            }
            setLoading(false);
        });
    };
    
    function buscarCep(buscar){
        if(buscar < 8) {
        return;
        } else {      
            fetch(`http://viacep.com.br/ws/${buscar}/json/`)
            .then((res) => res.json())
            .then((data) => {
                if (data.hasOwnProperty("erro")) {
                    alert('Cep não existente');
                } else {
                    const itemEstado = selectEstado.find(({ value: v }) => v === data.uf ); 
                    setEstadoSelecionado(itemEstado);
                    
                    definirCidades(data.uf, data.localidade);
                    const itemCidade = selectCidade.find(({ value: v }) => v === data.localidade ); 
                    setCidadeSelecionada(itemCidade);
                    setEstado(data.uf);
                    setEndereco(data.logradouro);
                    setBairro(data.bairro);
                    setCidade(data.localidade);
                }
            })
            .catch(err => console.log(err));
        }
    }
    
    useEffect(() => {
        setLoading(true);
        getIpUser();
        const token = localStorage.getItem("token");
        const idUser = window.location.href.split('/')[6];
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/' + idUser,{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setNome(data.data.user.name);
            setEmail(data.data.user.email);
            setCidade(data.data.user.cidade);
            setEstado(data.data.user.estado);
            if(data.data.user.telefone){
                setTelefone(formatarTelefone(data.data.user.telefone));
            }
            setCargo(data.data.user.cargo);
            setCep(data.data.user.cep);
            setEndereco(data.data.user.endereco);
            setNumeroEndereco(data.data.user.numero_endereco);
            setBairro(data.data.user.bairro);
            setComplemento(data.data.user.complemento);
            setTipoUsuario(data.data.user.group);
            
            if(data.data.user.cpf){
                setCpf(formatarCpf(data.data.user.cpf));
            }

            if(data.data.user.estado){
                const itemEstado = selectEstado.find(({ value: v }) => v === data.data.user.estado ); 
                setEstadoSelecionado(itemEstado);
            }
            
            if(data.data.user.group){
                const itemTipoUsuario = selectTipoUsuario.find(({ value: v }) => v === data.data.user.group); 
                setTipoUsuarioSelecionado(itemTipoUsuario);
            }
            
            if(data.data.user.cidade){
                definirCidades(data.data.user.estado, data.data.user.cidade).then((r)=>{
                    if(selectCidade.length > 0){
                    const itemCidade = selectCidade.find(({ value: v }) => v === data.data.user.cidade ); 
                    setCidadeSelecionada(itemCidade);
                    } else{
                    console.log(data.data.user.cidade);
                    }
                });
            }
        })
        .catch((err) => {
            toast.error(err, {timer: 3000, theme: "colored"});
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });

    }, []);

    return(
        
        <div className="w-auto">
            {/* <div className='grid grid-cols-[100px_minmax(900px,_1fr)_150px]'>
                <Avatar width='20' height='20' size='4' color='white'/>
                <div className='flex items-center'>
                    <div>
                    <span className='block text-xl text-slate-800 font-semibold'>{!loading && tipoUsuario ? getTipoUsuario(tipoUsuario) : ''}</span>
                    <span className='block text-sm'>{!loading && cargo ? cargo : ''}</span>
                    <span className='text-xs'>{!loading && email ? email : ''}</span>
                    </div>
                </div>
                <span></span>
            </div> */}
            <div className='grid grid-cols-2 h-auto gap-6 auto-cols-max divide-x divide-slate-400/25'>
          
                <form className="mt-6 col-span-2" onSubmit={handleSubmit}>
                    <label className='block text-xl text-slate-800 font-semibold py-6'>Atualizar Dados Cadastrais</label>
                    <div className='grid grid-cols-2'>
                        <div className=''>
                            <SingleInput label="Nome Completo"
                            name="nome"
                            type="text"
                            value={nome}
                            onChange={(v) => setNome(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.name) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.name) != 'undefined' ? updateErrorState.name : ''}</span>
                            <SingleInput label="CPF"
                            name="cpf"
                            type="text"
                            value={cpf}
                            disabled={loading}
                            onChange={(v) => setCpf(formatarCpf(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.cpf) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.cpf) != 'undefined' ? updateErrorState.cpf : ''}</span>
                            <SingleInput label="E-mail"
                            name="email"
                            type="email"
                            value={email}
                            disabled={loading}
                            onChange={(v) => setEmail(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.email) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.email) != 'undefined' ? updateErrorState.email : ''}</span>
                            <SingleInput label="Telefone"
                            name="telefone"
                            type="tel"
                            value={telefone}
                            disabled={loading}
                            onChange={(v) => setTelefone(formatarTelefone(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.telefone) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.telefone) != 'undefined' ? updateErrorState.telefone : ''}</span>
                            <SingleInput label="Cargo"
                            name="cargo"
                            type="text"
                            value={cargo}
                            disabled={loading}
                            onChange={(v) => setCargo(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.cargo) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.cargo) != 'undefined' ? updateErrorState.cargo : ''}</span>
                            <SingleInput label="CEP"
                            name="cep"
                            type="text"
                            value={cep}
                            disabled={loading}
                            onChange={(v) => {buscarCep(formatarCep(v.target.value));setCep(formatarCep(v.target.value))}} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.cep) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.cep) != 'undefined' ? updateErrorState.cep : ''}</span>
                        </div>
                        <div className='pl-6'>
                            <SingleInput label="Endereço"
                                name="endereco"
                                type="text"
                                value={endereco}
                                disabled={loading}
                                onChange={(v) => setEndereco(v.target.value)} />

                            <SingleInput label="Número"
                                name="numero_endereco"
                                type="text"
                                value={numero_endereco}
                                disabled={loading}
                                onChange={(v) => setNumeroEndereco(v.target.value)} />

                            <SingleInput label="Complemento"
                                name="complemento"
                                type="text"
                                value={complemento}
                                disabled={loading}
                                onChange={(v) => setComplemento(v.target.value)} />

                            <SingleInput label="Bairro"
                                name="bairro"
                                type="text"
                                value={bairro}
                                disabled={loading}
                                onChange={(v) => setBairro(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.bairro) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.bairro) != 'undefined' ? updateErrorState.bairro : ''}</span>

                            <Select 
                                className="mt-6"
                                placeholder="Selecione um Estado"
                                options={selectEstado.map((guest, index) => {
                                    return {
                                        label: guest.label,
                                        value: guest.value,
                                        key: index
                                    };
                                })}
                                onChange={(item) => {
                                    setEstado(item.value);
                                    setEstadoSelecionado(item);
                                    definirCidades(item.value);
                                }}
                                value={estadoSelecionado}
                            />

                            <Select 
                                className="mt-6"
                                placeholder="Selecione uma Cidade"
                                isClearable={false} 
                                options={selectCidade}
                                onChange={(item) => {
                                    setCidade(item.value);
                                    setCidadeSelecionada(item);
                                }}
                                value={cidadeSelecionada}
                            />

                            <Select 
                                className="mt-6"
                                placeholder="Selecione um Tipo"
                                isClearable={false} 
                                options={selectTipoUsuario}
                                onChange={(item) => {
                                    if(item.label === 'Administrador de Cliente'){
                                        openModal();
                                    }
                                    setTipoUsuario(item.value);
                                    setTipoUsuarioSelecionado(item);
                                }}
                                value={tipoUsuarioSelecionado}
                            />
                            
                            <FormAction handleSubmit={handleSubmit} text="Atualizar Dados" loading={loading}/>
                        </div>
                    </div>
                </form>
                {/* <div className='pl-6'>
                <form className='mt-6' onSubmit={handlePassSubmit}>
                <label className='block text-xl text-slate-800 font-semibold py-6'>Atualizar Senha</label>
                <SingleInput label="Senha Atual"
                name="senhaatual"
                type="password"
                value={senhaatual}
                disabled={loading}
                onChange={(v) => setSenhaAtual(v.target.value)} />
                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.senha_atual) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.senha_atual) != 'undefined' ? mensagemErro.senha_atual : '' }</span>
                <SingleInput label="Nova Senha"
                name="novasenha"
                type="password"
                value={novasenha}
                disabled={loading}
                onChange={(v) => setNovaSenha(v.target.value)} />
                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.nova_senha) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.nova_senha) != 'undefined' ? mensagemErro.nova_senha : ''}</span>
                <SingleInput label="Confirmar Senha"
                name="confirmarsenha"
                type="password"
                value={confirmarsenha}
                disabled={loading}
                onChange={(v) => setConfirmarSenha(v.target.value)} />
                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.nova_senha_confirmation) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.nova_senha_confirmation) != 'undefined' ? mensagemErro.nova_senha_confirmation : ''}</span>
                <FormAction handleSubmit={handlePassSubmit} text="Atualizar Senha" />
                </form>
                </div> */}
                <div>
                    <ToastContainer />
                </div>
            </div>
            {showModal && <Modal closeModal={closeModal} conteudo={FormAdminCliente} />}
        </div>
    )
}