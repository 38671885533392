const InfoLogoEmpresas = [
    {
      titulo: 'Grupo Massa',
      link: 'https://grupomassa.com.br/',
      imagemUrl: './img/logo-grupomassa.png'
    },
    {
      titulo: 'Rede Massa',
      link: 'https://redemassa.com.br/',
      imagemUrl: './img/logo-redemassasbt.png'
    },
    {
      titulo: 'Massa FM',
      link: 'https://massafm.com.br/',
      imagemUrl: './img/logo-massafm-vertical.png'
    },
    {
      titulo: 'Massa Digital',
      link: '#',
      imagemUrl: './img/logo-massadigital.png'
    },
    {
      titulo: 'Massa News',
      link: 'https://massanews.com/',
      imagemUrl: './img/logo-massanews.png'
    },
    {
      titulo: 'Instituto Solange Massa',
      link: '#',
      imagemUrl: './img/logo-instsolangemassa.png'
    },
  ]
  export {InfoLogoEmpresas}