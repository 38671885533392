import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { selectSortType } from '../constants/filtro'

const selectEmpresas = []
const selectAgencias = []

export default function FilterData({searchChangeHandler, orderByChangeHandler, sortTypeChangeHandler, selectStatusChangeHandler, totalItens, itensSelect = [], placeholderSelect, selectOrderBy = [], showSelectEmpresas = false, showSelectAgencia = false, searchByCompanyHandler = '', searchByAgencyHandler = ''}){
    const [searchQuery, setSearchQuery] = useState("");
    const [filterByCompany, setFilterByCompany] = useState("");
    const [filterByAgency, setFilterByAgency] = useState("");
    const [itemSelect, setItemSelect] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    // const onChange = useAsyncDebounce(value => {
    //     setGlobalFilter(value || undefined)
    //   }, 200);

    useEffect(() => {
        if(showSelectEmpresas){
            setLoading(true);
            const token = localStorage.getItem("token");
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/select/companies',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                while(selectEmpresas.length > 0) {
                  selectEmpresas.pop();
                }
                selectEmpresas.push({value:"", label:"Todas"})
                data.data.companies.map((items =>
                    selectEmpresas.push({value:items.codigo_cliente, label:items.nome})
                ));
                while(selectAgencias.length > 0) {
                    selectAgencias.pop();
                }
                
                let total_agencias = data.data.agencies;
                if(total_agencias.length > 0){
                    selectAgencias.push({value:"", label:"Todas"})
                    data.data.agencies.map((items =>
                        selectAgencias.push({value:items.codigo, label:items.nome})
                    ));
                }
            })
            .catch((err) => {
                // Erro
            })
            .finally(() => {
                setLoading(false);
            });
        }
    
    }, []);

    useEffect(() => {
        searchChangeHandler(searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        if(searchByCompanyHandler.length > 0){
            searchByCompanyHandler(filterByCompany);
        }
    }, [filterByCompany]);

    useEffect(() => {
        if(searchByAgencyHandler.length > 0){
            searchByAgencyHandler(filterByAgency);
        }
    }, [filterByAgency]);

    function handleKeyPress(e){
        if (e.key === 'Enter') {
            setSearchQuery(e.target.value);
        }
    }

    return(
        <>
            <div className="flex w-full">
                <span className="w-3/5">
                    <label className="px-2.5 pt-2.5">Buscar: {' '}</label>
                    <input
                        className="block px-2.5 pb-2.5 pt-2.5 mr-1 w-2/3 text-sm ml-2 text-gray-900 bg-transparent rounded-md border border-gray-300 appearance-none text-gray-600 border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        // value={searchQuery || ""}
                        onKeyUp={e => {
                            // setSearchQuery(e.target.value);
                            handleKeyPress(e);
                        //   onChange(e.target.value);
                        }}
                        placeholder={`${totalItens} registros..`}
                    />
                    <p id="helper-text-explanation" class="px-2.5 mt-2 text-sm text-gray-500">Aperte <b>Enter</b> para realizar a busca.</p>
                </span>
                {
                    (showSelectEmpresas) ?
                        <span className="px-2 w-1/2">
                            <label className="px-2.5 pt-2.5">Filtrar por empresa: {' '}</label>
                            <Select 
                                className="block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer"
                                placeholder={(loading) ? "Carregando opções..." : "Selecione"}
                                isClearable={false} 
                                options={selectEmpresas}
                                onChange={(item) => {
                                    if(item.label === "Todas"){
                                        setValue('');
                                        setFilterByCompany('');
                                        // onChange(undefined);
                                    } else{
                                        setValue(item.label);
                                        setFilterByCompany(item.value);
                                        // onChange(item.label);
                                    }
                                    
                                }}
                            />
                        </span>
                    :
                        ''
                }
                {
                    (showSelectAgencia && !loading && selectAgencias.length > 0) ?
                        <span className="px-2 w-1/2">
                            <label className="px-2.5 pt-2.5">Filtrar por agência: {' '}</label>
                            <Select 
                                className="block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer"
                                placeholder={(loading) ? "Carregando opções..." : "Selecione"}
                                isClearable={false} 
                                options={selectAgencias}
                                onChange={(item) => {
                                    if(item.label === "Todas"){
                                        setValue('');
                                        setFilterByAgency('');
                                    } else{
                                        setValue(item.label);
                                        setFilterByAgency(item.value);
                                    }
                                    
                                }}
                            />
                        </span>
                    :
                        ''
                }
                {(itensSelect.length > 0) 
                    ? 
                    <span className="w-2/5">
                        <label className="px-2.5 pt-2.5">Grupo</label>
                        <Select className='block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer'
                                placeholder={placeholderSelect}
                                isClearable={false} 
                                options={itensSelect}
                                onChange={(item) => {
                                    selectStatusChangeHandler(item.value);
                                }}
                        />
                    </span>
                    : ''

                }
                {(selectOrderBy.length > 0) 
                    ? 
                    <span className="w-2/5">
                        <label className="px-2.5 pt-2.5">Ordenar por</label>
                        <Select className='block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer'
                                placeholder="Selecione"
                                isClearable={false} 
                                options={selectOrderBy}
                                onChange={(item) => {
                                    orderByChangeHandler(item.value);
                                }}
                        />
                    </span>
                    : ''

                }
                {(selectSortType.length > 0) 
                    ? 
                    <span className="w-2/5">
                        <label className="px-2.5 pt-2.5">Em ordem</label>
                        <Select className='block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer'
                                placeholder="Selecione"
                                isClearable={false} 
                                options={selectSortType}
                                onChange={(item) => {
                                    sortTypeChangeHandler(item.value);
                                }}
                        />
                    </span>
                    : ''

                }
            </div>
        </>
    )
}