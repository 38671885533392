import React from 'react'


export default function TermosCondicoesDeUso() {
  return (
    <div className='block p-8'>
        <h1 className='text-2xl font-bold mb-5'>TERMOS E CONDIÇÕES DE USO</h1>
        <h2 className='text-xl font-bold mb-5'>PORTAL SOLUÇÕES MASSA</h2>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>Informamos que os serviços e o site são de propriedade exclusiva da empresa Grupo Massa. O Grupo Massa é responsável pela criação, gestão e manutenção de serviços online do portal denominado <a className='text-blue-600' href='https://solucoesmassa.com.br'>solucoesmassa.com.br</a>.</p>
            <p><br/>O Grupo Massa compreende a importância na proteção dos dados dos usuários e a segurança necessária para mantê-los em sigilo. Para tanto, visando estabelecer princípios claros e precisos acerca do uso de seu Portal, o Grupo Massa formatou os termos gerais de uso e a política de privacidade a seguir descritos, os quais podem ser alterados a qualquer tempo para melhorar o sistema de segurança e devem ser aceitos pelo usuário.</p>
            <p><br/>Algumas informações pessoais serão obtidas no registro feito pelo usuário. Durante o processo de registro, o Portal do Soluções Massa perguntará nome, endereço de e-mail, empresa e cargo do usuário, telefone, estado e cidade. Para maior proteção e experiência do usuário, essas informações devem ser preenchidas pelo usuário de forma fidedigna e precisa. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DOS OBJETIVOS DO PORTAL SOLUÇÕES MASSA</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Portal Soluções Massa é um site que possui como objetivo geral o acesso a informações, envio de materiais e gerenciamento fiscal dos clientes do Grupo Massa.</p>
            <p><br/>O Portal permite que o usuário utilize uma série de plataformas, links e ferramentas pensados para fazer interação entre o usuário e o site.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA ACEITAÇÃO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Usuário que acessar o Portal Soluções Massa por meio do endereço eletrônico acima indicado e realizar o seu cadastro, se sujeitará a aceitação integral aos Termos e Condições de Uso, Política de Privacidade e Política de Cookies, aderindo expressamente e sem reservas ou ressalvas a todas as disposições destes documentos.</p>
            <p><br/>O usuário declara que leu e analisou atentamente os Termos e Condições de Uso.</p>
            <p><br/>A aceitação do presente documento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pelo Portal Soluções Massa. Caso não concorde com as disposições, o usuário não deve utilizá-los.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DOS TERMOS E CONDIÇÕES DE USO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O presente “Termo e Condições de Uso” visa informar as responsabilidades e condições de que todo usuário assume ao utilizar e se cadastrar no Portal Soluções Massa. </p>
            <p><br/>O presente documento representa um acordo de vontades, ou seja, um contrato da espécie interativo, firmado entre o usuário, Portal Soluções Massa e sua gerenciadora. Dessa forma, visitando e cadastrando seus dados, o usuário concorda, sem restrições, em aceitar os termos e condições de uso abaixo indicados, uma vez que as disposições constantes neste Termo de Uso vinculam o usuário do Portal Soluções Massa, dando a ele ciência inequívoca no que diz respeito aos termos e condições de uso. O conteúdo, o software e a marca, estão protegidos de acordo com o que disciplina as Leis Ordinárias nº 9.609/1998 e 9.279/1996. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DAS FUNCIONALIDADES DISPONIBILIZADAS PELO PORTAL </h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O presente Termo e Condições de Uso disciplina a forma de utilização dos serviços disponibilizados através do Portal Soluções Massa aos seus usuários. Nesse sentido, o Portal oportunizará aos usuários que interajam através do site. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>O OBJETIVO DA PLATAFORMA</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Portal Soluções Massa caracteriza-se pela prestação de serviços de gerenciamento fiscal de anunciantes em soluções do Grupo Massa, acesso a informações de mercado e envio de materiais para veiculação de anúncios nos meios de comunicações do Grupo Massa </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>RESPONSABILIDADES DO USUÁRIO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>
              <ul className='list-decimal ml-8'>
                <li> defeitos ou vícios técnicos originados no próprio sistema do usuário;</li>
                <li> a correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários;</li>
                <li> pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade e na legislação nacional e internacional; </li>
                <li> pela proteção aos dados de acesso à sua conta/perfil (login e senha).</li>
              </ul>
            </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>RESPONSABILIDADES DO PORTAL SOLUÇÕES MASSA </h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>
              <ul className='list-decimal ml-8'>
                <li> indicar as características do serviço ou produto;</li>
                <li> os defeitos e vícios encontrados no serviço ou produto oferecido desde que lhe tenha dado causa;</li>
                <li> as informações que foram por ele divulgadas, sendo que os comentários ou informações divulgadas por usuários são de inteira responsabilidade dos próprios usuários;</li>
              </ul>
            </p>
            <p><br/>A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário à ambiente externo a sua rede.</p>
            <p><br/>Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA POLÍTICA DE PRIVACIDADE</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Portal Soluções Massa recebe, armazena e processa informações do usuário quando o mesmo preenche seu cadastro, formulários, vincula conta de redes sociais, envia comunicados ao Portal Soluções Massa. </p>
            <p><br/>O Portal Soluções Massa utiliza as informações do usuário para permitir a sua utilização; para melhorar o modo de uso da plataforma do site; para evitar fraudes, infrações legais, contribuir com investigações ou cumprir ordens do Poder Público; para tornar o ambiente confiável; para trocar mensagens com os usuários; para organizar suas funcionalidades e para garantir todos os Termos e Condições de Uso do Site. </p>
            <p><br/>O Portal Soluções Massa poderá, por si ou por terceiros, examinar, rastrear, armazenar, analisar e manejar as informações prestadas pelos usuários, da forma como lhe convier e, sobretudo, para evitar o cometimento de ilegalidades ou dar cumprimento às ordens judiciais e/ou policiais.</p>
            <p><br/>O cadastro do usuário poderá ser utilizado para todos os serviços integrados do Grupo Massa, bem como suas informações poderão ser utilizadas por todas as empresas interligadas de qualquer forma ao Portal Soluções Massa, com o que o usuário expressamente concorda e permite. </p>
            <p><br/>As informações dos usuários poderão ser tornadas públicas nos campos que assim o exijam para a utilização do Portal Soluções Massa. Também poderão ser compartilhadas entre as empresas coligadas à operadora do Portal Soluções Massa, disponibilizadas para os fornecedores e/ou prestadores de serviços, utilizadas para otimizar o uso dos Portal Soluções Massa, envio de propagandas e, ainda, para evitar o cometimento de crimes ou respeitar ordens emanadas de órgãos públicos.</p>
            <p><br/>O usuário poderá atualizar, modificar e corrigir as informações cadastradas no Portal Soluções Massa, sempre seguindo as orientações e ferramentas disponibilizadas dentro da plataforma do site.</p>
            <p><br/>O Portal Soluções Massa adota medidas rigorosas para evitar fraudes e garantir a segurança de todas as informações que são inseridas pelos usuários. Contudo, nenhuma plataforma ou método de transmissão de dados com acesso à internet ou armazenamento de informações eletrônicas é indene de problemas e totalmente segura. Assim, o Portal Soluções Massa e a empresa que o administra não podem garantir a segurança absoluta das transmissões dos usuários e das informações cadastradas no site.</p>
            <p><br/>O usuário está ciente que a segurança das informações, assim como a precaução de seu uso indevido ou desautorizado estão diretamente ligados a utilização adequada do Portal, não fornecendo informações a terceiros, mantendo os logs, senhas e dados pessoas de maneira pessoal e intransferível.</p>
            <p><br/>Essa política de privacidade e armazenamento de dados poderá ser modificada a qualquer tempo, independente de autorização prévia ou aviso aos usuários, que aceitam tal alteração, ainda que realizada no período de uso contínuo do Portal Soluções Massa. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DOS TERMOS E CONDIÇÕES DE USO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O presente “Termo e Condições de Uso” visa informar as responsabilidades e condições de que todo usuário assume ao utilizar e se cadastrar no Portal Soluções Massa. </p>
            <p><br/>O presente documento representa um acordo de vontades, ou seja, um contrato da espécie interativo, firmado entre o usuário, Portal Soluções Massa e sua gerenciadora. Dessa forma, visitando e cadastrando seus dados, o usuário concorda, sem restrições, em aceitar os termos e condições de uso abaixo indicados, uma vez que as disposições constantes neste Termo de Uso vinculam o usuário do Portal Soluções Massa, dando a ele ciência inequívoca no que diz respeito aos termos e condições de uso. O conteúdo, o software e a marca, estão protegidos de acordo com o que disciplina as Leis Ordinárias nº 9.609/1998 e 9.279/1996. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA POLÍTICA DE USO DO PORTAL SOLUÇÕES MASSA</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>Apenas será confirmado e aceito o cadastramento do usuário que venha a preencher todos os campos obrigatórios solicitados pelo site no ato do cadastro, comprometendo-se o usuário, ainda, a prestar informações exatas, precisas e verdadeiras, sob pena dos sansões previstas na lei e nos termos do presente Termo de Uso. </p>
            <p><br/>O usuário declara e assume o compromisso de atualizar os seus dados e caso exista, de dependentes, familiares, terceiros, entre outros, constantes em seu cadastro sempre que ocorrer alguma mudança, de forma a que a consulta, aquisição e utilização dos serviços disponíveis através da plataforma do Portal Soluções Massa não seja prejudicada.  </p>
            <p><br/>As consequências e responsabilidades sobre os dados informados são exclusivas do usuário, ficando desde já o Grupo Massa, bem como empresas que utilizam o Portal Soluções Massa ou são a ele interligadas, isentos de qualquer reclamação pela inconsistência de qualquer informação prestada pelo usuário ou em virtude da sua desatualização. </p>
            <p><br/>Não será permitida a utilização indevida das funcionalidades disponibilizadas pelo Portal Soluções Massa de modo a que apenas seja permitido ao usuário um único cadastro por CPF/CNPJ ficando o usuário obrigado a utilizar o que for lhe disponibilizado dentro das condutas compatíveis e não vedadas pela legislação brasileira em vigor.  </p>
            <p><br/>Poderá ser aplicada como pena a suspensão ou cancelamento do cadastro do usuário que descumprir os Termos ou Políticas aqui estatuídas, bem como se forem constatadas práticas que configurem má conduta ou mau uso do Portal. </p>
            <p><br/>A exploração comercial do Portal Soluções Massa caberá apenas ao Grupo Massa, suas coligadas, subsidiárias, controladas, controladoras, grupo econômico, sócios e terceiros apenas por elas indicadas e devidamente contratadas.  </p>
            <p><br/>Ao efetuar o cadastramento cadastrar e concordar com o presente Termo de Uso e Política de Privacidade, o usuário receberá uma licença de uso em caráter revogável, não transferível, de uso pessoal, o que, em nenhuma hipótese tornará o usuário proprietário do Portal, da sua conta ou de qualquer direito decorrente, implícito ou explicito. </p>
            <p><br/>Sem limitar, fica estabelecido que o usuário não pode:</p>
            <p><br/><ul className='list-decimal ml-8'>
                <li> alugar, arrendar, licenciar, distribuir, sublicenciar ou de qualquer forma transferir sua conta ou qualquer funcionalidade do Portal;</li>
                <li> fazer cópias do Portal, da conta ou de qualquer parte de tal plataforma;</li>
                <li> fazer engenharia reversa, decompilar ou desconstruir qualquer parte do Portal e de sua plataforma de uso;</li>
                <li> criar trabalhos derivados do Portal ou de qualquer parte; </li>
                <li> incorporar o Portal ou qualquer parte de tal plataforma a qualquer produto ou serviço;</li>
                <li> usar o Portal ou qualquer parte dele para fins comerciais, ou que seja ilícito, discriminatório ou usado para ofender terceiros, e</li>
                <li> remover, alterar ou ocultar quaisquer observações, legendas, símbolos ou etiquetas referentes a copyright, trademark, trade name ou qualquer outro direito de  propriedade que apareça no PORTAL MASSA.</li>
              </ul>
            </p>
            <p><br/>Todos os direitos que não sejam expressamente cedidos ao usuário no presente Termo de Uso são reservados aos proprietários do Portal Soluções Massa e a seus respectivos detentores. </p>
            <p><br/>A concordância às condições deste Termo de Uso autoriza ao Grupo Massa a proceder com o envio de mensagens publicitárias ou administrativas ao usuário, cabendo a este desativar o envio de tais mensagens quando de seu interesse, através dos recursos de configuração disponibilizados no site, bem como outros disponibilizados pela empresa gerenciadora. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>OBRIGAÇÕES DO USUÁRIO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O usuário não pode utilizar o Portal Soluções Massa para qualquer finalidade ilegal ou não autorizada. Com o aceite do presente Termo de Uso, o usuário concorda em cumprir todas as leis, regras e regulamentações (seja no âmbito federal, estadual e municipal) aplicáveis ao uso do Portal Soluções Massa e seu conteúdo, incluindo, mas não limitado as leis que salvaguardam direitos autorais. </p>
            <p><br/>O usuário não deverá, em hipótese alguma interferir ou interromper o serviço prestado pelo uso do Portal Soluções Massa ou os servidores ou redes conectadas, inclusive, através da transmissão de qualquer worm, vírus, spyware, malware ou qualquer outro código de natureza que venha a prejudicar o Portal ou a empresa gerenciadora.  </p>
            <p><br/>É vedado ao usuário injetar conteúdo, código, ou sob qualquer forma alterar ou interferir no processamento ou exibição no dispositivo de um usuário, sob pena de responder pelos danos causados e pelas violações cometidas. </p>
            <p><br/>Não poderá o usuário criar contas no Portal Soluções Massa através de meios não autorizados, incluindo, mas não limitado a utilizar um dispositivo automático, script, bot, spider ou crawler. </p>
            <p><br/>Em caso de falecimento de algum usuário pedimos que nos seja enviado um e-mail para: <a className='text-blue-600' href="mailto:contato@grupomassa.com.br">contato@grupomassa.com.br</a> visando a remoção da conta do usuário que será feita pela equipe técnica gerenciadora do Portal Soluções Massa. </p>
            <p><br/>Os usuários que não seguirem ou adequarem suas condutas a este Termo e Condições de Uso poderão ter suas respectivas contas acessadas e desativadas unilateralmente sem ser obrigado a revelar as razões para tal ato independentemente de prévia notificação pelos desenvolvedores do Portal Soluções Massa. </p>
            <p><br/>Sem prejuízo das disposições acima descritas, são obrigações do usuário: Manifestar seu aceite em relação ao presente Termo e Condições Gerais de Uso; efetuar o cadastro no Portal, preenchendo todos os campos com informações verídicas, se atendo a não divulgar a terceiros login e senha de acesso; manter as informações pessoais sempre atualizadas, inclusive, confirmando o endereço e dados pessoais.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DAS OBRIGAÇÕES DO PORTAL</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>Sem prejuízo das disposições acima descritas, são obrigações do Portal Soluções Massa:</p>
            <p><br/>Permitir que o usuário devidamente cadastrado e que tenha aceitado as disposições do presente Termo de Uso utilize as funcionalidades ofertadas pelo Portal.</p>
            <p><br/>Propiciar que o usuário utilize o Portal Soluções Massa com a maior segurança possível, fazendo para tanto, o armazenamento de referido conteúdo em seus servidores ou outros meios que possam atribuir o máximo possível de segurança ao usuário.</p>
            <p><br/>É expressamente excluída a responsabilidade da administradora do Portal Soluções Massa por eventuais prejuízos causados em decorrência do uso indevido de dados por terceiros que porventura conseguirem violar os sistemas de segurança do Portal Soluções Massa e ter acesso a informações de cunho confidenciais do usuário. </p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DO USO DAS INFORMAÇÕES</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>As informações coletadas serão utilizadas para personalizar o conteúdo e/ou serviços disponibilizados. Isso inclui serviços de personalização, comunicações interativas, compras e outros tipos de serviços.  </p>
            <p><br/>As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários, da mesma forma poderão ser fornecidas a parceiros do Grupo Massa, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação. </p>
            <p><br/>As informações coletadas poderão ser utilizadas para direcionamento de campanhas publicitárias de uma forma geral.</p>
            <p><br/>As promoções realizadas no Portal Soluções Massa podem ser patrocinadas por outras empresas ou segundo uma parceria entre o Grupo Massa com essas empresas. Alguns ou todos os dados coletados durante uma promoção podem ser compartilhados com o parceiro e/ou patrocinador.</p>
            <p><br/>Sempre que o usuário acessar anunciantes ou outros sites da web através de links presentes no Portal Soluções Massa, aqueles poderão coletar informações sobre o usuário. As práticas relativas à privacidade desses parceiros e terceiros não são cobertas pela presente política e podem ser diferentes dos padrões de privacidade aqui estipulados.</p>
            <p><br/>Ao aceitar os termos de uso, o usuário está automaticamente autorizando que o Portal Soluções Massa e/ou parceiros lhe enviem ofertas, mensagens ou conteúdos publicitários que possam interessá-lo. Se não desejar receber mais esses informes e/ou ofertas, basta informar à gerenciadora do Portal Soluções Massa, ou, a qualquer momento, alterar suas informações cadastrais, selecionando a opção de não receber mais essas comunicações. Serão necessárias sua identificação e senha para acessar suas informações cadastrais.</p>
            <p><br/>A senha do usuário é secreta e recomendamos que não a revele a ninguém. A gerenciadora do Portal Soluções Massa não irá solicitar, em nenhuma hipótese, seja por e-mail ou telefone, sua senha pessoal. Lembre-se também de desconectar-se da sua conta do site e fechar a janela do seu navegador quando concluir sua navegação na Internet. Isso garante que outras pessoas não tenham acesso às suas informações pessoais e correspondências, caso você compartilhe um computador com alguém ou esteja usando um computador em local público.</p>
            <p><br/>Nenhuma transmissão de dados na Internet é 100% segura. Sendo assim, embora a gerenciadora do Portal Soluções Massa sempre faça o possível para proteger suas informações pessoais, não é possível garantir a segurança de todas as informações que você venha a fornecer. É uma decisão pessoal a utilização do serviço nessas condições.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA POSSIBILIDADE DE MODIFICAÇÃO DO SERVIÇO PRESTADO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Portal Soluções Massa estará sempre atento as novidades do mercado e se preocupa com a constante melhoria na prestação de seus serviços. Nesse sentido, nossos desenvolvedores trabalham para oferecer ao usuário um sistema mais seguro, interativo, atrativo e moderno ao usuário e, como consequência disso poderá incluir ou remover funcionalidades no Portal Soluções Massa ou ainda, poderá criar novos limites aos serviços, o que pode acarretar modificação na forma de interação do usuário.</p>
            <p><br/>Considerando que o Portal Soluções Massa não possui obrigação de avisar previamente os usuários quando da ocorrência de qualquer modificação e/ou incorporação de novas funcionalidades de sua plataforma, este poderá enviar mensagens publicitárias ou anúncios nesse sentido. Por tais razões, poderá ocorrer situações em que os serviços serão interrompidos, incluindo, sem limitação, para manutenção ou atualizações programadas para reparos de emergência ou devido a falhas e o Portal se reserva no direito de remover qualquer conteúdo de seu serviço, por qualquer motivo e sem aviso prévio.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DAS GARANTIAS E RESPONSABILIDADES</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O usuário declara sob as penas da Lei que não se sente induzido, coagido ou obrigado a cumprir qualquer disposição contida no presente Termo de Uso, sendo mera opção e exercício do livre arbítrio a decisão de cumprir quaisquer desafios de natureza meramente sugestiva propostos pelo Portal Soluções Massa.</p>
            <p><br/>Em nenhuma hipótese a gerenciadora do Portal Soluções Massa responderá pelos serviços ofertados em eventuais dados publicitários divulgados no site. </p>
            <p><br/>O usuário é o único responsável pelos termos por ele inseridos no Portal Soluções Massa, por comentários, ofensas ou violações de direito por si praticados, isentando o Portal Soluções Massa e sua gerenciadora de qualquer consequência jurídica daí decorrente.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DO SUPORTE TÉCNICO</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O Portal Soluções Massa fornecerá suporte técnico ao usuário na medida em que eventuais falhas nas funcionalidades do site forem constatadas por seus programadores.</p>
            <p><br/>O Portal Soluções Massa incentivará a utilização e detecção de problemas por parte do usuário por meio de disponibilização de espaços específicos para sugestões, críticas e elogios na própria plataforma do site.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA PROTEÇÃO DA MARCA DO PORTAL</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>O nome do Portal Soluções Massa e logotipo são marcas registradas pelos titulares do site e não podem, em hipótese alguma serem copiados, imitados ou utilizadas, no todo ou em parte, sem a permissão prévia por escrito do Portal Soluções Massa ou sua gerenciadora, exceto quando da ocorrência de autorização por parte do Grupo Massa com eventual estipulação contratual permissiva.</p>
            <p><br/>Todos os cabeçalhos, gráficos personalizados, ícones, telas e scripts são marcas comerciais do Portal Soluções Massa e do Grupo Massa, e não podem ser copiadas, imitadas ou utilizadas, no todo ou em parte, sem permissão prévia por escrito do proprietário do Portal e/ou da marca.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>ACEITE DOS TERMOS</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>Ao cadastra, utilizar as ferramentas, recursos e funcionalidades do Portal Soluções Massa, o usuário concorda com este acordo em todos os seus termos aceitando expressamente a os Termos de Uso, a Política de Privacidade e Confidencialidade da Informação, que contêm informações claras e completas sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários, convidados, cadastrados e visitantes do site.  </p>
            <p><br/>O usuário deverá efetuar um cadastro único usando as formas indicadas no Portal Soluções Massa, ciente de que o cadastro criará uma conta pessoal e intransferível. Em razão disso, o Portal Soluções Massa não se responsabilizará pelo uso inadequado e divulgação dos dados do usuário para terceiros, como senhas, dados pessoais, confidenciais, endereços, entre outros, da mesma forma que o Portal Soluções Massa, nem quaisquer de seus empregados ou prepostos, coligadas, subsidiárias, controladoras, controladas e afins, solicitará, por qualquer meio, físico ou eletrônico, os dados de acesso de seus usuários.</p>
            <p><br/>O usuário deverá ler com muita atenção os termos aqui disponibilizados antes de efetuar o cadastro e começar a utilizar o Portal Soluções Massa, ficando livre para não se cadastrar, uma vez que o aceite disponibilizado no ato do cadastramento importa na manifestação de concordância irrevogável e irretratável com todas as disposições deste Termo e com as condições de uso do Portal Soluções Massa.</p>
            <p><br/>A gerenciadora do site, em razão de suspeita ou violação à legislação em vigor, bem como aos Termos e condições gerais de uso, conforme a situação a ser definida por si, poderá, sem prejuízo de outras medidas que a lei lhe faculta, recusar qualquer cadastro de usuário, assim como advertir, suspender, temporária ou definitivamente, a conta de um usuário, independentemente de aviso prévio ou justificativa.</p>
            <p><br/>Não estão sujeitas à cláusula de sigilo de dados, todas as informações cadastrais que sejam solicitadas por meio de ordem judicial, ou para evitar a realização de crimes, contravenções ou qualquer infração legal. </p>
            <p><br/>Caso não concorde com este Termo e Condições Gerais, o usuário não deve se cadastrar no site. Ao utilizar o Portal Soluções Massa, além de todas as disposições prevista na anteriormente, o usuário afirma que tem idade superior a 18 (dezoito) anos completos, com toda a capacidade legal para realizar negócios jurídicos, prestar declarações e se responsabilizar por seus atos.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DA MODIFICAÇÃO DOS TERMOS</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>A gerenciadora do site responsável por operar o Portal Soluções Massa e seus serviços, reserva-se ao direito de modificar este Termo de uso e qualquer política que afete o uso do Portal Soluções Massa, suas funcionalidades, bem como interesses, a qualquer tempo e, independentemente de prévio aviso ao usuário, tornando-se tais modificações vigentes no ato de sua publicação no próprio site e outros locais indicados pela gerenciadora.</p>
            <p><br/>Caso ocorra modificação deste Termo e das condições de utilização do Portal Soluções Massa durante o período de uso continuado pelo usuário, independentemente de o usuário ter realizado cadastrado previamente, recuperar senhas, após qualquer período de inatividade, não ter saído do site, ou ainda ter permanecido initerruptamente ativo, as modificações serão consideradas aceitas tacitamente pelo usuário. Caso o usuário não concorde ou não esteja satisfeito com as mudanças realizadas, ele poderá, a qualquer tempo, excluir o seu cadastro no site.</p>
            <p><br/>Em virtude da previsão acima, o usuário se compromete a verificar periodicamente as eventuais modificações do Termo de Uso, independentemente de qualquer notificação realizada pela empresa gerenciadora.</p>
        </div>

        <h3 className='text-xl font-bold mt-8'>DO FORO COMPETENTE</h3>
        <div className='text-[#656565] text-md font-light leading-6'>
            <p>A competência material para o conhecimento, processamento ou julgamento de qualquer feito judicial, administrativo ou extrajudicial será o foro da comarca de Curitiba, Estado do Paraná, Brasil, renunciando as partes a qualquer outro, por mais privilegiado seja.</p>
        </div>
    </div>
  )
}