import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const Box = ({ onClose, customClass, selectEmpresas, boxRef }) => {
    const [loading, setLoading] = useState(false);
    const [searched, setSearched] = useState(false);
    const [nomeVendedor, setNomeVendedor] = useState('');
    const [contatoWhatsapp, setContatoWhatsapp] = useState('');
    const [contatoEmail, setContatoEmail] = useState('');
    const [cnpj, setCnpj] = useState('');

    useEffect(() => {
        if(selectEmpresas.length === 1){
            var empresa = selectEmpresas[0];
            setCnpj(empresa.value);
        }

        if(cnpj.length > 0){
            searchSellerByCnpj(cnpj);
        }
    }, [cnpj]);

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#fff",
          "&:hover": {
            // borderColor: state.isFocused ? "red" : "blue"
          }
        }),
        menu: base => ({
          ...base,
          borderRadius: 0,
          marginTop: 0,
          marginBottom:10
        }),
        menuList: base => ({
          ...base,
          padding: 0
        })
    };

    function searchSellerByCnpj(search){
        setSearched(false)
        setNomeVendedor('');
        setContatoEmail('');
        setContatoWhatsapp('');
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/seller/contact/cnpj/',{
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({cnpj: search})
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setSearched(true);
            setNomeVendedor(data.data.vendedor.nome);
            setContatoEmail(data.data.vendedor.email);
            setContatoWhatsapp('https://api.whatsapp.com/send?phone=55'+data.data.vendedor.telefone);
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }

  return (
    <div className="fixed inset-0 flex items-end justify-end z-50 mb-24 mr-4 teste-select">
      <div ref={boxRef} className={`bg-[#2785DD] p-6 rounded-md shadow-md w-72 ${customClass}`}>
          {(selectEmpresas.length > 1) ? 
          <Select 
              styles={customStyles}
              className="block px-2.5 w-full text-sm text-white-900 appearance-none text-white-600 peer"
              placeholder="Selecionar empresa"
              isClearable={false} 
              options={selectEmpresas}
              menuPlacement="auto"
              maxMenuHeight={(nomeVendedor !== '') ? 225 : 115}
              onChange={(item) => {
                      setCnpj(item.value);
                  }
              }
          /> : ''}
        {(nomeVendedor !== '') ?
            <>
                <p className="mb-1 text-white font-light text-sm flex justify-center">Seu atendimento é com</p>
                <p className="mb-3 text-white text-xl font-semibold flex justify-center">{nomeVendedor}</p>
                <div className="grid">
                  {contatoWhatsapp !== '' ?
                      <a href={contatoWhatsapp} target="_blank" title="Conversar pelo WhatsApp" rel="noopener noreferrer" className="flex justify-center"><img src='./img/chat/conversar-whatsapp.png' alt='Conversar pelo WhatsApp'/></a>
                      : ''}
                  {contatoEmail !== '' ?
                      <a href={`mailto:${contatoEmail}?subject=Contato via Portal Soluções`} title="Conversar pelo E-mail" rel="noopener noreferrer" className="flex justify-center"><img src='./img/chat/conversar-email.png' alt='Conversar pelo E-mail'/></a>
                      : ''}
                </div>

            </> 
            : (searched) ? 'Nenhum vendedor encontrado.' : ''
        }
      </div>
    </div>
  );
};

export default Box;