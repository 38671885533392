import React, { useState, useEffect } from 'react';
import { useTable, useFlexLayout, useGlobalFilter, useFilters, useSortBy, usePagination, useExpanded, useResizeColumns } from 'react-table'
import { FiChevronsLeft, FiChevronLeft, FiChevronRight, FiChevronsRight  } from 'react-icons/fi'
import { FaSearch, FaUser } from 'react-icons/fa';
import { classNames } from "./Utils";
import FiltroGlobal from './FiltroGlobal'
import Botao from './Botao'
import PageBotao from './PageBotao'


export function StatusTag({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("em aberto") ? "bg-yellow-100 text-yellow-600" : null,
        status.startsWith("pago") ? "bg-green-100 text-green-700" : null,
        status.startsWith("cancelado") ? "bg-red-100 text-red-700" : null,
        status.startsWith("pendente") ? "bg-yellow-100 text-yellow-600" : null,

        status.startsWith("ativo") ? "bg-green-100 text-green-700" : null,
        status.startsWith("inativo") ? "bg-red-100 text-red-700" : null,
      )}
    >
      {status}
    </span>
  );
}

export function FiltroEmpresa ({ column: { filterValue, setFilter, preFilteredRows, id }}) {
  const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
  return(
      <select
        className="select-filtro-compv"
          name={id}
          id={id}
          value={filterValue}
          onChange={(e) => {
              setFilter(e.target.value || undefined);
          }}  
      >
          <option value="">Filtrar por Cliente</option>
          {options.map((option, i) => (
              <option key={i} value={option}>
                  {option}
              </option>
          ))}
      </select>
  )
}

export function FiltroCnpj ({ column: { filterValue, setFilter, preFilteredRows, id }}) {
  const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
  return(
      <select
        className="select-filtro-compv"
          name={id}
          id={id}
          value={filterValue}
          onChange={(e) => {
              setFilter(e.target.value || undefined);
          }}  
      >
          <option value="">Filtrar por CNPJ</option>
          {options.map((option, i) => (
              <option key={i} value={option}>
                  {option}
              </option>
          ))}
      </select>
  )
}

export default function Tabela({
columns, 
data,
renderRowSubComponent,
rowOnClick,
rowClickHandler,
expandRows,
expandedRowObj,
searchChangeHandler
}){
  const {
      getTableProps, 
      getTableBodyProps, 
      headerGroups,  
      prepareRow,

      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,

      state,
      preGlobalFilteredRows,
      setGlobalFilter,
      globalFilter
   } =
  useTable({
    columns,
    data,
    initialState: {
      expanded:
        expandRows && expandedRowObj.hasOwnProperty(0) ? expandedRowObj : {}
    },
  },
  useFilters,
  useGlobalFilter,
  useSortBy,
  useResizeColumns,
  useExpanded,
  usePagination,
  useFlexLayout
  );
  const [searchQuery, setSearchQuery] = useState("");
  
  const [localGlobalFilter, setLocalGlobalFilter] = useState(globalFilter);
  const [localFilters, setLocalFilters] = useState(() => {
    const filters = {};
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach(column => {
        if (column.Filter) {
          filters[column.id] = column.filterValue || '';
        }
      });
    });
    return filters;
  });
  const applyFilters = () => {
    setGlobalFilter(localGlobalFilter);
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach(column => {
        if (column.Filter) {
          column.setFilter(localFilters[column.id]);
        }
      });
    });
  };

  return(
  <>
  <div className='flex gap-2 mb-2'>
    <FiltroGlobal preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} setSearchQuery={setSearchQuery}/>
    {headerGroups.map((headerGroup) =>
        headerGroup.headers.map((column) =>
          column.Filter ? (
            <div className=' w-1/3' key={column.id}>
              {/* <label for={column.id}>{column.render("Header")} </label> */}
              <FaUser className='absolute mt-3 ml-4 text-[#656565]' />
              {column.render("Filter")}
            </div>
          ) : null
        )
    )}
      <button className='bg-[#112587] px-3 py-3 rounded-full' onClick={applyFilters}><FaSearch className='text-[#FFFFFF]' /></button>
  </div>

<div className="mt-2 flex flex-col">
  <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} border="1" className="divide-y divide-gray-200 w-full">
                <thead className="bg-[#efefef]">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-6 py-4 text-left text-sm font-medium text-gray-500 tracking-wider">
                          {column.render("Header")}
                          <span>
                              {column.isSorted ? column.isSortedDesc ? ' ▼' : ' ▲' : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <React.Fragment key={i + "_frag"}>
                              <tr
                                {...row.getRowProps()}
                                onClick={
                                  rowOnClick
                                    ? () => rowClickHandler(row.original)
                                    : () => ""
                                }
                              >
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()} className="px-6 py-6 text-sm text-[#1E1E1E] font-normal">
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                              {row.isExpanded ? (
                                <tr>
                                  <td className="px-6 py-4">
                                    <span className="subTable">
                                      {renderRowSubComponent({ row })}
                                    </span>
                                  </td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              <div className="py-3 px-3 flex items-center justify-between filtros-bottom">
                  <div className="flex-1 flex justify-between sm:hidden">
                    <Botao onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</Botao>
                    <Botao onClick={() => nextPage()} disabled={!canNextPage}>Próximo</Botao>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="flex gap-x-2">
                          <span className="text-sm text-gray-700"> Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageOptions.length}</span></span>
                          <select
                          value={state.pageSize}
                          onChange={e => {
                              setPageSize(Number(e.target.value))
                          }}
                          >
                          {[5, 10, 20].map(pageSize => (
                              <option key={pageSize} value={pageSize}>
                              Mostrar {pageSize}
                              </option>
                          ))}
                          </select>
                      </div>
                      <div>
                      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <PageBotao
                          className="rounded-l-md"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          <span className="sr-only">Primeira</span>
                          <FiChevronsLeft className="h-5 w-5" aria-hidden="true" />
                        </PageBotao>
                        <PageBotao
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          <span className="sr-only">Anterior</span>
                          <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
                        </PageBotao>
                        <PageBotao
                          onClick={() => nextPage()}
                          disabled={!canNextPage
                          }>
                          <span className="sr-only">Próxima</span>
                          <FiChevronRight className="h-5 w-5" aria-hidden="true" />
                        </PageBotao>
                        <PageBotao
                          className="rounded-r-md"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          <span className="sr-only">Última</span>
                          <FiChevronsRight className="h-5 w-5" aria-hidden="true" />
                        </PageBotao>
                      </nav>
                    </div>
                  </div>
                </div>
            </div>
    </div>
  </div>
</div>

  </>
  )
}

Tabela.defaultProps = {
  rowOnClick: false,
  showPagination: false,
  expandRows: false,
  expandedRowObj: {}
};