import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';

export default function DashInicio() {
  return (
        <Routes>
            <Route path='/' element={<Dashboard />}/>
        </Routes>
  )
}