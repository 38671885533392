import React from "react";
import { Outlet } from 'react-router'

const LayoutExterno = (props) => {
return  <div className="max-h-screen flex w-full-mobile">
            <div className="hidden lg:block relative w-0 flex-1 w-1/2 h-screen">
                <div className="flex max-h-full">
                    <img className="rounded-l-none rounded-[22px] w-screen h-screen object-cover" src="/img/banner-instagram-2024.jpg" alt="banner" />
                </div>
            </div>

            <div className="flex p-2 py-6 sm:p-0 sm:py-0 lg:flex-none lg:px-10 xl-px-14 w-1/2 overflow-hidden w-full-mobile">
                <div className="grid items-center overflow-y-scroll">
                    <div className="mx-auto w-full max-w-lg">
                        <img className="lg:hidden w-auto" src="/img/banner-instagram-2024.jpg" alt="banner"/>
                        <Outlet />
                    </div>
                    {(props.regua === "true")
                    ?   <div className="w-8/12 sticky top-0 flex justify-center m-auto ">
                            <img src="/img/Regua_Grupo-Massa_abr2023_PRINCIPAL-HORIZONTAL.png" alt="Grupo Massa" />
                        </div>
                    : ''}
                </div>
            </div>
        </div>
};

export default LayoutExterno;