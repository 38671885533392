import React, { useState, useEffect, useCallback } from 'react';
import ReactSlider from 'react-slider';

const formatCurrency = (value) => {
    let v = value == null || typeof value == 'undefined' ? 0.00 : value;
    return v.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0
    });
  };

export function FiltroSlider({
    column: { filterValue = [], preFilteredRows, setFilter, id, filtroRange },
}) {
    // console.log(['FiltroSlider',filtroRange])
    const [min, max] = filtroRange;
    const [range, setRange] = useState(filterValue.length ? filterValue : [min, max]);//cod antigo abaixo
    //const [range, setRange] = useState([min, max]);

    const debounce = (func, delay) => { //evitar multiplas atualizações/renderizações do estado
      let debounceTimer;
      return function(...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
    };

    const updateFilter = useCallback(debounce((newRange) => { //callback para memorizar o updateFilter
      // console.log(['DEBÁUNSSER',newRange]);
      setFilter(newRange);
    }, 900), [setFilter]);//cod antigo abaixo
    // useEffect(() => {
    //     setFilter(range);
    //   }, [range, setFilter]);

    useEffect(() => {
      // console.log('useeffect setrange',filterValue);
      if (filterValue.length) {
        setRange(filterValue);
      }
    }, [filterValue]);

    useEffect(() => {
      // console.log('useeffect updateFilter',range);
      updateFilter(range);
    }, [range, updateFilter]);

  return (
    <div className="range-slider">
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        defaultValue={[min, max]}
        value={range}
        min={min}
        max={max}
        step={100}
        onChange={(values) => {
          setRange(values);
        }}
        renderTrack={(props, state) => {
          if (state.index === 0) {
            return <div {...props} className="example-track" />;
          }
          if (state.index === 1) {
            return <div {...props} className="example-track example-track-between" />;
          }
        }}
      />
      <div className="range-values flex justify-between mt-3 text-[#1E1E1E] text-md">
        <span>{formatCurrency(range[0])}</span>
        <span>{formatCurrency(range[1])}</span>
      </div>
    </div>
  );
}