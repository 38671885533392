import React, { useState, useEffect } from 'react'
import { FaLinkedinIn } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'

export default function SocialLogin() {

  const [loginGoogleUrl, setLoginGoogleUrl] = useState(null);
  const [loginLinkedinUrl, setLoginLinkedinUrl] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BACK_URL + '/api/auth-social', {
      headers: {
        'Content-type' : 'application/json',
        'Accept': 'application/json'
      }
    })
    .then((response) => {
      if(response.ok){
        return response.json();
      }
      throw new Error('Error');
    })
    .then((data) => {setLoginGoogleUrl(data.url_google); setLoginLinkedinUrl(data.url_linkedin)})
    .catch((error) => console.log(error));
  });

  return (
    <>  
        {(loginGoogleUrl != null || loginLinkedinUrl != null) ?
        <fieldset className="border-t border-slate-300">
            <legend className="mx-auto px-4 text-sso-option">ou</legend>
        </fieldset>
        :
        ''}
        {
            (() => {
              if(loginGoogleUrl != null){
                return(
                  <a href={loginGoogleUrl} className="group relative w-full flex justify-evenly py-2 px-4 text-sm text-grey-700 font-medium duration-200 rounded-md btn-login-google"><FcGoogle size={20}/> Entrar com Google</a>      
                )
              }
            })()
        }
        {
            (() => {
              if(loginLinkedinUrl != null){
                return(
                  <a href={loginLinkedinUrl} className="group relative w-full flex justify-evenly py-2 px-4 border border-transparent text-sm text-white font-medium duration-200 rounded-md btn-login-linkedin"><FaLinkedinIn size={20} /> Entrar com LinkedIn</a>
                )
              }
            })()
        }
        
        { /*
        <button className="group relative w-full flex justify-evenly py-2 px-4 border border-transparent text-sm text-white font-medium duration-200 rounded-md bg-blue-600 hover:">
            <FaFacebookSquare size={20}/> Entrar com Facebook
        </button> */}
    </>    
  )
}
