import React from "react";
import Sidebar from './Sidebar'
import { Outlet } from 'react-router'
import { hotjar } from 'react-hotjar';
import BotaoFlutuante from './BotaoFlutuante';

hotjar.initialize(3621506, 6);

const Layout = (props) => {
return  <div className='flex'>
            <Sidebar />
            <div className='columns-1 w-full overflow-auto'>
                <div className='flex justify-center mt-14 lg:mt-0'>
                    <div className='xl:container p-5 h-full'>
                        <Outlet />
                    </div>
                </div>
            </div>
            <BotaoFlutuante />
        </div>
};

export default Layout;