import React from "react";

const fixedInputClass="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-700 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-[#C0C0C0]";

const SingleInput = ({ label, name, type = "text", customClass = '', showAlert = false, mensagemErro = '', ...rest }) => {
  return (
    <div className="relative z-0 w-full mb-2 mt-6">
      <input
        {...rest}
        id={name}
        type={type}
        placeholder=" "
        className={fixedInputClass+customClass}
      />
      <label
        htmlFor={name}
        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
      >
        {label}
      </label>
      <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 mt-1 p-1" style={{ display:(showAlert && typeof(mensagemErro) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro) != 'undefined' ? mensagemErro : ''}</span>
    </div>
  );
};

export default SingleInput;
