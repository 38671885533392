import React from "react";
import {IoCloseOutline} from 'react-icons/io5'

export default function Modal({ closeModal, conteudo: Conteudo, classe = true }) {
    return (
        <>
            <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-75 z-50">
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className={(classe) ? "relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg" : "relative p-4 mx-auto bg-white rounded-md shadow-lg"} >
                        <IoCloseOutline className="absolute top-2 right-2 text-xl cursor-pointer hover:text-blue-900" onClick={closeModal}/>
                        <div className="mt-3 sm:flex">
                            <Conteudo />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    