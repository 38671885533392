const loginFields=[
    {
        labelText:"Email address",
        labelFor:"email",
        id:"email",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"E-mail"   
    },
    {
        labelText:"Password",
        labelFor:"password",
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Senha"   
    }
]

const signupFields=[
    {
        labelText:"Nome completo",
        labelFor:"username",
        id:"username",
        name:"username",
        type:"text",
        autoComplete:"username",
        isRequired:true,
        placeholder:"Nome Completo"   
    },
    {
        labelText:"E-mail",
        labelFor:"email",
        id:"email",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"E-mail"   
    },
    {
        labelText:"Telefone",
        labelFor:"telephone",
        id:"telephone",
        name:"telephone",
        type:"tel",
        autoComplete:"telephone",
        isRequired:true,
        placeholder:"Telefone"   
    },
    {
        labelText:"Estado",
        labelFor:"estado",
        id:"estado",
        name:"estado",
        type:"text",
        autoComplete:"estado",
        isRequired:true,
        placeholder:"Estado"   
    },
    {
        labelText:"Cidade",
        labelFor:"cidade",
        id:"cidade",
        name:"cidade",
        type:"text",
        autoComplete:"cidade",
        isRequired:true,
        placeholder:"Cidade"   
    },
    {
        labelText:"Cargo",
        labelFor:"cargo",
        id:"cargo",
        name:"cargo",
        type:"text",
        autoComplete:"cargo",
        isRequired:true,
        placeholder:"Cargo"   
    },
    {
        labelText:"Senha",
        labelFor:"password",
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Senha"   
    },
    {
        labelText:"Confirmar senha",
        labelFor:"confirm-password",
        id:"confirm-password",
        name:"confirm-password",
        type:"password",
        autoComplete:"confirm-password",
        isRequired:true,
        placeholder:"Confirme sua Senha"
    }
]

export {loginFields,signupFields}