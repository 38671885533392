const InfoProjetos = [
  {
    titulo: 'Mais Verão',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos/maisverao.png'
  },
  {
    titulo: 'Outubro Rosa',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos/outubrorosa.png'
  },
  {
    titulo: 'Transmita Calor',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos/transmitacalor.png'
  },
]

const InfoCalendario = [
  {
    titulo: 'Dia das Mães',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/calendario/diadasmaes.png'
  },
  {
    titulo: 'Dia dos Pais',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/calendario/diadospais.png'
  },
]

const InfoSegmentos = [
  {
    titulo: 'Dicas Massa',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/segmentos/dicasmassa.png'
  },
]

const InfoPersonalizados = [
  {
    titulo: 'Furacão é Massa',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/personalizados/furacaoemassa.png'
  },
  {
    titulo: 'Momento brbet',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/personalizados/momentobrbet.png'
  },
  {
    titulo: 'Só para os Fords',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/personalizados/soparaosfords.png'
  },
]

const InfoProjetosDeMercado = [
  {
    titulo: 'Campeonato Amadorzão 2023',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos-de-mercado/campeonatoamadorzao2023.png'
  },
  {
    titulo: 'Expoinga 2023',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos-de-mercado/expoinga2023.png'
  },
  {
    titulo: 'Expo Londrina 2023',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos-de-mercado/expolondrina2023.png'
  },
  {
    titulo: 'Festival Gastronomico',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/projetos-de-mercado/festivalgastronomico.png'
  },
]

const InfoSeriesEGuias = [
  {
    titulo: 'Arruma Minha Bagunça',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/series-e-guias/arrumaminhabagunca.png'
  },
  {
    titulo: 'Você sabia?',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/series-e-guias/vocesabia.png'
  },
]

const InfoFormatos = [
  {
    titulo: 'Ação Especial',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/formatos/acaoespecial.png'
  },
  {
    titulo: 'Bumper',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/formatos/bumper.png'
  },
  {
    titulo: 'Top 5',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/formatos/top5.png'
  },
  {
    titulo: 'Vinheta Animada',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/formatos/vinhetaanimada.png'
  },
  {
    titulo: 'Vinheta de Passagem',
    link: '',
    imagemUrl: './img/projeto-solucoesnegocios/formatos/vinhetadepassagem.png'
  },
]
export {InfoProjetos, InfoCalendario, InfoSegmentos, InfoPersonalizados, InfoProjetosDeMercado, InfoSeriesEGuias, InfoFormatos}