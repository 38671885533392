import React from 'react';
import {Link} from 'react-router-dom';

export default function Header({
    heading,
    paragraph,
    subParagraph,
    linkName,
    linkUrl="#",
    customClass,
    customClassParagraph
}){
    return(
        <div className="mb-10 text-left">
            {/* <div className="flex justify-center">
                <img 
                    alt=""
                    className="h-37 w-37"
                    src="https://s3-sa-east-1.amazonaws.com/prod-jobsite-files.kenoby.com/uploads/grupomassa-1578690543-ativo-3png.png"/>
            </div> */}
            <h2 className={(customClass) ? "mt-6 " +customClass : "mt-6 text-center text-3xl font-extrabold text-gray-900"}>
                {heading}
            </h2>
            <p className={(customClassParagraph) ? " "+customClassParagraph : "text-sm text-gray-600"}>
            {paragraph} {' '}
            {(subParagraph) ? subParagraph.split('\n').map((item, i) => '\n'+item.replace(/\\n/g, '')) : '' }
            <Link to={linkUrl} className="font-medium text-link-gm">
                {linkName}
            </Link>
            </p>
        </div>
    )
}