export const data = {
    data: {
      getGroupedUsuarioInfo: [
        {
            usuario: "Jean Carlo olsen",
            numEmpCadastrada: "2",
            dataRegistro: "01/03/2019",
            status: "Ativo",
            permissao: "Administrador",
            empresasUsuario: [
                {
                  empresa: "123 LTDA",
                  cnpj: "67.883.926/0001-43",
                  dataCadastro: "15/06/2011",
                  tipoEmpresa: "Cliente",
                  situacaoEmpresa: "Ativo",
                },
                {
                    empresa: "456 LTDA",
                    cnpj: "67.883.926/0001-43",
                    dataCadastro: "15/06/2011",
                    tipoEmpresa: "Cliente",
                    situacaoEmpresa: "Inativo",
                  },
              ]
          },
          {
            usuario: "Camila Souza",
            numEmpCadastrada: "0",
            dataRegistro: "18/04/2022",
            status: "Ativo",
            permissao: "Usuário",
            empresasUsuario: []
          },
          {
            usuario: "Ricardo Luiz",
            numEmpCadastrada: "0",
            dataRegistro: "22/02/2020",
            status: "Ativo",
            permissao: "Usuário",
            empresasUsuario: []
          },
          {
            usuario: "Jamilson Bine",
            numEmpCadastrada: "0",
            dataRegistro: "10/03/2020",
            status: "Ativo",
            permissao: "Usuário",
            empresasUsuario: []
          },
          {
            usuario: "KOJNSAODNSA",
            numEmpCadastrada: "0",
            dataRegistro: "10/03/2019",
            status: "Inativo",
            permissao: "Visitante",
            empresasUsuario: []
          },
      ]
    }
};
