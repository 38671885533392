import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token != null){
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/logout', {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);
                setData(data);
            });
        } else{
            navigate('/', { replace: true });
        }

    }, []);

    if(loading){
        return <DisplayLoading/>
    } else{
        if(data.success){
            localStorage.clear();
            navigate('/', { replace: true });
        } else{
            navigate('/dash', { state: {alert: true, alertMsg: 'Não foi possível desconectar.'}});
        }
    }

}

function DisplayLoading(){
    return <div>Desconectando...</div>
}

export default Logout;