import React from 'react';
import Header from '../HeaderForm';
import Login from '../Login';
import SocialLogin from '../SocialLogin';

export default function LoginPage() {
  return (
    <div className="flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8" >
            <Header 
                heading="Entre na sua conta"
                paragraph="Ainda não tem uma conta? "
                linkName="Cadastre-se"
                linkUrl="/cadastro"
                customClass="titulo-login"
            /> 
            <Login />
            <SocialLogin />
        </div>
    </div>
  )
}
