import React from 'react'

export default function FormAction({
    handleSubmit,
    type = 'Button',
    action = 'submit',
    loading = false,
    text
}) {
    return (
        <>
            {
                type === 'Button' ?
                    <button
                        disabled={loading ? 'disabled' : ''}
                        type={action}
                        className="group relative w-full flex justify-center py-3 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-10 btn-submit"
                        onSubmit={handleSubmit}
                    >
                        <svg style={{display:(loading? "block": "none")}} className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {loading ? 'Aguarde...' : text}
                    </button>
                    :
                    <></>
            }
        </>
    )
}