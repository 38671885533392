import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Avatar  from '../Avatar'
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
import UsuariosDaEmpresa from '../UsuariosDaEmpresa'
// import AttTipoEmpresa from '../AttTipoEmpresa'
import { formatarCnpj, formatarTelefone } from '../Form/helper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const selectTipoEmpresa = [
  { value: '1', label: 'Afiliada' },
  { value: '2', label: 'Agência' },
  { value: '3', label: 'Cliente' },
  { value: '4', label: 'Fornecedor' },
  { value: '5', label: 'Prospect' },
]

const selectCidade = []
  
const selectEstado = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' }
]

export default function MeusDados() {
  const [rsocial, setRsocial] = useState("");
  const [nomefantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [idTipoEmpresa, setIdTipoEmpresa] = useState("");

  const [cidade, setCidade] = useState("");
  const [cidadeSelecionada, setCidadeSelecionada] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState("");

  const [updateErrorState,setUpdateErrorState]=useState({});
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [idTipoEmpresaSelecionado, setIdTipoEmpresaSelecionado] = useState("");
  const [usuarios,setUsuarios]=useState({});
  const [ipUser, setIPUser] = useState("");

  const handleSubmit=(e)=>{
    e.preventDefault();
    updateEmpresa()
  }

  const getIpUser = async () => {
    const res = fetch('https://api.ipify.org/?format=json')
                .then(response => response.json())
                .then(data => {
                    setIPUser(data.ip);
                });
  };

  const updateEmpresa=(e)=>{
      //Atualizar dados da empresa
      setLoading(true);
      const token = localStorage.getItem("token");
      const idCompany = window.location.href.split('/')[6];
    
      const updateData = {
          razao_social:rsocial,
          nome_fantasia:nomefantasia,
          cidade:cidade,
          estado:estado,
          telefone:telefone,
          cnpj:cnpj,
          email:email,
          id_tipo_empresa:idTipoEmpresa,
          ip: ipUser,
          user_agent: window.navigator.userAgent,
      };

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/edit/company/' + idCompany, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(updateData)
    }).then(response => 
        response.json().then(response => ({ response })
    ).then(res => {
        setLoading(false);
        setAlert(true);
        if(res.response.success === true){
            setTitulo(nomefantasia);
            toast.success(res.response.message, {autoClose:3000})
        } else{
            toast.warning(res.response.message, {autoClose:3000})
        }
    }));
  }

  const handlePassSubmit=(e)=>{
    e.preventDefault();
    updateSenha()
  }
  const updateSenha=(e)=>{
    //Atualizar senha da empresa
  }

  const definirCidades = (estado, buscar = '') => {
    setLoading(true);
    setCidadeSelecionada('');
    let response = fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          while(selectCidade.length > 0) {
              selectCidade.pop();
          }
          data.map((items =>
              selectCidade.push({value:items.nome, label:items.nome})
          ));
          if(buscar !== ''){
              const itemCidade = selectCidade.find(({ value: v }) => v === buscar ); 
              setCidadeSelecionada(itemCidade);
          }
          setLoading(false);
      });
  };

  useEffect(() => {
      setLoading(true);
      getIpUser();
      const idCompany = window.location.href.split('/')[6];
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/company/' + idCompany,{
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': "Bearer " + token
          }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          setRsocial(data.data.company.razao_social);
          setCnpj(formatarCnpj(data.data.company.cnpj));
          setEmail(data.data.company.email);
          setNomeFantasia(data.data.company.nome_fantasia);
          setTelefone(formatarTelefone(data.data.company.telefone));
          setEstado(data.data.company.estado);
          setCidade(data.data.company.cidade);
          setIdTipoEmpresa(data.data.company.id_tipo_empresa);
          setTitulo(data.data.company.nome_fantasia);
          setUsuarios(data.data.usuarios);

          const itemTipoEmpresa = selectTipoEmpresa.find(({ value: v }) => v === data.data.company.id_tipo_empresa);
          setIdTipoEmpresaSelecionado(itemTipoEmpresa);

          const itemEstado = selectEstado.find(({ value: v }) => v === data.data.company.estado ); 
          setEstadoSelecionado(itemEstado);
          
          definirCidades(data.data.company.estado, data.data.company.cidade).then((r)=>{
            if(selectCidade.length > 0){
              const itemCidade = selectCidade.find(({ value: v }) => v === data.data.company.cidade ); 
              setCidadeSelecionada(itemCidade);
            } else{
              console.log(data.data.company.cidade);
            }
          });          
      })
      .catch((err) => {
          // Erro
      })
      .finally(() => {
          setLoading(false);
      });

  }, []);

  return (
    <>
      <div className='
      h-56 
      w-full 
      bg-[url("https://images.unsplash.com/photo-1485727373771-86ef43f2cbeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80")] 
      bg-no-repeat
      bg-cover
      rounded-lg
      '>
      </div>
      <div className=' mx-10 bg-white -mt-20 rounded-lg p-5'>
        <div className='grid grid-cols-[100px_minmax(800px,_1fr)_10px]'>
          <Avatar width='20' height='20' size='4' color='white'/>
          <div className='flex items-center'>
            <div>
              <span className='block text-xl text-slate-800 font-semibold'>{titulo}</span>
            </div>
          </div>
          <span></span>
        </div>
        
        <div className="w-auto">
          <div className='grid grid-cols-2 h-auto gap-6 auto-cols-max divide-x divide-slate-400/25'>
            <form className="mt-6 col-span-2" onSubmit={handleSubmit}>
              <label className='block text-xl text-slate-800 font-semibold py-6'>Atualizar Dados Cadastrais</label>
              <div className='grid grid-cols-2'>
              <div className=''>
                <SingleInput label="Razão Social"
                name="rsocial"
                type="text"
                value={rsocial}
                onChange={(v) => setRsocial(v.target.value)} />
                <SingleInput label="Nome Fantasia"
                name="nomefantasia"
                type="text"
                value={nomefantasia}
                onChange={(v) => setNomeFantasia(v.target.value)} />
                <SingleInput label="CNPJ"
                name="cnpj"
                type="text"
                value={cnpj}
                onChange={(v) => setCnpj(formatarCnpj(v.target.value))} />
                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateErrorState.cnpj) != 'undefined' ? "block": "none") }}>{typeof(updateErrorState.cnpj) != 'undefined' ? updateErrorState.cnpj : ''}</span>
                <SingleInput label="E-mail"
                name="email"
                type="email"
                value={email}
                onChange={(v) => setEmail(v.target.value)} />
                <SingleInput label="Telefone"
                name="telefone"
                type="tel"
                value={telefone}
                onChange={(v) => setTelefone(formatarTelefone(v.target.value))} />
              </div>
              <div className='pl-6'>
              {/* <SingleInput label="CEP"
                name="cep"
                type="text"
                value={cep}
                onChange={(v) => {buscarCep(formatarCep(v.target.value));setCep(formatarCep(v.target.value))}} />
                <SingleInput label="Endereço"
                name="endereco"
                type="text"
                value={endereco}
                onChange={(v) => setEndereco(v.target.value)} />
                <SingleInput label="Bairro"
                name="bairro"
                type="text"
                value={bairro}
                onChange={(v) => setBairro(v.target.value)} /> */}
                <Select 
                className="mb-8 mt-6"
                placeholder="Selecione um Estado"
                options={selectEstado.map((guest, index) => {
                  return {
                    label: guest.label,
                    value: guest.value,
                    key: index
                  };
                })}
                onChange={(item) => {
                  setEstado(item.value);
                  setEstadoSelecionado(item);
                  definirCidades(item.value);
                }}
                value={estadoSelecionado}
                />
                <Select 
                className="mt-6"
                placeholder="Selecione uma Cidade"
                isClearable={false} 
                options={selectCidade}
                onChange={(item) => {
                  setCidade(item.value);
                  setCidadeSelecionada(item);
                }}
                value={cidadeSelecionada}
                />
                <Select 
                    className="mt-6"
                    placeholder="Selecione um Tipo:"
                    isClearable={false} 
                    options={selectTipoEmpresa}
                    onChange={(item) => {
                        setIdTipoEmpresa(item.value);
                        setIdTipoEmpresaSelecionado(item);
                    }}
                    value={idTipoEmpresaSelecionado}
                /> 
                <FormAction handleSubmit={handleSubmit} loading={loading} text="Atualizar Dados" />
              </div>
              </div>
            </form>
            
            
            {/* <div className='pl-6'>
              <form className='mt-6' onSubmit={handlePassSubmit}>
              <label className='block text-xl text-slate-800 font-semibold py-6'>Atualizar Senha</label>
              <SingleInput label="Senha Atual"
              name="senhaatual"
              type="password"
              value={senhaatual}
              onChange={(v) => setSenhaAtual(v.target.value)} />
              <SingleInput label="Nova Senha"
              name="novasenha"
              type="password"
              value={novasenha}
              onChange={(v) => setNovaSenha(v.target.value)} />
              <SingleInput label="Confirmar Senha"
              name="confirmarsenha"
              type="password"
              value={confirmarsenha}
              onChange={(v) => setConfirmarSenha(v.target.value)} />
              <FormAction handleSubmit={handlePassSubmit} text="Atualizar Senha" />
              </form>
              <AttTipoEmpresa />
            </div> */}
            <div>
                <ToastContainer />
            </div>
          </div>
        </div>
        {(usuarios && usuarios.length > 0) ? (
            <UsuariosDaEmpresa conteudo={usuarios} />
        ) : (loading) ? 
            'Carregando...' : 
            'Nenhum dado a apresentar'
        }
      </div>
    </>
    
  )
}