import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../Modal'
import FormBaixarLogs from '../Form/FormBaixarLogs';

export default function Estatisticas(){
    const [showModal, setOpenModal] = useState(false);
    const [ipUser, setIPUser] = useState("");

    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

    const [loading, setLoading] = useState(false);

    const getIpUser = async () => {
        setLoading(true);
        const res = fetch('https://api.ipify.org/?format=json')
                    .then(response => response.json())
                    .then(data => {
                        setIPUser(data.ip);
                    });
        setLoading(false);
    };

    useEffect(() => {
        getIpUser();
    }, []);

    const [estatisticas, setEstatisticas] = useState(() => {
        setLoading(true);
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/statistics',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setEstatisticas(data.data);
        })
        .catch((err) => {
            toast.error('Não foi possível obter os dados.', {autoClose:3000})
        })
        .finally(() => {
            setLoading(false);
        });
    });
    
    function getFile(e){
        setLoading(true);
        const token = localStorage.getItem("token");
        const tipoDados = e.target.dataset.tipo;
        const textoAnterior = e.target.textContent;
        const nomeArquivo = e.target.dataset.filename;
        const postData = {
                            ip: ipUser,
                            user_agent: window.navigator.userAgent,
                        };
        e.target.textContent = 'Aguarde...';
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/export-'+tipoDados,{
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                },
                body: JSON.stringify(postData)
        })
        .then((response) => {
            if(response.status === '404'){
                toast.error('Arquivo indisponível no momento.', {autoClose:3000})  
            } else{
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = nomeArquivo;
                    alink.click();
                    toast.success('Download realizado com sucesso!', {autoClose:3000})
                })
            }
        })
        .catch((err) => {
            toast.error('Não foi possível realizar o seu download no momento.', {autoClose:3000})
        })
        .finally(() => {
            setLoading(false);
            e.target.textContent = textoAnterior;
        });
    }

    return(
        <div className="min-h-screen bg-white text-gray-900">
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                <div className="mt-4 w-auto">
                    {estatisticas && Object.keys(estatisticas).length > 0 ? (
                        <>
                            <div class="text-right">
                                <button onClick={getFile} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded mx-2' data-tipo="clients" data-filename="" title="Baixar dados de clientes" { ... (loading) ? 'disabled' : ''}>
                                    Baixar clientes
                                </button>
                                <button onClick={getFile} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded mx-2' data-tipo="prospects" data-filename="" title="Baixar dados de prospects" { ... (loading) ? 'disabled' : ''}>
                                    Baixar prospects
                                </button>
                                <button onClick={openModal} className='bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border rounded mx-2' data-tipo="logs" data-filename="" title="Baixar todos os logs" {... (loading) ? 'disabled' : ''}>
                                    Baixar logs
                                </button>
                            </div>
                            <div class="p-5">
                                <h3 class="mb-4">
                                    Estatísticas gerais
                                </h3>
                                <div class="container mt-4 mx-auto">
                                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                                        <div class="card m-2 bg-sidebar border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0">
                                            <div class="m-3">
                                                <h2 class="text-2xl font-bold mb-2">{estatisticas.total_usuarios}</h2>
                                                <p class=""><b>Usuários</b> cadastrados no sistema</p>
                                            </div>
                                        </div>
                                        <div class="card m-2 bg-sidebar border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0">
                                            <div class="m-3">
                                                <h2 class="text-2xl font-bold mb-2">{estatisticas.total_downloads_nf}</h2>
                                                <p class="">Downloads de <b>notas fiscais</b></p>
                                            </div>
                                        </div>
                                        <div class="card m-2 bg-sidebar border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0">
                                            <div class="m-3">
                                                <h2 class="text-2xl font-bold mb-2">{estatisticas.total_downloads_boleto}</h2>
                                                <p class="">Downloads de <b>boletos</b></p>
                                            </div>
                                        </div>
                                        <div class="card m-2 bg-sidebar border border-gray-400 rounded-lg hover:shadow-md hover:border-opacity-0">
                                            <div class="m-3">
                                                <h2 class="text-2xl font-bold mb-2">{estatisticas.total_prospects}</h2>
                                                <p class="">Empresas <b>prospect</b></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (loading) ?
                    <span>
                        Carregando informações...
                    </span>
                    :
                    <span>
                        Sem informações para exibir.
                    </span>
                    }
                    <ToastContainer />

                </div>
            </main>
            {showModal && <Modal closeModal={closeModal} conteudo={FormBaixarLogs}/>}
        </div>
        
    )
}