const selectNivel = [
    { value: '0', label: 'Todos' },
    { value: '1', label: 'Administrador' },
    { value: '6', label: 'Afiliada' },
    { value: '5', label: 'Agência' },
    { value: '2', label: 'Cliente' },
    { value: '3', label: 'Prospect' },
    { value: '4', label: 'Fornecedor' },
];

const selectUsersOrderBy = [
    { value: 'name', label: 'Nome' },
    { value: 'created_at', label: 'Registrado desde' },
];

const selectCompaniesOrderBy = [
    { value: 'cnpj', label: 'CNPJ' },
    { value: 'nome_fantasia', label: 'Nome fantasia' },
    { value: 'razao_social', label: 'Razão social' },
    { value: 'created_at', label: 'Registrado desde' },
];

const selectClientsOrderBy = [
    { value: 'cnpj', label: 'CNPJ' },
    { value: 'nome', label: 'Nome' },
    { value: 'created_at', label: 'Registrado desde' },
];

const selectSortType = [
    { value: 'asc', label: 'Ascendente' },
    { value: 'desc', label: 'Descendente' },
];

const selectFinanceiroOrderBy = [
    { value: 'data_emissao', label: 'Data de emissão' },
    { value: 'nota_fiscal', label: 'Nº nota fiscal' },
    { value: 'valor', label: 'Total' },
];

export {selectNivel, selectUsersOrderBy, selectCompaniesOrderBy, selectClientsOrderBy, selectSortType, selectFinanceiroOrderBy}