import React from 'react'
import { FiChevronDown, FiChevronRight, FiDownload } from 'react-icons/fi'
import Tabela, { StatusTag, FiltroEmpresa, FiltroCnpj } from './ExpandTabela.jsx'
import RowExpand from './RowExpand.jsx'
import { data } from './CompData.js'
import { formatarDataPtBr } from '../Tabela/Tabela'
import { ToastContainer, toast } from 'react-toastify';

export default function ComprovantesVeiculacao({
  data
}) {
  const columns = React.useMemo(
    () => [

      {
        Header: "Nº de PI",
        accessor: "num_pi",
        width: 50,
      },
      {
        Header: "Campanha",
        accessor: "nome_campanha",
      },
      {
        Header: "Início",
        accessor: "data_inicio",
      },
      {
        Header: "Fim",
        accessor: "data_fim",
      },
      {
        Header: "Empresa",
        accessor: "empresa",
        Filter: FiltroEmpresa
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
        Filter: FiltroCnpj
      },
      {
        Header: () => null,
        id: "expander",
        width: 20,
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <FiChevronDown />
            ) : (
              <FiChevronRight />
            )}
          </span>
        )
      },
    ], []);

  const details = React.useMemo(
    () => [
      {
        Header: "Veiculo",
        accessor: "mercado_descricao",
      },
      // {
      //   Header: "Início",
      //   accessor: "inicio",
      // },
      // {
      //   Header: "Fim",
      //   accessor: "fim",
      // },
      {
        Header: "Data emissão",
        accessor: "data_emissao",
        Cell: formatarDataPtBr
      },
      {
        Header: "Nº da NF",
        accessor: "numero_nf_erp",
      },
      {
        Header: "Nota Fiscal",
        accessor: "linkNF",
        Cell: ({ row }) => (
          (typeof row.original.dados_nota_fiscal.serie !== "undefined" && row.original.dados_nota_fiscal.serie === 'NFS') ?

            <button className='flex text-white rounded bg-[#00B473] font-normal text-sm py-2 px-6' onClick={() => redirectNFSe(row.original.dados_nota_fiscal.link_nfse)}>
              NFS-e 
            </button>
            :
            (row.original.dados_nota_fiscal.download_nf !== 'nonexistent') ?
              <button onClick={getFile} className='flex text-white rounded bg-[#00B473] font-normal text-sm py-2 px-6' data-tipo="nota-fiscal" data-arquivo={row.original.dados_nota_fiscal.download_nf} data-filename={row.original.dados_nota_fiscal.nome_arquivo_nota}>
                Baixar <FiDownload className='text-white text-xl ml-1' />
              </button>
              :
              <button className='flex text-white rounded bg-gray-600 disabled:opacity-100 font-normal text-sm py-2 px-6' disabled>
                Indisponível
              </button>
        ),
      },
      {
        Header: "Comp. de Veículação",
        accessor: "linkComp",
        Cell: ({ row }) => (
          <button onClick={(e) => downloadComprovante(e, row.original.num_contrato, row.original.veiculo, row.original.nome_arquivo_comprovante, row.original.cnpj, row.original.cnpj_emissor, row.original.data_emissao)} className='flex text-white py-2 px-6 rounded bg-[#1346ED] font-normal text-sm btn-download-comprovante' data-numcontrato={row.original.num_contrato} data-veiculo={row.original.veiculo} data-filename={row.original.nome_arquivo_comprovante} data-cnpj={row.original.cnpj} data-cnpjemissor={row.original.cnpj_emissor} data-emissaonf={row.original.data_emissao}>
            Baixar <FiDownload className='text-white text-xl ml-1 icon-download' />
          </button>
        ),
      },
      {
        Header: "Art. 299",
        accessor: "linkArt299",
        Cell: ({ row }) => (
          <>
          {
            (row.original.nome_arquivo_art_299 !== 'nonexistent') ? 
            <button onClick={(e) => downloadArt299(e, row.original.veiculo, row.original.numero_nf_erp, row.original.cnpj_emissor, row.original.nome_arquivo_art_299)} className='flex text-white py-2 px-6 rounded bg-[#112587] font-normal text-sm' data-numcontrato={row.original.num_contrato} data-filename={row.original.nome_arquivo_art_299} data-veiculo={row.original.veiculo} data-numnf={row.original.numero_nf_erp} data-cnpjemissor={row.original.cnpj_emissor}>
              Baixar Art. 299 <FiDownload className='text-white text-xl ml-1 icon-download' />
            </button>
            : ''
          }
          </>
        ),
      },
    ]
  )
  const subTable = React.useCallback(
    ({ row }) =>
      row.original.notas_fiscais.length > 0 ? (
        <RowExpand
          columns={details}
          data={row.original.notas_fiscais}
        />
      ) : (
        "Não há informações a serem exibidas."
      ),
    [details]
  );

  const expandedRows = React.useMemo(() => {
    if (data?.data) {
      let arr;
      let d = data.data;
      /* if (d.getGroupedComprovante.length > 0) {
        arr = d.getGroupedComprovante.map((sid, ind) => {
          return { [ind]: true };
        });
      }*/
      return arr;
    }
  }, []);

  function redirectNFSe(url) {
    window.open(url, '_blank').focus();
  }

  function downloadArt299(e, veiculo, numero_nf, cnpj_emissor, nomeArquivo) {
    const token = localStorage.getItem("token");
    const textoAnterior = e.target.textContent;
  
    if(e.target.classList.contains('icon-download')){
      toast.info('Preparando arquivo, aguarde.', { autoClose: 4000 });
    } else{
      e.target.textContent = 'Aguarde...';
    }

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/download/comprovante/art299?veiculo=' + veiculo + '&num_nf='+numero_nf+'&cnpj_emissor=' + cnpj_emissor, {
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        toast.error('Arquivo indisponível no momento.', { autoClose: 3000 })
      } else {
        response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = nomeArquivo;
          alink.click();
          toast.success('Download realizado com sucesso!', { autoClose: 3000 })
        })
      }
    })
    .catch((error) => {
      toast.error('Não foi possível realizar o seu download no momento.', { autoClose: 3000 })
    })
    .finally(() => {
      if(e.target.classList.contains('icon-download')){
        // e.target.textContent = textoAnterior;
      } else{
        e.target.textContent = textoAnterior;
      }
    });
  }

  function downloadComprovante(e, num_contrato, veiculo, nome_arquivo_comprovante, cnpj, cnpj_emissor, data_emissao) {
    const token = localStorage.getItem("token");
    const nomeArquivo = nome_arquivo_comprovante;
    const textoAnterior = e.target.textContent;
    
    if(e.target.classList.contains('icon-download')){
      toast.info('Preparando arquivo, aguarde.', { autoClose: 4000 });
    } else{
      e.target.textContent = 'Aguarde...';
    }

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/download/comprovante/veiculacao?veiculo=' + veiculo + '&num_contrato=' + num_contrato + '&cnpj_emissor=' + cnpj_emissor + '&emissao_nf=' + data_emissao, {
      method: "GET",
      responseType: "arraybuffer",
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          toast.error('Arquivo indisponível no momento.', { autoClose: 3000 })
        } else {
          response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = nomeArquivo;
            alink.click();
            toast.success('Download realizado com sucesso!', { autoClose: 3000 })
          })
        }
      })
      .catch((error) => {
        toast.error('Não foi possível realizar o seu download no momento.', { autoClose: 3000 })
      })
      .finally(() => {
        if(e.target.classList.contains('icon-download')){
          // e.target.textContent = textoAnterior;
        } else{
          e.target.textContent = textoAnterior;
        }
      });
  }

  function getFile(e) {
    const token = localStorage.getItem("token");
    const idArquivo = e.target.dataset.arquivo;
    const tipoDocumento = e.target.dataset.tipo;
    const textoAnterior = e.target.textContent;
    const nomeArquivo = e.target.dataset.filename;
    const postData = {
      user_agent: window.navigator.userAgent,
    };
    e.target.textContent = 'Aguarde...';
    fetch(process.env.REACT_APP_API_BACK_URL + '/api/documento/' + tipoDocumento + '/' + idArquivo, {
      method: 'POST',
      responseType: 'arraybuffer',
      headers: {
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(postData)
    })
      .then(response => {
        if (response.status !== 200) {
          toast.error('Arquivo indisponível no momento.', { autoClose: 3000 })
        } else {
          response.blob().then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = nomeArquivo;
            alink.click();
            toast.success('Download realizado com sucesso!', { autoClose: 3000 })
          })
        }
      })
      .catch((error) => {
        toast.error('Não foi possível realizar o seu download no momento.', { autoClose: 3000 })
      })
      .finally(() => {
        e.target.textContent = textoAnterior;
      });
  }

  return (
    <>
      <div className="min-h-screen bg-white text-gray-900">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <h1 className='font-bold text-2xl text-[#1e1e1e] pt-8 pb-3'>Comprovantes de Veiculação</h1>
          <div className="mt-4 w-auto">

            {data ? (
              <Tabela
                columns={columns}
                data={data}
                renderRowSubComponent={subTable}
                expandedRowObj={expandedRows}
              />
            ) : (
              <span>
                <em>Nenhuma informação encontrada.</em>
              </span>
            )}
          </div>
        </main>
      </div>
      <div>
        <ToastContainer />
      </div>
    </>
  )
}