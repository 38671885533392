import React, {useState} from "react";
import Header from "../HeaderForm";
import SingleInput from "../SingleInput";
import FormAction from "../FormAction";
import { FaArrowLeft } from 'react-icons/fa'

export default function EsqueceuSenha(){
    const [email, setEmail] = useState("");
    const [resetPassErrorState,setResetPassErrorState]=useState(false);
    const [resetPassErrorMsg,setResetPassErrorMsg]=useState("");
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit=(e)=>{
        e.preventDefault();
        setLoading(true);
        setResetPassErrorState(false);
        setAlert(false);
        emailResetSenha();
    }
    const emailResetSenha=(e)=>{
        //email reset senha
        const signupData = {
            email:email,
        };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/esqueci-a-senha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(signupData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setLoading(false);
            let camposErro = Object.keys(res.response.data);
            let mensagem = "";
            if(camposErro.includes('email')){
                mensagem = res.response.data.email[0];
            } else{
                mensagem = res.response.data;
            }
            if(res.response.success === true){
                setAlert(true);
                setAlertMsg('Confira seu e-mail para redefinir sua senha.');
            } else{
                setResetPassErrorState(true);
                setResetPassErrorMsg(mensagem);
            }
        }));
    }
    return(
        <>
        <div className="min-h-screen max-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div style={{display:(alertMsg ? "none": "block")}} className="max-w-md w-full space-y-8 m-auto">
                <Header 
                    heading="Esqueci minha senha"
                    paragraph="Por favor digite seu endereço de e-mail abaixo. 
                                Um e-mail com informações de como alterar sua senha será mandado a você."
                    linkName=" "
                    linkUrl=" "
                    customClass="titulo-login text-center"
                    customClassParagraph="text-paragraph text-center"
                />
                
                <form className="" onSubmit={handleSubmit}>
                    <SingleInput label="Digite seu E-mail"
                        className={
                            resetPassErrorState ? "border-l-4 border-red-500" : ""
                        }
                        name="email"
                        type="email"
                        value={email}
                        onChange={(v) => setEmail(v.target.value)} 
                    />
                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(resetPassErrorState && resetPassErrorMsg !== '' ? "block": "none") }}>{resetPassErrorMsg}</span>
                    <FormAction handleSubmit={handleSubmit} text="Enviar" loading={loading} />
                </form>
                <a className="link-voltar w-full flex justify-center py-3 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-gray-400 hover:text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" href="/">
                    <FaArrowLeft className="mx-2 icon-link-voltar mt-1" />  Voltar para página inicial
                </a>
            </div>
            <div style={{display:(alertMsg ? "block": "none")}} className="" role="alert">
                <div className="max-w-md w-full m-auto text-center">
                    <h1 className="mt-6 titulo-login"> E-mail enviado!</h1>
                    <p className="text-paragraph">
                        Enviamos um e-mail com informações <br/> de como alterar sua senha. Confira sua <br /> caixa de entrada e seu spam.
                    </p>
                    <div className="my-5">
                    <a className="link-voltar w-full flex justify-center py-3 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-[#A4A4A4] hover:text-white bg-[#D9D9D9] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" href="/">
                        <FaArrowLeft className="mx-2 icon-link-voltar mt-1" />  Voltar para página inicial
                    </a>
                    </div>
                </div>
            </div>
            <div className="mx-auto sticky py-1 top-[100vh]">
                <img height="40px" width="106px" src="/img/Logo-solucoes-massa.png" alt="banner"/>
            </div>
        </div>
            
        </>
    )
}