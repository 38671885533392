import React from "react";
import {IoCloseOutline} from 'react-icons/io5'

export default function ModalFiltros({ className, closeModal, children }) {
    return (
        <>
            <div className="fixed inset-0 z-10 overflow-y-auto bg-gray-600 bg-opacity-75">
                <div className="flex items-center min-h-screen px-4 py-20 lg:py-8">
                    <div className={`relative flex justify-center w-full p-10 mx-auto bg-white rounded-md shadow-lg ${className}`}>
                        <IoCloseOutline className="absolute top-2 right-2 text-xl cursor-pointer hover:text-blue-900" onClick={closeModal}/>
                        <div className="mt-3 sm:flex w-full">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    