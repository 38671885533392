import React, { useState, useEffect } from "react";
import { FiChevronsLeft, FiChevronsRight  } from 'react-icons/fi'

const Pagination = ({ pageChangeHandler, pageLimitChangeHandler, totalRows, rowsPerPage, page, searchInput = false }) => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  const [pagesArr, setPagesArr] = useState([...new Array(noOfPages)]);
  const [pageNumbers, setPageNumbers] = useState([]);

  const [currentPage, setCurrentPage] = useState(page);
  const [limitPage, setLimitPage] = useState(rowsPerPage);

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);
  const onLimitPageSelect = (limitPage) => setLimitPage(limitPage);

  const [shownLimitPages, setShownLimitPages] = useState(currentPage);

  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    const pageNumbers = [];

    if(currentPage > 3 && currentPage < (noOfPages + 2)){
        for (let i = (currentPage-2); ((i <= (currentPage+2)) && (i <= noOfPages)); i++) {
            if(i === noOfPages){
                break;
            }
            pageNumbers.push(i);
        }
        setPageNumbers(pageNumbers);
    } else{  
        for (let i = 0; ((i <= (currentPage+4)) && (i <= noOfPages)); i++) {
            if(i === noOfPages){
                break;
            }
            pageNumbers.push(i);
        }
        setPageNumbers(pageNumbers);
    }

    pageChangeHandler(currentPage);
  }, [currentPage, noOfPages]);

  useEffect(() => {
    if(searchInput && currentPage !== 1){
      setCurrentPage(1);
    }
  }, [searchInput]);

  useEffect(() => {
    pageLimitChangeHandler(limitPage);
  }, [limitPage]);

  return (
    <>
      {noOfPages > 1 ? (
        <div class="min-w-full my-3">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="flex gap-x-2">
                    <span className="text-base text-gray-700 my-auto"> Página <span className="font-medium">{currentPage}</span> de <span className="font-medium">{noOfPages}</span></span>
                    <select className='bg-transparent'
                        value={rowsPerPage}
                        onChange={e => {
                            setLimitPage(Number(e.target.value))
                        }}
                    >
                        {[5, 10, 20].map(pageOptionSize => (
                            <option key={pageOptionSize} value={pageOptionSize}>
                                Mostrar {pageOptionSize}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button
                          className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 rounded-l-md"
                          // onClick={onPrevPage}
                          onClick={() => onPageSelect(1)}
                          disabled={!canGoBack}
                          title="Primeira"
                        >
                            <span className="sr-only">Primeira</span>
                            <FiChevronsLeft className="h-5 w-5" aria-hidden="true" />
                        </button>
                        {pageNumbers.slice(0,5).map((num, index) => (
                          <button
                            onClick={() => onPageSelect(num + 1)}
                            className={`${"relative inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium hover:bg-gray-50 hover:text-blue-600"}  ${
                              num + 1 === currentPage ? ' bg-blue-500 text-white ' : "bg-white text-gray-500"
                            }`}
                          >
                            {num + 1}
                          </button>
                        ))}
                        <button
                          className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 rounded-r-md"
                          // onClick={onNextPage}
                          onClick={() => onPageSelect(noOfPages)}
                          disabled={!canGoNext}
                          title="Última"
                        >
                            <span className="sr-only">Última</span>
                            <FiChevronsRight className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
      ) : null}
    </>
  );
};

export default Pagination;