import React from "react"
import CardNegocio from "./CardNegocio"
import {InfoProjetos, InfoCalendario, InfoSegmentos, InfoPersonalizados, InfoProjetosDeMercado, InfoSeriesEGuias, InfoFormatos} from "./InfoNegocios"
import GetRssSolucoes from '../RSS/BlogSolucoes'

export default function CardListSolucoesNegocios(){
    return(
        <>
            <div className="p-5">
                <span className="text-2xl font-medium">Projetos</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoProjetos.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Calendário</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoCalendario.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Segmentos</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoSegmentos.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Personalizados</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoPersonalizados.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Projetos De Mercado</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoProjetosDeMercado.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Séries e Guias</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoSeriesEGuias.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className="p-5">
                <span className="text-2xl font-medium">Formatos</span>
                <div className="grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 min-[1366px]:grid-cols-5">
                    {
                        InfoFormatos.map((card) => (
                            <CardNegocio 
                                link={card.link}
                                imagem={card.imagemUrl}
                                titulo={card.titulo}
                            />
                        ))
                    }
                </div>
            </div>
            <div className='p-5'>
                <GetRssSolucoes />
            </div>
        </>
    )
}