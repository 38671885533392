import React, { useState } from "react"
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
import Select from 'react-select'
import { selectEstado } from '../../components/constants/estados'
import { formatarTelefone, formatarCnpj } from "./helper";
import Checkbox from '../Checkbox'
import { ToastContainer, toast } from 'react-toastify';

const selectTipoEmpresa = [
    { value: 'nacional', label: 'Nacional' },
    { value: 'internacional', label: 'Internacional' },
  ]

const selectCliente = [
    { value: 'sim', label: 'Sim, é cliente do Grupo Massa' },
    { value: 'nao', label: 'Não é cliente do Grupo Massa' },
]

const selectAgencia = [
    { value: 'sim', label: 'Sim, é agência' },
    { value: 'nao', label: 'Não é agência' },
]

const selectCidade = [];

export default function FormVincularEmpresaAdmin(){
    const [tipoEmpresa, setTipoEmpresa] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [loading, setLoading] = useState(false);

    const [mensagemErro,setMensagemErroState]=useState({});
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState(false);

    const [cliente, setCliente] = useState("");
    const [agencia, setAgencia] = useState("");

    // Constantes apenas para não clientes
    const [cnpjNovo, setCnpjNovo] = useState("");
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [estadoSelecionado, setEstadoSelecionado] = useState("");
    const [cidadeSelecionada, setCidadeSelecionada] = useState("");
    const [comunicacao_email, setComunicacaoEmail] = useState(true);
    const [comunicacao_whatsapp, setComunicacaoWhatsapp] = useState(true);
    const [comunicacao_sms, setComunicacaoSMS] = useState(true);
    const [companies, setCompanies] = useState({});
    const [inputSearch, setInputSearch] = useState("");
    const [afterSearch, setAfterSearch] = useState(false);

    const [ipUser, setIPUser] = useState(() => {
        setLoading(true);
        fetch('http://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            if(typeof(data.ip) != 'undefined'){
                setIPUser(data.ip);
            } else{
                setIPUser('');
            }
        })
        .catch((err) => {
            setIPUser('');
        })
        .finally(() => {
            setLoading(false);
        })
    });

    function handleSearch(e){
        if (inputSearch !== "") {
            setAlert(false);
            setLoading(true);
            setAfterSearch(false);
            setCompanies({});
            const token = localStorage.getItem("token");
            const postData = {
                id_usuario: window.location.href.split('/')[6],
                search: inputSearch,
            };
            let tipoBusca = ((cliente === 'sim') ? 'client' : 'agency');
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/search-'+tipoBusca, {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(postData)
            }).then(response => 
                response.json().then(response => ({ response })
            )).then(response => {
                if(response.response.data.success){
                    setAfterSearch(true);
                    setCompanies(response.response.data.empresas);
                } else{
                    toast.error('Nenhum cliente foi encontrado.', {autoClose:3000})
                }
                
            })
            .catch((error) => {
                toast.error('Não foi possível realizar essa ação no momento.', {autoClose:3000})
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        vincularEmpresa()
      }

    const handleCheckboxEmail = () => {
        setComunicacaoEmail(!comunicacao_email);
    }

    const handleCheckboxWhatsapp = () => {
        setComunicacaoWhatsapp(!comunicacao_whatsapp);
    }

    const handleCheckboxSMS = () => {
        setComunicacaoSMS(!comunicacao_sms);
    }

    const vincularEmpresa=(e)=>{
        const idUser = window.location.href.split('/')[6];
        setLoading(true);
        setAlert(false);
        const token = localStorage.getItem("token");
        const postData = (cliente === 'sim' || agencia === 'sim') ?
            //Vincular usuário à empresa
            {
                id_usuario: idUser,
                cliente: cliente,
                agencia: agencia,
                cliente_internacional:(tipoEmpresa === 'internacional') ? true : false,
                cnpj:cnpj,
                user_agent: window.navigator.userAgent,
                ip: ipUser,
            }
        :
            // Criar empresa para prospect
            {
                id_usuario: idUser,
                cliente: cliente,
                cnpj: cnpjNovo,
                cliente_internacional:(tipoEmpresa === 'internacional') ? true : false,
                nome_fantasia:nomeFantasia,
                razao_social:razaoSocial,
                estado:estado,
                cidade:cidade,
                telefone:telefone,
                email:email,
                comunicacao_email:comunicacao_email,
                comunicacao_whatsapp:comunicacao_whatsapp,
                comunicacao_sms:comunicacao_sms,
                user_agent: window.navigator.userAgent,
                ip: ipUser,
            };
        

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/vincular-usuario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setAlert(true);
            if(res.response.success === true){
                setAlertStatus(true);
                setAlertMsg(res.response.message);
                setTipoEmpresa("");
                setCnpj("");
                localStorage.setItem("info_vinculo", false);
                window.location.reload();
                setCliente("");
                setAgencia("");
            } else{
                setAlertStatus(false);

                setMensagemErroState(res.response.data);
                setAlertMsg(res.response.message);
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const definirCidades = (estado, buscar = '') => {
        if(estado !== ''){
            setLoading(true);
            setCidade('');
            setCidadeSelecionada('');
            fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                while(selectCidade.length > 0) {
                    selectCidade.pop();
                }
                data.map((items =>
                    selectCidade.push({value:items.nome, label:items.nome})
                ));
                if(buscar !== ''){
                    selectCidade.find(({ value: v }) => v === buscar ); 
                    //setCidadeSelecionada(itemCidade);
                }
            }).catch((err) => {
                setAlert(true);
                setAlertMsg("Error in connection.");
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };
    
    return(
        <>
            <form onSubmit={handleSubmit}>
                <span className="block mb-3">Para realizar o vínculo desse usuário com uma empresa, informe os seguintes dados:</span>
                <Select 
                className={(cliente === 'sim') ? "hidden" : "mb-8"}
                placeholder="É uma agência?"
                isClearable={false} 
                options={selectAgencia}
                onChange={(agencia) => setAgencia(agencia.value)}
                />

                <Select 
                className={(agencia === 'sim') ? "hidden" : "mb-8"}
                placeholder="Já é um cliente do Grupo Massa?"
                isClearable={false} 
                options={selectCliente}
                onChange={(cliente) => setCliente(cliente.value)}
                />
                {(cliente !== "" || agencia !== "") ? 
                    (cliente === 'sim' || agencia === 'sim') ?
                        <>
                            <Select 
                            className="mb-8"
                            placeholder="Selecione o Tipo de Empresa"
                            isClearable={false} 
                            options={selectTipoEmpresa}
                            onChange={(tipoEmpresa) => setTipoEmpresa(tipoEmpresa.value)}
                            required
                            />

                            <div class="relative w-full">
                                <input type="text" id="buscar_cliente" class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-gray-300 border-gray-600" placeholder="Buscar por CNPJ, razão social ou nome fantasia" value={inputSearch} onChange={(v) => setInputSearch(v.target.value)} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                                <button type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={e => {
                                    handleSearch(e);
                                }}>
                                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                    </svg>
                                    <span class="sr-only">Buscar</span>
                                </button>
                            </div>

                            {(companies && Object.keys(companies).length > 0) ? (
                                <Select 
                                    className="mb-8 mt-2"
                                    placeholder={(loading) ? "Carregando opções..." : (companies.length > 0) ? "Selecione" : "Nenhum cliente encontrado"}
                                    isClearable={false} 
                                    options={(companies.length > 0) ? companies.map((item, index) => {
                                        return {
                                          label: item.cnpj + ' - ' +item.nome,
                                          value: item.cnpj,
                                          key: index
                                        };
                                      }) : null}
                                    onChange={(v) => setCnpj(formatarCnpj(v.value))}
                                    disabled={(companies.length > 0) ? "false" : "true"}
                                />
                            ) : 
                            (afterSearch && inputSearch !== "") ? 
                                <p id="helper-text-explanation" class="px-2.5 mt-2 text-sm text-gray-500">Nenhum resultado encontrado para sua pesquisa.</p>
                            : 
                                ''
                            }

                            <SingleInput label="CNPJ"
                            name="cnpj"
                            type="text"
                            value={cnpj}
                            onChange={(v) => setCnpj(formatarCnpj(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.cnpj) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.cnpj) != 'undefined' ? mensagemErro.cnpj : '' }</span>

                            <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                            <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                        </>
                    :
                    <>
                        <Select 
                        className="mb-8"
                        placeholder="Selecione o Tipo de Empresa"
                        isClearable={false} 
                        options={selectTipoEmpresa}
                        onChange={(tipoEmpresa) => setTipoEmpresa(tipoEmpresa.value)}
                        required
                        />
                        <SingleInput label="CNPJ"
                        name="cnpj"
                        type="text"
                        value={cnpjNovo}
                        onChange={(v) => setCnpjNovo(formatarCnpj(v.target.value))} />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro.cnpj && typeof(mensagemErro.cnpj) != 'undefined' ? "block": "none") }}>{ (mensagemErro.cnpj && typeof(mensagemErro.cnpj) != 'undefined') ? mensagemErro.cnpj : '' }</span>
                        <SingleInput label="Razão social"
                        name="razao_social"
                        type="text"
                        value={razaoSocial}
                        onChange={(v) => setRazaoSocial(v.target.value)} />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.razao_social) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.razao_social) != 'undefined' ? mensagemErro.razao_social : '' }</span>
                        <SingleInput label="Nome fantasia"
                        name="nome_fantasia"
                        type="text"
                        value={nomeFantasia}
                        onChange={(v) => setNomeFantasia(v.target.value)} />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.nome_fantasia) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.nome_fantasia) != 'undefined' ? mensagemErro.nome_fantasia : '' }</span>
                        {(tipoEmpresa === 'nacional') ? 
                            <>
                                <Select 
                                    className="mb-8"
                                    placeholder="Selecione um Estado"
                                    options={selectEstado.map((guest, index) => {
                                    return {
                                        label: guest.label,
                                        value: guest.value,
                                        key: index
                                    };
                                    })}
                                    onChange={(item) => {
                                        setEstado(item.value);
                                        setEstadoSelecionado(item);
                                        definirCidades(item.value);
                                    }}
                                    value={estadoSelecionado}
                                />
                                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro['estado'] !== '' ? "block": "none") }}>{mensagemErro['estado'] !== '' ? mensagemErro['estado'] : ''}</span>

                                <Select 
                                    className="mb-8"
                                    placeholder="Selecione uma Cidade"
                                    isClearable={false} 
                                    options={selectCidade}
                                    onChange={(item) => {
                                        setCidade(item.value);
                                        setCidadeSelecionada(item);
                                    }}
                                    value={cidadeSelecionada}
                                />
                                <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro['cidade'] !== '' ? "block": "none") }}>{mensagemErro['cidade'] !== '' ? mensagemErro['cidade'] : ''}</span>
                                </>
                                :
                                <>
                                    <SingleInput label="Estado"
                                    name="estado"
                                    type="text"
                                    customClass={(alert && mensagemErro['estado'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                    value={estado}
                                    onChange={(v) => setEstado(v.target.value)} />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro['estado'] !== '' ? "block": "none") }}>{mensagemErro['estado'] !== '' ? mensagemErro['estado'] : ''}</span>

                                    <SingleInput label="Cidade"
                                    name="cidade"
                                    type="text"
                                    customClass={(alert && mensagemErro['cidade'] !== '' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                    value={cidade}
                                    onChange={(v) => setCidade(v.target.value)} />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro['cidade'] !== '' ? "block": "none") }}>{mensagemErro['cidade'] !== '' ? mensagemErro['cidade'] : ''}</span>
                                </>
                            }
                        <SingleInput label="Telefone"
                        name="telefone"
                        type="text"
                        value={telefone}
                        onChange={(v) => setTelefone(formatarTelefone(v.target.value))} />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.telefone) !== 'undefined' ? "block": "none") }}>{typeof(mensagemErro.telefone) !== 'undefined' ? mensagemErro.telefone : '' }</span>
                        <SingleInput label="E-mail de contato da empresa"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(v) => setEmail(v.target.value)} />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.email) !== 'undefined' ? "block": "none") }}>{typeof(mensagemErro.email) !== 'undefined' ? mensagemErro.email : '' }</span>

                        <div>
                            <Checkbox
                                label={ <div className='contents w-full'>Aceito receber comunicações por e-mail</div> }
                                value={comunicacao_email}
                                onChange={handleCheckboxEmail}/>
                        </div>
                        
                        <div>
                            <Checkbox
                                label={ <div className='contents w-full'>Aceito receber comunicações por WhatsApp</div> }
                                value={comunicacao_whatsapp}
                                onChange={handleCheckboxWhatsapp}/>
                        </div>
                        
                        <div>
                            <Checkbox
                                label={ <div className='contents w-full'>Aceito receber comunicações por SMS</div> }
                                value={comunicacao_sms}
                                onChange={handleCheckboxSMS}/>
                        </div>

                        <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                        <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                    </>
                :
                ""}
            </form>
            <div>
                <ToastContainer />
            </div>
        </>
    )
}