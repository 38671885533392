import React from "react"
import EmpresaDoUsuario from '../EmpresaDoUsuario'
import AttDadosSenha from '../AttDadosSenha'
// import AttPermissao from '../AttPermissao'

export default function InternaGestaoUsuario(){
    return(
        <>
        {/* <div className='
            h-56 
            w-full 
            bg-[url("https://images.unsplash.com/photo-1480497490787-505ec076689f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80")] 
            bg-no-repeat
            bg-cover
            rounded-lg
            '>
        </div> */}
      <div className=' mx-10 rounded-lg p-5'>
        <AttDadosSenha />
        {/* <div className="w-auto">
          <div className='grid grid-cols-3 h-auto gap-6 auto-cols-max divide-x divide-slate-400/25'>
            <div className="grid grid-cols-1">
                <AttPermissao />
            </div>
          </div>
        </div> */}
        <EmpresaDoUsuario showAddCompany={true} idUser={window.location.href.split('/')[6]} showAddCompanyAdmin={((localStorage.getItem('group') === 'admin' && window.location.href.split('/')[3] !== 'meus-dados') ? true : false)}/>
      </div>
    </>
    )
}