import React from 'react'
import Header from '../HeaderForm';
import Cadastro from '../Cadastro'

export default function CadastroPage() {
  return (
    <div className="min-h-full flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
            <Header 
                heading="Criar uma conta"
                paragraph="Já tem uma conta? "
                linkName="Faça login"
                linkUrl="/"
                customClass="titulo-cadastro"
            /> 
            <Cadastro />
        </div>
    </div>
  )
}
