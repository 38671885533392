import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CiCircleCheck } from 'react-icons/ci'
import { RiErrorWarningFill } from 'react-icons/ri'
// import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from 'react-icons/fa'

export default function EmailConfirmado(){
    const [statusDaConfirmacao, setStatusDaConfirmacao] = useState(true);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('Lamentamos, mas não foi possível confirmar o seu email no momento.');

    useEffect(() => {
        setLoading(true);
        const query = new URLSearchParams(location.search);

        fetch(query.get('verify_url'),{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setLoading(false);
            setStatusDaConfirmacao(data.success);
            setMensagemErro(data.message);
        });

    }, []);

    return(
        <>
        <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg w-full space-y-8">
                {(!loading) ?
                (statusDaConfirmacao) ? (
                    <>
                    <div className='shadow-xl shadow-emerald-100 border border-emerald-100 px-6'>
                        <CiCircleCheck className='text-[#4DEE9E] text-7xl flex justify-center mx-auto -mt-8'/>
                        <h1 className='flex justify-center mt-4 text-4xl font-semibold text-[#414141]'>E-mail confirmado!</h1>
                        <span className='text-center text-sm justify-center flex text-[#414141]'>Faça login com os dados preenchidos no</span>
                        <span className='text-center text-sm justify-center flex text-[#414141] mb-3'>cadastro para continuar.</span>
                        <a href="/" className="group relative mx-auto w-3/5 flex justify-center mt-4 mb-8 py-2 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-white bg-[#1346ED] hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                            <FaArrowLeft className="mx-2 icon-link-voltar mt-1" /> Fazer Login
                        </a>
                    </div>
                    </>
                    ) : (
                    <>
                    <div className='shadow-xl shadow-red-100 border border-red-100 px-6'>
                        <RiErrorWarningFill className='text-red-700 text-6xl flex justify-center mx-auto -mt-8'/>
                        <h1 className='flex justify-center mt-4 text-4xl font-semibold text-red-700 mb-3'>Ops! Algo deu errado</h1>
                        <span className='text-center justify-center flex text-slate-600'>{mensagemErro}</span>
                        <span className='text-center justify-center flex text-slate-600 mb-3'>Se o problema persistir, entre em contato para obter assistência.</span>
                        <span className='text-center justify-center flex text-slate-600 mb-8'>Agradecemos a sua compreensão e pedimos desculpas por qualquer inconveniente causado.</span>
                    </div>  
                    </>
                ) : <span className='text-center justify-center flex text-slate-600'>Aguarde...</span>}
            </div>
        </div>
        </>
    )
}