import React, { useState, useRef } from 'react';
import Box from './Box';
import { BsFillChatDotsFill } from 'react-icons/bs'
import { MdOutlineClose } from 'react-icons/md'
import classnames from 'classnames';
import useOnClickOutside from './closeBox';

const selectEmpresas = [];

const BotaoFlutuante = () => {
  const [showBox, setShowBox] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalEmpresas, setTotalEmpresas] = useState(0);
  const ref = useRef();

  const [empresas, setEmpresas] = useState(() => {
      setLoading(true);
      
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/sellers',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          setTotalEmpresas(data.data.total_empresas);
          setEmpresas(data.data.empresas);

          if(data.data.total_empresas > 0){
              while(selectEmpresas.length > 0) {
                selectEmpresas.pop();
              }
              
              data.data.empresas.map((items =>
                  selectEmpresas.push({value:items.cnpj, label:items.nome})
              ));  
          }
      })
      .catch((err) => {
          // Erro
      })
      .finally(() => {
          setLoading(false);
      });
  });

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

    const botaoPrincipal = classnames(
        'text-white font-semibold py-4 px-4 rounded-full shadow transition-all duration-300',
        {
          'bg-red-500 hover:bg-red-700': isDialogOpen,
          'bg-blue-500 hover:bg-blue-700': !isDialogOpen,
        }
    )
    
    useOnClickOutside(ref, () => setIsDialogOpen(false));

  return (
    <>
    {(!loading && totalEmpresas > 0) ? 
    <div className="fixed bottom-4 right-4">
      <button
        className={botaoPrincipal}
        onClick={isDialogOpen ? closeDialog : openDialog}
      >
        {isDialogOpen ? <MdOutlineClose className='text-3xl text-white'/> : <BsFillChatDotsFill className='text-3xl'/>}
      </button>
      {isDialogOpen ? (
        <Box boxRef={ref} onClose={openDialog} selectEmpresas={selectEmpresas} customClass='animate-box'/>
      ) : showBox ? (
        <Box onClose={closeDialog} customClass='animate-box-close'/>
      ) : null}
    </div>
      : ''
    }
    </>
  );
};

export default BotaoFlutuante;