import React, { useState } from 'react';
import Tabela, {StatusTag, formatCnpj} from './Tabela/Tabela'
import InfoTopBorder from './Alert/InfoTopBorder'
import Modal from './Modal'
import FormVincularEmpresa from './Form/FormVincularEmpresa';
import FormVincularEmpresaAdmin from './Form/FormVincularEmpresaAdmin';
import { ToastContainer, toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function EmpresaDoUsuario({showAddCompany, idUser = '', showAddCompanyAdmin = false}){
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState(() => {
      setLoading(true);
      let url = process.env.REACT_APP_API_BACK_URL + '/api/user/companies';
      if(idUser !== ''){
          url = process.env.REACT_APP_API_BACK_URL+'/api/list/user/companies/'+idUser+'/100';
      }
          const token = localStorage.getItem("token");
          fetch(url,{
                  headers : {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': "Bearer " + token
                  }
          })
          .then((response) => {
              return response.json();
          })
          .then((data) => {
              setCompanies(data.data.empresas);
              return data.data.empresas;    
          })
          .catch((err) => {
              // Erro
          })
          .finally(() => {
              setLoading(false);
          });
    });
    
    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

    let iconTrashStyles = { color: "red"};
    const columns = React.useMemo(
        () => [
          {
            Header: "Empresa",
            accessor: "nome",
          },
          {
            Header: "CNPJ",
            accessor: "cnpj",
            Cell: formatCnpj
          },
          {
            Header: "Tipo",
            accessor: "tipo_empresa",
          },
          {
            Header: "Status",
            accessor: "situacao_cliente",
            Cell: StatusTag,
          },
          {
            Header: () => null,
            id: "remover_vinculo",
            isVisible: false,
            Cell: ({ row }) => (
              
              <button onClick={alertRemoverVinculo} className='text-red-700 font-semibold py-1 px-1 rounded text-sm' title="Remover vínculo" data-row={row.id} data-usuario={(window.location.href.split('/')[6]) ? window.location.href.split('/')[6] : 'self'} data-cnpj={row.original.cnpj} data-tipoempresa={row.original.tipo_empresa} >
                  Remover vínculo
              </button>
            )
          }
        ], []);

      const data = React.useMemo(() => companies, []);

      const alertRemoverVinculo = (e) => {
        confirmAlert({
            title: 'Remover vínculo',
            message: 'Essa é uma ação irreversível, tem certeza disso?',
            buttons: [
              {
                label: 'Sim',
                onClick: () => removerVinculo(e)
              },
              {
                label: 'Não',
              }
            ]
        });
      };

      const removerVinculo = (e) => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const cnpj = e.target.dataset.cnpj;
        const id_usuario = e.target.dataset.usuario;
        const tipo_empresa = e.target.dataset.tipoempresa;
        
        const postData = {
            id_usuario: id_usuario,
            cnpj: cnpj,
            tipo_empresa:tipo_empresa,
            pagina:'usuario'
        };
        e.target.textContent = 'Aguarde...';
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/remover-vinculo', {
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        )).then(response => {
            if(response.response.data.success){
                toast.success('Vínculo removido com sucesso.', {autoClose:3000});
                setCompanies(response.response.data.empresas);
            } else{
                toast.error('Não foi possível remover o vínculo.', {autoClose:3000});
            }
        })
        .catch((error) => {
            toast.error('Não foi realizar essa ação no momento.', {autoClose:3000})
        })
        .finally(() => {
            e.target.textContent = 'Remover vínculo';
            setLoading(false);
        });
      };
    
    return(
    <div className="">
        <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="mt-4">

            {(companies && Object.keys(companies).length > 0) ? (
              <>
                  <div id="empresas-usuario">
                      <Tabela columns={columns} data={companies} showAddCompany={showAddCompany} showAddCompanyAdmin={((localStorage.getItem('group') === 'admin' && window.location.href.split('/')[3] !== 'meus-dados') ? true : false)} hiddenColumnsTable={((localStorage.getItem('group') !== 'admin') ? ['remover_vinculo'] : [])} />
                  </div>
              </>
            ): (loading) ? 
                'Carregando...' : (showAddCompany) ?
                      <>
                      <InfoTopBorder 
                      titulo="Seu usuário está sem vínculo com uma Empresa." 
                      texto="Para acessar todos os recursos e funcionalidades disponíveis, é necessário vincular-se, por favor, clique no botão abaixo e preencha as informações necessárias."
                      textoBotao="Vincular Empresa"
                      actionBotao={openModal}
                      />{showModal && <Modal closeModal={closeModal} conteudo={((showAddCompanyAdmin) ? FormVincularEmpresaAdmin : FormVincularEmpresa)}/>}
                      </>
                      : ''
            }
            
          </div>
          <div>
              <ToastContainer />
          </div>
        </main>
    </div>
    )
  }