import React from "react"
import Contador from "./Contador"
import { ConfigBannerContagem } from "./ConfigBannerContagem"

export default function ContagemRegressiva(){
    const corPrincipal = `h-full bg-gradient-to-r from-[${ConfigBannerContagem[0].corPrincipal}]/75 to-[${ConfigBannerContagem[0].corPrincipal}] bg-cover rounded-tl-lg rounded-tr-lg xl:rounded-bl-lg xl:rounded-tr-none`;
    const imagemDeFundo = {
      backgroundImage: `url(${ConfigBannerContagem[0].imagemFundo})`,
    };
    return(
        <>
          <div className="h-[300px] xl:h-[200px] grid grid-cols-1 xl:grid-cols-2">
          <div className="col-span-1 ">
            <div className="h-full bg-cover bg-center bg-no-repeat rounded-tl-lg rounded-tr-lg xl:rounded-bl-lg xl:rounded-tr-none" style={imagemDeFundo}>
                <div className={corPrincipal}>
                <div className="h-full grid grid-cols-1 pt-6 md:grid-cols-2 xl:pt-0">
                    <div className="h-full col-span-1 flex justify-center items-center text-3xl text-white font-bold pb-4 xl:pb-0">Contagem <br />Regressiva</div>
                    <div className="h-full col-span-1 flex justify-center items-center pb-4 xl:pb-0">
                      <img width={150} src={ConfigBannerContagem[0].linkLogo} alt="Logo Evento"/>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="col-span-1 bg-[#FF0066] flex justify-center items-center rounded-br-lg rounded-bl-lg xl:rounded-tr-lg xl:rounded-bl-none py-2 xl:py-none">
            <Contador />
          </div>
        </div>
        </>
    )
}