import React from "react";
import { useAsyncDebounce } from "react-table";

export default function FiltroGlobal({preGlobalFilteredRows, globalFilter, setGlobalFilter}){
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return(
    <span className="mt-2.5">
      <label className='text-[#112587] font-bold text-sm pl-3'>Nº de PI: {' '}</label>
      <input
        className="input-filtro-histcomp block w-full rounded-md appearance-none text-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder='Digite um n° de PI'
      />
    </span>
  )
}