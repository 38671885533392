import React, { useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import Select from 'react-select'
import { FaSearch } from "react-icons/fa";

const selectEmpresas = []

export default function FiltroGlobal({preGlobalFilteredRows, globalFilter, setGlobalFilter, showSelectEmpresas, setSearchQuery}){
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const [loading, setLoading] = React.useState(false)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200);
  
  useEffect(() => {
    if(showSelectEmpresas){
        setLoading(true);
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/select/companies',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            while(selectEmpresas.length > 0) {
              selectEmpresas.pop();
            }
            selectEmpresas.push({value:"", label:"Todas"})
            data.data.companies.map((items =>
                selectEmpresas.push({value:items.codigo_cliente, label:items.nome})
            ));        
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }

  }, []);

  return(
      <div class="flex w-1/3">
        {
        (showSelectEmpresas) ?
            <span className="px-2 w-1/2">
                <label className="pt-2.5">Filtrar por empresa: {' '}</label>
                <Select 
                    className="block px-2.5 w-full text-sm text-gray-900 bg-transparent appearance-none text-gray-600 peer"
                    placeholder={(loading) ? "Carregando opções..." : "Selecionar empresa"}
                    isClearable={false} 
                    options={selectEmpresas}
                    onChange={(item) => {
                        if(item.label === "Todas"){
                            setValue(undefined);
                            onChange(undefined);
                        } else{
                            setValue(item.label);
                            onChange(item.label);
                        }
                        
                    }}
                />
            </span>
        :
            ''
        }
        <span className="w-full">
          <FaSearch className=" absolute mt-3 ml-4 text-[#656565]"/>
          <input
            className="block pr-3 pl-10 pb-[.8rem] pt-[.8rem] w-full text-xs text-[#656565] bg-[#EFEFEF] rounded-full appearance-none focus:border-blue-500 focus:outline-none focus:ring-0 peer"
            value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder="Buscar por N° de PI ou palavra-chave"
          />
        </span>
    </div>
  )
}