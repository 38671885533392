import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function LinkedinCallback() {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [user, setUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        fetch(process.env.REACT_APP_API_BACK_URL + `/api/auth/linkedin/callback${location.search}`,{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setLoading(false);
            setData(data);
        });

    }, []);

    if(loading){
        return <DisplayLoading/>
    } else{
        if(data.success && data.data.name != null){
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('user', data.data.name);
            localStorage.setItem('avatar', data.data.avatar);
            localStorage.setItem('group', data.data.group);
            localStorage.setItem('empresas_vinculadas', data.data.empresas_vinculadas);
            navigate('/dash');
        } else{
            navigate('/', { state: {alert: true, alertMsg: 'Não foi possível se conectar.'}});
        }
    }

}

function DisplayLoading(){
    return <div>Loading...</div>
}

export default LinkedinCallback;