import React, { useState, useRef, useEffect } from "react"
import { InfoLogoEmpresas } from "./DataLogoEmpresas"
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"

export default function CarrosselEmpresas(){
    const maxScrollWidth = useRef(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carousel = useRef(null);


    const movePrev = () => {
        if (currentIndex > 0) {
        setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const moveNext = () => {
        if (
        carousel.current !== null &&
        carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
        ) {
        setCurrentIndex((prevState) => prevState + 1);
        }
    };

    useEffect(() => {
        if (carousel !== null && carousel.current !== null) {
            carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
        }
    }, [currentIndex]);
    
    useEffect(() => {
        maxScrollWidth.current = carousel.current ? carousel.current.scrollWidth - carousel.current.offsetWidth : 0;
    }, []);
    
    
    return(
      <>
        <div className="mt-3 mx-auto w-[300px] sm:w-[490px] md:w-auto">
          <div className="relative overflow-hidden">
            <div className="flex justify-between absolute w-full h-full">
              <button
                onClick={movePrev}
                className="hover:bg-transparent text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
              >
                <MdNavigateBefore className="text-6xl -mr-4"/>
                <span className="sr-only">Anterior</span>
              </button>
              <button
                onClick={moveNext}
                className="hover:bg-transparent text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
              >
                <MdNavigateNext className="text-6xl -ml-5"/>
                <span className="sr-only">Próximo</span>
              </button>
            </div>
            <div ref={carousel} className="carousel-container relative flex gap-4 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0">
              {InfoLogoEmpresas.map((resource, index) => (
                  <div key={index} className="carousel-item text-center relative w-[300px] h-[125px] snap-start">
                    <a className="h-full w-full aspect-square block bg-origin-content bg-center bg-contain bg-no-repeat m-auto z-0 rounded-lg max-w-[150px] max-h-[100px]" style={{ backgroundImage: `url(${resource.imagemUrl || ''})` }} href="https://portal.solucoesmassa.com.br/"> 
                      <img src={resource.imagemUrl || ''} alt={resource.titulo} className=" w-3/4 aspect-square hidden " />
                    </a>
                  </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
}