import React, { useEffect, useState } from "react"

export default function Contador(){

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(`10/26/${year}`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
              dias: <div className="p-2 w-[44px] h-[44px] sm:p-5 sm:w-[88px] sm:h-[88px] flex justify-center border-2 sm:border-4 rounded-full border-white text-white text-lg sm:text-4xl font-semibold">{Math.floor(difference / (1000 * 60 * 60 * 24))}</div>,
              horas: <div className="p-2 w-[44px] h-[44px] sm:p-5 sm:w-[88px] sm:h-[88px] flex justify-center border-2 sm:border-4 rounded-full border-white text-white text-lg sm:text-4xl font-semibold">{Math.floor((difference / (1000 * 60 * 60)) % 24)}</div>,
              minutos: <div className="p-2 w-[44px] h-[44px] sm:p-5 sm:w-[88px] sm:h-[88px] flex justify-center border-2 sm:border-4 rounded-full border-white text-white text-lg sm:text-4xl font-semibold">{Math.floor((difference / 1000 / 60) % 60)}</div>,
              segundos: <div className="p-2 w-[44px] h-[44px] sm:p-5 sm:w-[88px] sm:h-[88px] flex justify-center border-2 sm:border-4 rounded-full border-white text-white text-lg sm:text-4xl font-semibold">{Math.floor((difference / 1000) % 60)}</div>
            };
          }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
      });

      const timerComponents = [];
      Object.keys(timeLeft).forEach((interval) => {
          if (!timeLeft[interval]) {
            return;
          }
          timerComponents.push(
            <span className="mr-2">
              {timeLeft[interval]} <span className="flex justify-center text-white text-xs sm:text-lg">{interval}</span>{" "}
            </span>
          );
        });
    return(
        <>
        <div className="inline-flex">
            {timerComponents.length ? timerComponents : <span>Em andamento</span>}
        </div>
        </>
    )
}