import React, { useState } from "react"
import FormAction from "../FormAction"
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';

export default function FormVincularUsuarioAgencia(){
    const [usuarios,setUsuarios]=useState({});
    const [idUsuario, setIdUsuario] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingInvite, setLoadingInvite] = useState(false);

    // const [errorState, setErrorState]=useState({});
    // const [mensagemErro,setMensagemErroState]=useState({});
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertInvite, setAlertInvite] = useState(false);

    const [inputSearch, setInputSearch] = useState("");
    const [inputConvite, setInputConvite] = useState("");
    const inputAgencia = useState(true);
    const [afterSearch, setAfterSearch] = useState(false);

    const idAgencia = window.location.href.split('/')[6];

    const [ipUser, setIPUser] = useState(() => {
        setLoading(true);
        fetch('http://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            if(typeof(data.ip) != 'undefined'){
                setIPUser(data.ip);
            } else{
                setIPUser('');
            }
        })
        .catch((err) => {
            setIPUser('');
        })
        .finally(() => {
            setLoading(false);
        })
    });

    function handleInvite(e){
        setLoadingInvite(true);
        setAlertInvite(false);
        setAlert(false);
        const token = localStorage.getItem("token");
        const postData = 
            // Convite para usuário
            {
                id: idAgencia,
                email: inputConvite,
                agencia: inputAgencia,
                ip: ipUser,
            };
        
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/send-invite', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setAlertInvite(true);
            if(res.response.success === true){
                setAlertStatus(true);
                setAlertMsg(res.response.message);
                toast.success(res.response.message, {autoClose:3000});
                // window.location.reload();
            } else{
                for (const key in res.response.data) {
                    if (Object.hasOwnProperty.call(res.response.data, key)) {
                        // const element = res.response.data[key];
                        toast.error(res.response.data[key][0], {autoClose:3000});
                        setAlertMsg(res.response.data[key][0]);
                    }
                }
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoadingInvite(false);
        });
    }

    function handleSearch(e){
        if (inputSearch !== "") {
            setAlert(false);
            setLoading(true);
            setAfterSearch(false);
            setUsuarios({});
            const token = localStorage.getItem("token");
            const postData = {
                id: window.location.href.split('/')[6],
                agencia: inputAgencia,
                search: inputSearch,
            };
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/search-user', {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(postData)
            }).then(response => 
                response.json().then(response => ({ response })
            )).then(response => {
                if(response.response.data.success){
                    setAfterSearch(true);
                    setUsuarios(response.response.data.usuarios);
                } else{
                    toast.error('Nenhum usuário foi encontrado.', {autoClose:3000})
                }
                
            })
            .catch((error) => {
                toast.error('Não foi realizar essa ação no momento.', {autoClose:3000})
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        vincularUsuario()
    }

    const vincularUsuario=(e)=>{
        const idAgencia = window.location.href.split('/')[6];
        setLoading(true);
        setAlert(false);
        const token = localStorage.getItem("token");
        const postData = 
            //Vincular usuário à empresa
            {
                id: idAgencia,
                id_usuario: idUsuario,
                ip: ipUser,
                agencia: inputAgencia,
            };
        
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/vincular-usuario-cliente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setAlert(true);
            if(res.response.success === true){
                setAlertStatus(true);
                setAlertMsg(res.response.message);
                window.location.reload();
            } else{
                setAlertStatus(false);
                // errorState['cnpj'] = '';
                
                // setMensagemErroState(errorState);
                // setMensagemErroState(res.response.data);
                setAlertMsg(res.response.message);
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return(
        <>
            <form onSubmit={handleSubmit}>
                <span className="block mb-3">Para realizar o vínculo dessa agência com um usuário, informe os seguintes dados:</span>
                <>
                    <div class="relative w-full">
                        <input type="text" id="buscar_usuario" class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-gray-300 border-gray-600" placeholder="Buscar por nome, e-mail ou CPF" value={inputSearch} onChange={(v) => setInputSearch(v.target.value)} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                        <button type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={e => {
                            handleSearch(e);
                        }} >
                            <svg style={{display:(loading? "block": "none")}} className="animate-spin -ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <svg style={{display:(!loading? "block": "none")}} class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                            <span class="sr-only">Buscar</span>
                        </button>
                    </div>

                    {(usuarios && Object.keys(usuarios).length > 0) ? (
                        <Select 
                            className="mb-8 mt-2"
                            placeholder={(loading) ? "Carregando opções..." : (usuarios.length > 0) ? "Selecione" : "Nenhum usuário encontrado"}
                            isClearable={false} 
                            options={(usuarios.length > 0) ? usuarios.map((item, index) => {
                                return {
                                    label: item.name + ' - ' +item.email,
                                    value: item.id,
                                    key: index
                                };
                                }) : null}
                            onChange={(v) => setIdUsuario(v.value)}
                            disabled={(usuarios.length > 0) ? "false" : "true"}
                        />
                    ) : 
                    (afterSearch && inputSearch !== "") ? 
                        <p id="helper-text-explanation" class="px-2.5 mt-2 mb-4 text-sm text-gray-500">Nenhum resultado encontrado para sua pesquisa.</p>
                    : 
                        ''
                    }

                    <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                    {(idUsuario !== '')
                    ?
                    <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                    : ''
                    }
                    
                    <div class="relative w-full mt-5">
                        <input type="text" id="enviar_convite" class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-gray-300 border-gray-600" placeholder="Enviar convite de cadastro" value={inputConvite} onChange={(v) => setInputConvite(v.target.value)} onKeyDown={(e) => { setAlert(false); e.key === 'Enter' && e.preventDefault() }} />
                        <button type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={e => {
                            handleInvite(e);
                        }}>
                            {loadingInvite ?
                                <>
                                    <svg className="animate-spin -ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </>
                            :
                                'Enviar'
                            }
                        </button>
                    </div>
                    <p id="helper-text-explanation" class="px-2.5 mt-2 text-sm text-gray-500">Digite o e-mail para enviar o convite.</p>
                    <div style={{display:(alertInvite? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                </>
            </form>
            <div>
                <ToastContainer />
            </div>
        </>
    )
}