import React from "react"

export default function Checkbox({ label, value, onChange }){
    return(
        <>
        <label className="ml-2 text-sm font-medium text-gray-600">
            <input className="w-3 h-3 mr-2 text-blue-600 bg-gray-100 border-gray-200 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                   type="checkbox" checked={value} onChange={onChange} />
            {label}
        </label>
        </>
    )
}