import React, { useState, useEffect } from 'react'
import Avatar  from '../Avatar'
import SingleInput from "../SingleInput"
import UsuariosDaEmpresa from '../UsuariosDaEmpresa'
import { formatarTelefone, formatarCnpj } from '../Form/helper'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from '../Modal'
import FormVincularUsuarioAgencia from '../Form/FormVincularUsuarioAgencia'

export default function InternaAgencia() {
  const [showModal, setOpenModal] = useState(false);
  const openModal = () => {
      setOpenModal(true);
  }
  const closeModal = () => {
      setOpenModal(false);
  }
  const [nome, setNome] = useState("");
  const [nomefantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [bairro, setBairro] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");

  const [updateErrorState,setUpdateErrorState]=useState({});
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [usuarios,setUsuarios]=useState({});
  const [ipUser, setIPUser] = useState("");

  const getIpUser = async () => {
    const res = fetch('https://api.ipify.org/?format=json')
                .then(response => response.json())
                .then(data => {
                    setIPUser(data.ip);
                });
  };

  useEffect(() => {
      setLoading(true);
      getIpUser();
      const idAgency = window.location.href.split('/')[6];
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/agency/' + idAgency,{
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': "Bearer " + token
          }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          setNome(data.data.agencia.nome);
          setCnpj(formatarCnpj(data.data.agencia.cnpj));
          setNomeFantasia(data.data.agencia.fantasia);
          setTitulo(data.data.agencia.fantasia);
          setEndereco(data.data.agencia.endereco);
          setCep(data.data.agencia.cep);
          setBairro(data.data.agencia.bairro);
          setCidade(data.data.agencia.cidade);
          setEstado(data.data.agencia.estado);
          setTelefone(formatarTelefone(data.data.agencia.telefone));
          setEmail(data.data.agencia.email);

          setUsuarios(data.data.agencia.vinculos);
      })
      .catch((err) => {
          // Erro
      })
      .finally(() => {
          setLoading(false);
      });

  }, []);

  return (
    <>
      <div className='
      h-56 
      w-full 
      bg-[url("https://images.unsplash.com/photo-1485727373771-86ef43f2cbeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80")] 
      bg-no-repeat
      bg-cover
      rounded-lg
      '>
      </div>
      <div className=' mx-10 bg-white -mt-20 rounded-lg p-5'>
        <div className='grid grid-cols-[100px_minmax(800px,_1fr)_10px]'>
          <Avatar width='20' height='20' size='4' color='white'/>
          <div className='flex items-center'>
            <div>
              <span className='block text-xl text-slate-800 font-semibold'>{titulo}</span>
            </div>
          </div>
          <span></span>
        </div>
        
        <div className="w-auto">
          <div className='grid grid-cols-1 h-auto gap-6 auto-cols-max divide-x divide-slate-400/25'>
            <label className='block text-xl text-slate-800 font-semibold py-2'>Dados Cadastrais</label>
            <div className='grid grid-cols-2'>
              <div className='px-2'>
                <SingleInput 
                  label="Nome"
                  name="nome"
                  type="text"
                  value={nome}
                  disabled 
                />

                <SingleInput 
                  label="Nome Fantasia"
                  name="nomefantasia"
                  type="text"
                  value={nomefantasia}
                  disabled 
                />
                
                <SingleInput 
                  label="CNPJ"
                  name="cnpj"
                  type="text"
                  value={cnpj}
                  onChange={(v) => setCnpj(formatarCnpj(v.target.value))} 
                  disabled
                />
                
                <SingleInput label="E-mail"
                    name="email"
                    type="text"
                    value={email}
                    disabled />

                <SingleInput label="Telefone"
                    name="telefone"
                    type="text"
                    value={telefone}
                    disabled />
            </div>
            <div className=''>
                <SingleInput label="CEP"
                    name="cep"
                    type="text"
                    value={cep}
                    disabled />
                
                <SingleInput label="Endereço"
                    name="endereco"
                    type="text"
                    value={endereco}
                    disabled />
                
                <SingleInput label="Bairro"
                    name="bairro"
                    type="text"
                    value={bairro}
                    disabled />

                <SingleInput label="Estado"
                    name="estado"
                    type="text"
                    value={estado}
                    disabled />
                
                <SingleInput label="Cidade"
                    name="cidade"
                    type="text"
                    value={cidade}
                    disabled />
              </div>
            </div>
            <div>
                <ToastContainer />
            </div>
            {(!loading) 
              ? 
              <button
                  className="my-5 group relative w-2/5 flex justify-center py-2 px-4 border border-blue-700 duration-200 text-sm font-medium rounded-md text-blue-700 bg-transparent hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2"
                  onClick={openModal}
              >
                  Vincular usuário a essa agência
              </button> 
              : 
              ''
            }
            {showModal && <Modal closeModal={closeModal} conteudo={FormVincularUsuarioAgencia} />}
          </div>
        </div>
        {(usuarios && usuarios.length > 0) ? (
            <UsuariosDaEmpresa conteudo={usuarios} hiddenColumnsTable={['status']} cnpj={cnpj} />
        ) : (loading) ? 
            'Carregando...' : 
            'Nenhum dado a apresentar'
        }
      </div>
    </>
    
  )
}