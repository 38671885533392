import React, {useState, useEffect} from "react";
import Header from "../HeaderForm";
import SingleInput from "../SingleInput";
import FormAction from "../FormAction";
import { useNavigate } from "react-router-dom";
import { CiCircleCheck } from 'react-icons/ci'
import { FaArrowLeft } from 'react-icons/fa'
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";

async function novaSenha(credentials) {
    return fetch(process.env.REACT_APP_API_BACK_URL + '/api/redefinir-senha', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => { 
            return data.json()    
        })
        .catch(error => { console.log('err'); console.log(error); })
}

export default function ResetarSenha(){
    const [senha, setSenha] = useState("");
    const [confirmarSenha, setConfirmarSenha] = useState("");
    const [validToken, setValidToken] = useState("");
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [statusDaConfirmacao, setStatusDaConfirmacao] = useState(false);
    const navigate = useNavigate();
    const [senhaTypeNova, setSenhaTypeNova] = useState("password");
    const [senhaTypeConfirmar, setSenhaTypeConfirmar] = useState("password");

    const togglePasswordNova = (e) => {
        e.preventDefault();
        setSenhaTypeNova((prevState) => (prevState === "password" ? "text" : "password"));
      };
      
      const togglePasswordConfirmar = (e) => {
        e.preventDefault();
        setSenhaTypeConfirmar((prevState) => (prevState === "password" ? "text" : "password"));
      };

    useEffect(() => {
        const fullUrl = window.location.pathname;
        const token_url = fullUrl.split("/").pop();
        setToken(token_url);

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/redefinir-senha/' + token_url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setValidToken(data.data.valid);
        });

    });

    const handleSubmit = async e => {
        e.preventDefault();
        
        setLoading(true);

        const response = await novaSenha({
            token:token,
            password:senha,
            password_confirmation:confirmarSenha,
        });

        setLoading(false);

        let camposErro = Object.keys(response.data);
        let mensagem = "";
        if(camposErro.includes('password')){
            mensagem = response.data.password[0];
        } else if(camposErro.includes('password_confirmation')){
            mensagem = response.data.password_confirmation[0];
        } else{
            mensagem = response.data;
        }

        if(response.success === true){
            setAlert(false);
            setStatusDaConfirmacao(true);
        } else{
            setAlert(true);
            setAlertMsg(mensagem);
        }
    }
    return(
        <div className="min-h-screen max-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            {(statusDaConfirmacao) ?
                (<>
                    <div className="max-w-md w-full m-auto text-center">
                        <CiCircleCheck className='text-[#4DEE9E] text-7xl flex justify-center mx-auto -mt-8'/>
                        <h1 className="mt-6 titulo-login"> Tudo certo!</h1>
                        <p className="text-paragraph">
                        Sua senha foi alterada com sucesso!
                        </p>
                        <div className="my-5">
                            <a className="link-voltar rounded inline-flex bg-[#1346ED font-medium text-sm] text-white" href="/">
                                <FaArrowLeft className="mx-2 icon-link-voltar" />  Voltar para página inicial
                            </a>
                        </div>
                        {/* <div className='shadow-xl shadow-emerald-100 border border-emerald-100 px-6'>
                            <BsPatchCheckFill className='text-emerald-600 text-6xl flex justify-center mx-auto -mt-8'/>
                            <h1 className='flex justify-center mt-4 text-4xl font-semibold text-emerald-600 mb-3'>Tudo certo!</h1>
                            <span className='text-center justify-center flex text-slate-600'>Sua senha foi alterada com sucesso!</span>
                            <a href="/" className="group relative mx-auto w-3/5 flex justify-center mt-4 mb-8 py-2 px-4 border border-transparent duration-200 text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                                Ir para Login
                            </a>
                        </div> */}
                    </div>
                    <div className="mx-auto sticky py-1 top-[100vh]">
                        <img height="40px" width="106px" src="/img/Logo-solucoes-massa.png" alt="banner"/>
                    </div>
                </>
            ) : 
            <div style={{display:(!validToken && validToken !== "" ? "block": "none")}} className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">Código de redefinição inválido.</div>
            }
            
            <div className={validToken && !statusDaConfirmacao ? 'max-w-md w-full space-y-8' : 'hidden'}>
                <Header 
                    heading="Definir uma nova senha"
                    paragraph="Defina uma nova senha com 8 caracteres."
                    subParagraph="\nIncluindo letras, números e caractere especial."
                    linkName=" "
                    linkUrl=" "
                    customClass="titulo-login text-center"
                    customClassParagraph="text-paragraph text-center"
                />

                <form className="" onSubmit={handleSubmit}>
                    <div className="flex">
                        <SingleInput label="Nova senha"
                            name="senha"
                            type={senhaTypeNova}
                            value={senha}
                            onChange={(v) => setSenha(v.target.value)} 
                        />
                        <div className="mt-10 -ml-9 z-10">
                            <button className="btn btn-outline-primary" onClick={togglePasswordNova}>
                            { senhaTypeNova === "password" ? <RiEyeCloseLine className='text-lg text-slate-600'/> : <RiEyeLine className='text-lg text-slate-600'/> }
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        <SingleInput label="Repita a nova senha"
                            name="confirmarSenha"
                            type={senhaTypeConfirmar}
                            value={confirmarSenha}
                            onChange={(v) => setConfirmarSenha(v.target.value)} 
                        />
                        <div className="mt-10 -ml-9 z-10">
                            <button className="btn btn-outline-primary" onClick={togglePasswordConfirmar}>
                            { senhaTypeConfirmar ==="password" ? <RiEyeCloseLine className='text-lg text-slate-600'/> : <RiEyeLine className='text-lg text-slate-600'/> }
                            </button>
                        </div>
                    </div>
                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && alertMsg !== '' ? "block": "none") }}>{alertMsg}</span>
                    <FormAction handleSubmit={handleSubmit} text="Salvar" loading={loading} />
                </form>
            </div>
        </div>
    )
}