import React, { useState, useEffect } from 'react';
import Tabela, { formatCnpj, formatarDataPtBr, linkVisualizarCliente} from './Tabela/Tabela'
import FilterData from './Tabela/FilterData'
import Pagination from './Tabela/Pagination'
import { selectClientsOrderBy } from './constants/filtro'

  export default function EmpresasCadastradas(){
      const [currentPage, setCurrentPage] = useState(1);
      const [limitPage, setLimitPage] = useState(10);
      const [totalItens, setTotalItens] = useState('');
      const [search, setSearch] = useState('');
      const [loading, setLoading] = useState(false);
      const [beginSearch, setBeginSearch] = useState(false);
      const [orderBy, setOrderBy] = useState('');
      const [sortType, setSortType] = useState('');
      const [clients, setClientes] = useState(() => {
          setLoading(true);
          const token = localStorage.getItem("token");
          fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/clients/'+limitPage+'?page=1',{
                  headers : {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': "Bearer " + token
                  }
          })
          .then((response) => {
              return response.json();
          })
          .then((result) => {
              setClientes(result.data.clients.data);
              setTotalItens(result.data.clients.total);
              return result.data;
          })
          .catch((err) => {
              // Erro
          })
          .finally(() => {
              setLoading(false);
          });
    });

    useEffect(() => {
        setLoading(true);
        setBeginSearch(false);
        setClientes({});
        const token = localStorage.getItem("token");
        
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/clients/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType, {
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setClientes(result.data.clients.data);
            setTotalItens(result.data.clients.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [currentPage]);

    useEffect(() => {
        setLoading(true);
        setBeginSearch(true);
        setClientes({});
        
        const token = localStorage.getItem("token");
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/clients/'+limitPage+'?page='+currentPage+'&search='+search+'&order_by='+orderBy+'&sort_type='+sortType,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            setClientes(result.data.clients.data);
            setTotalItens(result.data.clients.total);
            return result.data;
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        });
    }, [search, orderBy, sortType, limitPage]);

    const columns = React.useMemo(
        () => [
          {
            Header: "Nome da Empresa",
            accessor: "nome",
            maxWidth: 40,
            minWidth: 14,
            width: 20,
          },
          {
            Header: "Fantasia",
            accessor: "fantasia",
          },
          {
            Header: "CNPJ",
            accessor: "cnpj",
            className: "whitespace-nowrap",
            Cell: formatCnpj,
          },
          {
            Header: "Cadastrado em",
            accessor: "created_at",
            Cell: formatarDataPtBr,
          },
          // {
          //   Header: "Status",
          //   Cell: "Cliente"
          // },
          // {
          //   Header: "Tipo",
          //   accessor: "tipo",
          //   Filter: Filtro,
          //   filter: 'includes',
          // },
          {
            Header: " ",
            accessor: "id",
            Cell: linkVisualizarCliente
          },
        ], []);
      // const data = React.useMemo(() => getData(), []);
    return(
    <div className="">
        <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
          <div className="mt-4">
            <div id="lista-clientes">
                <FilterData 
                    totalItens={totalItens} 
                    searchChangeHandler={setSearch}
                    orderByChangeHandler={setOrderBy}
                    sortTypeChangeHandler={setSortType}
                    selectOrderBy={selectClientsOrderBy}
                />
                {((clients && Object.keys(clients).length > 0)) ? (
                  <>
                    <Tabela columns={columns} 
                            data={clients} 
                            isLoading={loading}
                            limitSize={limitPage}
                    />
                    <Pagination
                        totalRows={totalItens}
                        pageChangeHandler={setCurrentPage}
                        pageLimitChangeHandler={setLimitPage}
                        rowsPerPage={limitPage}
                        page={currentPage}
                        searchInput={beginSearch}
                    />
                  </>
              ): (loading) ? 
                  'Carregando...' 
                  : 
                  (clients && Object.keys(clients).length === 0)
                  ?
                    <span>
                        Nenhuma informação foi encontrada.
                    </span>
                  :
                  'Nenhum cliente cadastrado ainda.'
              }
            </div>
          </div>
        </main>
    </div>
    )
  }