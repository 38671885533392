import React, { useState } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination, defaultColumn } from 'react-table'
import { FiChevronsLeft, FiChevronLeft, FiChevronRight, FiChevronsRight } from 'react-icons/fi'
import { classNames } from "./Utils";
import Botao from './Botao'
import PageBotao from './PageBotao'
import ModalFiltros from './ModalFiltros'
import { IoFilterSharp, IoArrowDownSharp } from 'react-icons/io5';
import { FiltroSlider } from './FiltroSlider';
import FiltroModal from './FiltroModal';

//Filtro de Data
export function dateBetweenFilterFn(rows, id, filterValues) {
  let [start, end] = filterValues;

  if (!start && !end) {
    return rows;
  }

  start = start ? new Date(start) : undefined;
  end = end ? new Date(end) : undefined;

  return rows.filter(row => {
    const rowDate = new Date(row.values[id]);

    if (start && end) {
      return rowDate >= start && rowDate <= end;
    } else if (start) {
      return rowDate >= start;
    } else if (end) {
      return rowDate <= end;
    }

    return true;
  });
}
dateBetweenFilterFn.autoRemove = val => !val;


//Filtro de Range de Valor
export function rangeFilterFn(rows, id, filterValues) {
  const [min, max] = filterValues;

  if (!min && !max) {
    return rows;
  }

  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= min && rowValue <= max;
  });
}
rangeFilterFn.autoRemove = val => !val || !val.length;


export function StatusTag({ value }) {
  // console.log({'value':value});
  const status = value ? value.toLowerCase() : "desconhecido";
  const arrs = {
    p:"Pendente",
    f:"Finalizado"
  }
  return (
    <span className={classNames("px-4 py-2 leading-wide font-normal capitalize text-xs rounded-md shadow-sm",
      status.startsWith("f") ? "bg-[#C8F2E7] text-[#008E6B]" : null,
      // status.startsWith("cancelado") ? "bg-[#FDD4D4] text-[#EE373B]" : null,
      status.startsWith("p") ? "bg-[#FFE2CC] text-[#FF6E01]" : null
    )}>
      {typeof arrs[status] == 'undefined' ? status : arrs[status]}
    </span>
  )
}


//Filtro de Cliente
export function FiltroCliente({ column: { filterValue, setFilter, preFilteredRows, opts, id } }) {
  // console.log({
  //   'SELECT': 'FiltroCliente',
  //   'preFilteredRows': preFilteredRows[0].values.cliente,
  //   'filterValue': filterValue
  // });

  const options = React.useMemo(() => {
    const options = new Set();
    opts.forEach((row) => {
      // console.log(['ROW', row.cliente_fantasia, row]);
      options.add(row.cliente_fantasia);
    });
    return [...options.values()];
  }, [opts]);

  const optval = filterValue === undefined ? (typeof preFilteredRows[0] == 'undefined' ? '' : preFilteredRows[0].values.cliente) : filterValue;
  // console.log({ 'optval': optval });
  return (
    <select
      className="select-filtro-histcomp"
      name={id}
      id={id}
      value={optval}
      onChange={(e) => {
        setFilter(e.target.value || '');
      }}
    >

      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

//Filtro de CNPJ
export function FiltroCnpj({ column: { filterValue, setFilter, preFilteredRows, opts, id } }) {
  // console.log({
  //   'SELECT': 'FiltroCnpj',
  //   'preFilteredRows': preFilteredRows[0].values,
  //   'filterValue': filterValue,
  //   'opts': opts
  // });
  const options = React.useMemo(() => {
    const options = new Set();
    opts.forEach((row) => {
      options.add(row.cliente_cnpj);
    });
    return [...options.values()];
  }, [opts]);

  const optval = filterValue === undefined ? (typeof preFilteredRows[0] == 'undefined' ? '' : preFilteredRows[0].values.cnpj) : filterValue;

  return (
    <select
      className="select-filtro-histcomp"
      name={id}
      id={id}
      value={optval}
      onChange={(e) => {
        setFilter(e.target.value || '');
      }}

    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

//Filtro de Status
export function FiltroStatus({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // const options = React.useMemo(() => {
  //   const options = new Set();
  //   preFilteredRows.forEach((row) => {
  //     options.add(row.values[id]);
  //   });
  //   return [...options.values()];
  // }, [id, preFilteredRows]);
  return (
    <select
      className="select-filtro-histcomp"
      name={id}
      id={id}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}

    >
        <option value="">Todos</option>
        <option key="P" value="P">Pendente</option>
        <option key="F" value="F">Finalizado</option>
    </select>
  )
}

export default function Tabela({ columns, data, setFiltros, filtroEmpresas }) {
  // console.log('*** TABELA ***');
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } =
    useTable({
      columns,
      data,
      defaultColumn
    },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );

  const [showModalFiltros, setOpenModalFiltros] = useState(false);
  const [savedFilters, setSavedFilters] = useState({
    globalFilter: state.globalFilter,
    filters: headerGroups.reduce((acc, headerGroup) => {
      headerGroup.headers.forEach((column) => {
        if (column.canFilter) {
          acc[column.id] = column.filterValue;
        }
      });
      return acc;
    }, {}),
  });

  const openModalFiltros = () => {
    setOpenModalFiltros(true);
  }
  const closeModalFiltros = () => {
    setOpenModalFiltros(false);
  }

  const applyFilters = (globalFilter, filters) => {
    // console.log([
    //   'chamando applyFilters da Tabela.jsx',
    //   globalFilter,
    //   filters
    // ]);
    setGlobalFilter(globalFilter);
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach(column => {
        if (column.canFilter) {
          column.setFilter(filters[column.id]);
        }
      });
    });
    // console.log(['filters', filters, globalFilter])
    filters['nPI'] = globalFilter;
    setFiltros(filters);
    // console.log(['applyFilters', globalFilter, filters])
    setSavedFilters({ globalFilter, filters });
  };

  const resetFilters = () => {
    setGlobalFilter('');
    headerGroups.forEach(headerGroup => {
      headerGroup.headers.forEach(column => {
        if (column.canFilter) {
          column.setFilter(undefined);
        }
      });
    });
    setSavedFilters({
      globalFilter: '',
      filters: headerGroups.reduce((acc, headerGroup) => {
        headerGroup.headers.forEach((column) => {
          if (column.canFilter) {
            acc[column.id] = undefined;
          }
        });
        return acc;
      }, {}),
    });
  };

  return (
    <>
      <div className='flex gap-3 justify-end'>
        <button className='flex border-solid border-2 border-gray-400 bg-slate-100 px-4 py-2 rounded-md' onClick={openModalFiltros}><IoFilterSharp className='mt-0.5 mr-2' /> Filtrar</button>
        {showModalFiltros && (
          <ModalFiltros className='lg:max-w-screen-md' closeModal={closeModalFiltros}>
            <FiltroModal
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              headerGroups={headerGroups}
              savedFilters={savedFilters.filters}
              applyFilters={applyFilters}
              resetFilters={resetFilters}
              closeModal={closeModalFiltros}
              filtroEmpresas={filtroEmpresas}
            />
          </ModalFiltros>
        )}

        {/* Botão de Exportar dados */}
        {/* <button className='flex border-solid border-2 border-gray-400 bg-slate-100 px-4 py-2 rounded-md'><IoArrowDownSharp className='mt-0.5 mr-2' /> Exportar</button> */}

      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="table-config py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden sm:rounded-lg">
              <table {...getTableProps()} border="2" className="min-w-full divide-y-4 divide-white">
                <thead className="bg-[#EFEFEF]">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className="px-6 py-2 text-left text-sm font-normal text-[#656565] tracking-wider"
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-solid divide-y-4 divide-white">
                  {page.length === 0 ? (
                    <tr>
                      <td colSpan={headerGroups[0].headers.length} className="text-center py-4 text-sm text-[#656565]">
                        <div className='flex justify-center align-middle mt-3'>
                          <div className='grid text-center'>
                            <div className='flex justify-center mb-3'>
                              <img src='./img/icone-n-encontrado-portal-solucoes.png' width='113' height='97' />
                            </div>
                            <span className='text-[#112587] font-bold text-xl'>Nenhum resultado encontrado.</span>
                            <span className='text-[#656565] font-normal text-sm'>Utilize os filtros para fazer uma nova busca.</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr className="bg-[#EFEFEF] text-[#1E1E1E] min-h-[80px]" {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm">
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>

              <div className="py-3 flex items-center justify-between">
                <div className="flex-1 flex justify-between sm:hidden">
                  <Botao onClick={() => previousPage()} disabled={!canPreviousPage}>Anterior</Botao>
                  <Botao onClick={() => nextPage()} disabled={!canNextPage}>Próximo</Botao>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="flex gap-x-2">
                    <span className="text-sm text-gray-700">Página <span className="font-medium">{state.pageIndex + 1}</span> de <span className="font-medium">{pageOptions.length}</span></span>
                    <select
                      value={state.pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                      }}
                    >
                      {[5, 10, 20].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Mostrar {pageSize}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <PageBotao
                        className="rounded-l-md"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        <span className="sr-only">Primeira</span>
                        <FiChevronsLeft className="h-5 w-5" aria-hidden="true" />
                      </PageBotao>
                      <PageBotao
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <span className="sr-only">Anterior</span>
                        <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
                      </PageBotao>
                      <PageBotao
                        onClick={() => nextPage()}
                        disabled={!canNextPage
                        }>
                        <span className="sr-only">Próxima</span>
                        <FiChevronRight className="h-5 w-5" aria-hidden="true" />
                      </PageBotao>
                      <PageBotao
                        className="rounded-r-md"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        <span className="sr-only">Última</span>
                        <FiChevronsRight className="h-5 w-5" aria-hidden="true" />
                      </PageBotao>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}