import React, { useEffect, useState } from 'react'

export default function Avatar({abrir = false}) {

    const [avatar, setAvatar] = useState((localStorage.getItem('avatar') !== null && localStorage.getItem('avatar') !== 'null') ? localStorage.getItem('avatar') : '/img/profile.png');

    useEffect(() => {
      window.addEventListener('update_avatar', () => {
          setAvatar(localStorage.getItem('avatar'));
      });
    }, []);
    
    return (
      <>
          <img className={(!abrir) ? "rounded-full bg-white mx-auto w-[40px] h-[40px]" : "rounded-full bg-white mx-auto w-[70px] h-[70px]"} src={avatar} alt="avatar" />
      </>
    )
}
