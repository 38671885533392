import React, {useEffect, useState} from "react"
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
// import { useNavigate } from "react-router-dom";
import Select from 'react-select'
import { selectAcoesLogs } from '../../components/constants/acoesLogs'
import { ToastContainer, toast } from 'react-toastify';

const selectOrdenacao = [
    { value: 'asc', label: 'Mais antigos' },
    { value: 'desc', label: 'Mais recentes' },
];

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export default function FormBaixarLogs(){
    let dateObj = new Date();
    let today = new Date();
    let monthsAgo = dateObj.setMonth(dateObj.getMonth() - 3);

    const [dataInicio, setDataInicio] = useState(formatDate(monthsAgo));
    const [dataFim, setDataFim] = useState(formatDate(today));
    const [acaoLog, setAcaoLog] = useState('');
    const [acaoLogSelecionada, setAcaoLogSelecionada] = useState({ value: '0', label: 'Todos' });
    const [ordenacao, setOrdenacao] = useState('');
    const [ordenacaoSelecionada, setOrdenacaoSelecionada] = useState({ value: 'desc', label: 'Mais antigos' });
    const [loading, setLoading] = useState(false);

    // const [mensagemErro,setMensagemErroState]=useState({});
    // const [alertStatus, setAlertStatus] = useState(false);
    // const [alert, setAlert] = useState(false);
    // const [alertMsg, setAlertMsg] = useState('');
    const [ipUser, setIPUser] = useState("");

    // const navigate = useNavigate();
    // const reload=()=>window.location.reload();

    const getIpUser = async () => {
        setLoading(true);
        
        fetch('http://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {setIPUser(data.ip);});
        
        setLoading(false);
    };

    useEffect(() => {
        getIpUser();
    }, []);

    const handleSubmit=(e)=>{
        e.preventDefault();
        baixarLogs()
    }

    
    const baixarLogs=(e)=>{
        setLoading(true);
        // setAlert(false);
        const token = localStorage.getItem("token");
        const timestamp = Date.now();
        const nomeArquivo = 'logs_portal_solucoes_'+timestamp+'.csv'
        const postData = {
                            data_inicio: dataInicio,
                            data_fim:dataFim,
                            ip: ipUser,
                            id_acao: acaoLog,
                            ordenacao: ordenacao
                        };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/export-logs',{
            method: 'POST',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify(postData)
        })
        .then((response) => {
            if(response.status === '404'){
                toast.error('Arquivo indisponível no momento.', {autoClose:3000});
            } else{
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = nomeArquivo;
                    alink.click();
                    toast.success('Download realizado com sucesso!', {autoClose:3000})
                    this.props.setOpenModal(false);
                })
            }
        })
        .catch((err) => {
            toast.error('Não foi possível realizar o seu download no momento.', {autoClose:3000})
        })
        .finally(() => {
            setLoading(false);
        });
    }
    
    return(
        <>
            <form id="baixar-logs" onSubmit={handleSubmit}>
                <span className="block mb-3">Escolha o período que deseja visualizar os logs:</span>

                <label className="text-sm">
                    Tipo de log
                </label>
                <Select 
                    className="mt-1"
                    placeholder="Selecione um tipo"
                    isClearable={false} 
                    options={selectAcoesLogs}
                    onChange={(item) => {
                        setAcaoLog(item.value);
                        setAcaoLogSelecionada(item);
                    }}
                    value={acaoLogSelecionada}
                />
                
                <SingleInput label="Data início"
                name="data_inicio"
                type="date"
                value={dataInicio}
                onChange={(v) => setDataInicio(v.target.value)} />
                {/* <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert ? "block": "none") }}>{mensagemErro.data_inicio}</span> */}
                
                <SingleInput label="Data fim"
                name="data_fim"
                type="date"
                value={dataFim}
                onChange={(v) => setDataFim(v.target.value)} />
                {/* <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert ? "block": "none") }}>{mensagemErro.data_inicio}</span> */}

                <label className="text-sm">
                    Ordenar por
                </label>
                <Select 
                    className="mt-1"
                    placeholder="Selecione"
                    isClearable={false} 
                    options={selectOrdenacao}
                    onChange={(item) => {
                        setOrdenacao(item.value);
                        setOrdenacaoSelecionada(item);
                    }}
                    value={ordenacaoSelecionada}
                />
                    
                {/* <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'mt-3 bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div> */}
                
                <FormAction handleSubmit={handleSubmit} text="Baixar" loading={loading} />
            </form>
            <div>
                <ToastContainer />
            </div>
        </>
    )
}