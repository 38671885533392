import React, { useState, useEffect } from 'react';
 
export function FiltroDeData({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);
    let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);
 
    preFilteredRows.forEach(row => {
      const rowDate = new Date(row.values[id]);
      if (rowDate < min) {
        min = rowDate;
      }
      if (rowDate > max) {
        max = rowDate;
      }
    });
 
    return [min, max];
  }, [id, preFilteredRows]);
 
  const [startDate, setStartDate] = useState(filterValue[0] || '');
  const [endDate, setEndDate] = useState(filterValue[1] || '');
 
  useEffect(() => {
    setFilter([startDate || undefined, endDate || undefined]);
  }, [startDate, endDate]);
 
  return (
    <div className='flex align-middle col-span-2 gap-3'>
      <input
      className='input-filtro-histcomp'
        type="date"
        value={startDate}
        onChange={e => setStartDate(e.target.value)}
        placeholder='Selecione uma data de início'
      />
      <input
        className='input-filtro-histcomp'
        type="date"
        value={endDate}
        onChange={e => setEndDate(e.target.value)}
        placeholder='Selecione uma data de fim'
      />
    </div>
  );
}