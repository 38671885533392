import React, { useState, useEffect } from 'react';
import FiltroGlobal from './FiltroGlobal';
import { IoClose } from "react-icons/io5";

function FiltroModal({ preGlobalFilteredRows, globalFilter, setGlobalFilter, headerGroups, savedFilters, applyFilters, resetFilters, closeModal,filtroEmpresas }) {
  // console.log('*** FiltroModal ***');
  const [localGlobalFilter, setLocalGlobalFilter] = useState(globalFilter);
  const [localFilters, setLocalFilters] = useState(savedFilters);

  useEffect(() => {
    setLocalGlobalFilter(globalFilter);
    setLocalFilters(savedFilters);
  }, [globalFilter, savedFilters]);

  const handleApplyFilters = () => {
    // console.log(['handleApplyFilters',localGlobalFilter,localFilters,]);
    // console.log('Chamando applyFilters');
    applyFilters(localGlobalFilter, localFilters);
    closeModal();
  };

  const handleResetFilters = () => {
    setLocalGlobalFilter('');
    setLocalFilters(headerGroups.reduce((acc, headerGroup) => {
      headerGroup.headers.forEach((column) => {
        if (column.canFilter) {
          acc[column.id] = undefined;
        }
      });
      return acc;
    }, {}));
    resetFilters();
    closeModal();
  };

  return (
    <div className=' w-full'>
        <h2 className=' text-2xl text-[#112587] font-bold ml-1 mb-4'>Filtros</h2>
      <div className='grid grid-cols-2 gap-2 mb-2'>
        <FiltroGlobal preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={localGlobalFilter} setGlobalFilter={setLocalGlobalFilter} />
        {headerGroups.map(headerGroup => {
          const otherFilters = [];
          let dateFilter = null;
          let sliderFilter = null;

          headerGroup.headers.forEach(column => {
            const isDateFilter = column.id === 'data';
            const isSliderFilter = column.id === 'valor';

            if (column.Filter) {
              const filterElement = (
                <div className={`mt-2.5 ${isDateFilter || isSliderFilter ? 'col-span-2' : ''}`} key={column.id}>
                  <label className='text-[#112587] font-bold text-sm pl-3' htmlFor={column.id}>
                    {column.render("Header")}:
                  </label>
                  {React.cloneElement(column.render("Filter"), {
                    column: {
                      ...column,
                      opts: filtroEmpresas,
                      filterValue: localFilters[column.id],
                      setFilter: (value) => setLocalFilters(prev => ({ ...prev, [column.id]: value })),
                    }
                  })}
                </div>
              );

              if (isDateFilter) {
                dateFilter = filterElement;
              } else if (isSliderFilter) {
                sliderFilter = filterElement;
              } else {
                otherFilters.push(filterElement);
              }
            }
          });

          return (
            <React.Fragment key={headerGroup.id}>
              {otherFilters}
              {dateFilter}
              {sliderFilter}
            </React.Fragment>
          );
        })}
      </div>
      <div className="flex align-middle justify-between mt-4 gap-2">
        <button className="flex btn btn-secondary text-[#B00000] py-3 px-7" onClick={handleResetFilters}><IoClose className='mr-1 text-xl mt-[1px]' />Limpar filtros</button>
        <button className="btn btn-primary text-white bg-[#112587] rounded-md py-3 px-7" onClick={handleApplyFilters}>Aplicar Filtros</button>
      </div>
    </div>
  );
}

export default FiltroModal;
