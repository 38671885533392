import React, { useState, useEffect } from 'react'
import Avatar  from '../Avatar'
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
import EmpresaDoUsuario from '../EmpresaDoUsuario'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatarTelefone } from "../Form/helper";
import { selectEstado } from '../constants/estados'
import Checkbox from '../Checkbox'

import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";


const selectCidade = []

export default function MeusDados() {
  const [loading, setLoading] = useState(true);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cargo, setCargo] = useState("");

  const [cidade, setCidade] = useState("");
  const [cidadeSelecionada, setCidadeSelecionada] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoSelecionado, setEstadoSelecionado] = useState("");
  // const [tipoUsuario, setTipoUsuario] = useState("");
  const [comunicacao_email, setComunicacaoEmail] = useState(false);
  const [comunicacao_whatsapp, setComunicacaoWhatsapp] = useState(false);
  const [comunicacao_sms, setComunicacaoSMS] = useState(false);
  
  const [senhaTypeAtual, setSenhaTypeAtual] = useState("password");
  const [senhaTypeNova, setSenhaTypeNova] = useState("password");
  const [senhaTypeConfirmar, setSenhaTypeConfirmar] = useState("password");
  const [senhaatual, setSenhaAtual] = useState("");
  const [novasenha, setNovaSenha] = useState("");
  const [confirmarsenha, setConfirmarSenha] = useState("");
  // const handlePasswordChange =(evnt)=>{
  //   setPasswordInput(evnt.target.value);
  // }
  const togglePasswordAtual = (e) => {
    e.preventDefault();
    setSenhaTypeAtual((prevState) => (prevState === "password" ? "text" : "password"));
  };
  
  const togglePasswordNova = (e) => {
    e.preventDefault();
    setSenhaTypeNova((prevState) => (prevState === "password" ? "text" : "password"));
  };
  
  const togglePasswordConfirmar = (e) => {
    e.preventDefault();
    setSenhaTypeConfirmar((prevState) => (prevState === "password" ? "text" : "password"));
  };
  
  const [updateErrorState,setUpdateErrorState]=useState({});
  const [updateDataErrorState,setUpdateDataErrorState]=useState({});
  const [mensagemErro,setMensagemErroState]=useState({});
  const [alert, setAlert] = useState(false);

  const [showUpdatePass, setShowUpdatePass] = useState(true);

  const [ipUser, setIPUser] = useState("");

  const [image, setImage] = useState();
  const [fileAvatar, setFileAvatar] = useState();

  const handleSubmit=(e)=>{
    e.preventDefault();
    updateUsuario()
  }
  const handleCheckboxEmail = () => {
    setComunicacaoEmail(!comunicacao_email);
  }

  const handleCheckboxWhatsapp = () => {
      setComunicacaoWhatsapp(!comunicacao_whatsapp);
  }

  const handleCheckboxSMS = () => {
      setComunicacaoSMS(!comunicacao_sms);
  }

  const getIpUser = async () => {
    const res = fetch('https://api.ipify.org/?format=json')
                .then(response => response.json())
                .then(data => {
                    setIPUser(data.ip);
                });
  };

  const updateUsuario=(e)=>{
    setLoading(true);
    const token = localStorage.getItem("token");
    //Atualizar dados do usuário
    const updateData = {
      name:nome,
      email:email,
      cidade:cidade,
      estado:estado,
      telefone:telefone,
      cargo:cargo,
      ip: ipUser,
      user_agent: window.navigator.userAgent,
      comunicacao_email:comunicacao_email,
      comunicacao_whatsapp:comunicacao_whatsapp,
      comunicacao_sms:comunicacao_sms,
    };

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(updateData)
    }).then(response => 
        response.json().then(response => ({ response })
    ).then(res => {
        setLoading(false);
        setAlert(true);
        if(res.response.success === true){
            toast.success(res.response.message, {timer: 3000, theme: "colored"})
            setAlert(false);
        } else{
            setUpdateDataErrorState(res.response.data);
            console.log('DATA: '+res.response.data.name);
            toast.error(res.response.message, {timer: 3000, theme: "colored"})
        }
    }));
  }

  const handlePassSubmit=(e)=>{
    e.preventDefault();
    updateSenha()
  }
  const updateSenha=(e)=>{
    //Atualizar senha do usuário
    setLoading(true);
    const token = localStorage.getItem("token");
    const updatePass = {
      senha_atual:senhaatual,
      nova_senha:novasenha,
      nova_senha_confirmation:confirmarsenha,
      ip: ipUser,
      user_agent: window.navigator.userAgent,
    };

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/update-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(updatePass)
    }).then(response => 
        response.json().then(response => ({ response })
    ).then(res => {
        setLoading(false);
        setAlert(true);
        if(res.response.success === true){
            toast.success(res.response.message, {timer: 3000, theme: "colored"})
            setAlert(false);
        } else{
            updateErrorState['senha_atual'] = '';
            updateErrorState['nova_senha'] = '';
            updateErrorState['nova_senha_confirmation'] = '';
            
            setMensagemErroState(updateErrorState);
            setMensagemErroState(res.response.data);
            toast.error(res.response.message, {timer: 3000, theme: "colored"})
        }
    }));
  }

  const definirCidades = (estado, buscar = '') => {
    setLoading(true);
    setCidadeSelecionada('');
    let response = fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          while(selectCidade.length > 0) {
              selectCidade.pop();
          }
          data.map((items =>
              selectCidade.push({value:items.nome, label:items.nome})
          ));
          if(buscar !== ''){
              const itemCidade = selectCidade.find(({ value: v }) => v === buscar ); 
              setCidadeSelecionada(itemCidade);
          }
          setLoading(false);
      });
  };

  function uploadImage(e) {
    console.log(e.target.files);
    setFileAvatar(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  }

  function handleImageSubmit(e){
    e.preventDefault();
    updateImage();
  }

  const updateImage=(e)=>{
    //Atualizar imagem de perfil do usuário
    setLoading(true);
    const token = localStorage.getItem("token");
    // const updateAvatar = {
    //   avatar:fileAvatar,
    //   ip: ipUser,
    //   user_agent: window.navigator.userAgent,
    // };
    const fileInput = document.querySelector('#foto_perfil');

    var formData = new FormData();
    formData.append('avatar', fileInput.files[0]);

    fetch(process.env.REACT_APP_API_BACK_URL + '/api/user/update-avatar', {
        method: 'POST',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        },
        body: formData
    }).then(response => 
        response.json().then(response => ({ response })
    ).then(res => {
        setLoading(false);
        setAlert(true);
        if(res.response.success === true){
            localStorage.setItem('avatar', res.response.data.url_avatar);
            toast.success(res.response.message, {timer: 3000, theme: "colored"})
            setAlert(false);
            window.dispatchEvent(new Event('update_avatar'))
        } else{
            toast.error(res.response.message, {timer: 3000, theme: "colored"})
        }
    })
    .catch((error) => {
      setLoading(false);
      toast.error('Não foi possível atualizar sua foto no momento, tente novamente mais tarde.', {timer: 3000, theme: "colored"});
    })
    );
  }

  useEffect(() => {
      setLoading(true);
      getIpUser();
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/user',{
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': "Bearer " + token
          }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          setNome(data.data.user.name);
          setEmail(data.data.user.email);
          setCidade(data.data.user.cidade);
          setEstado(data.data.user.estado);
          setTelefone(formatarTelefone(data.data.user.telefone));
          setCargo(data.data.user.cargo);
          // setTipoUsuario(data.data.user.group);
          setComunicacaoEmail(data.data.user.comunicacao_email);
          setComunicacaoWhatsapp(data.data.user.comunicacao_whatsapp);
          setComunicacaoSMS(data.data.user.comunicacao_sms);
          
          if(data.data.user.google_id !== null || data.data.user.linkedin_id !== null){
              setShowUpdatePass(false);
          }

          const itemEstado = selectEstado.find(({ value: v }) => v === data.data.user.estado ); 
          setEstadoSelecionado(itemEstado);
          
          definirCidades(data.data.user.estado, data.data.user.cidade).then((r)=>{
            if(selectCidade.length > 0){
              const itemCidade = selectCidade.find(({ value: v }) => v === data.data.user.cidade ); 
              setCidadeSelecionada(itemCidade);
            } else{
              console.log(data.data.user.cidade);
            }
          });

          localStorage.setItem('empresas_vinculadas', data.data.empresas_vinculadas);          
      })
      .catch((err) => {
          // Erro
      })
      .finally(() => {
          setLoading(false);
      });

  }, []);

  return (
    <>     
      <div className=' mx-10 rounded-lg'>        
        <div className="w-auto">
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-1'>
              <form className='mt-6' onSubmit={handleImageSubmit}>
                <div className='grid grid-cols-4'>
                  <div className=' col-span-1'>
                    <Avatar />
                  </div>
                  <div className='col-span-3'>
                  <label className='block text-lg text-[#414141] font-medium'>Foto de Perfil</label>
                          <label className='block text-xs text-[#b1b1b1] font-light'>Utilize uma imagem no formato JPG ou PNG com tamanho máximo de 1 MB.</label>
                          <input
                            className="mt-2 block px-2.5 pb-2.5 pt-2.5 w-full text-sm text-gray-900 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-gray-300 border-gray-600"
                            type="file"
                            id="foto_perfil"
                            onChange={uploadImage}
                          />
                          {(typeof(image) != 'undefined') ? 
                            <>
                              <img src={image} className="my-3 mx-auto block rounded-full h-[150px] w-[150px]" alt="Avatar usuário" />
                              <FormAction handleSubmit={handleImageSubmit} loading={loading} text="Salvar foto" />
                            </>
                          : '' }
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Classe removida: divide-x divide-slate-400/25 */}
          <div className='grid grid-cols-3 h-auto gap-6 auto-cols-max'>
            <form className='mt-6 col-span-1' onSubmit={handleSubmit}>
              <label className='block text-lg text-[#414141] font-medium py-3'>Seus dados</label>
              <div className=''>
                <SingleInput label="Nome Completo"
                name="nome"
                type="text"
                value={nome}
                showAlert={alert && typeof(updateDataErrorState.name) != 'undefined' ? true: false}
                mensagemErro={typeof(updateDataErrorState.name) != 'undefined' ? updateDataErrorState.name : ''}
                onChange={(v) => setNome(v.target.value)} />

                <SingleInput label="E-mail"
                name="email"
                type="email"
                value={email}
                disabled={loading}
                showAlert={alert && typeof(updateDataErrorState.email) != 'undefined' ? true: false}
                mensagemErro={typeof(updateDataErrorState.email) != 'undefined' ? updateDataErrorState.email : ''}
                onChange={(v) => setEmail(v.target.value)} />

                <SingleInput label="Telefone"
                name="telefone"
                type="tel"
                value={telefone}
                disabled={loading}
                showAlert={alert && typeof(updateDataErrorState.telefone) != 'undefined' ? true: false}
                mensagemErro={typeof(updateDataErrorState.telefone) != 'undefined' ? updateDataErrorState.telefone : ''}
                onChange={(v) => setTelefone(formatarTelefone(v.target.value))} />
                
                <SingleInput label="Cargo"
                name="cargo"
                type="text"
                value={cargo}
                disabled={loading}
                showAlert={alert && typeof(updateDataErrorState.cargo) != 'undefined' ? true: false}
                mensagemErro={typeof(updateDataErrorState.cargo) != 'undefined' ? updateDataErrorState.cargo : ''}
                onChange={(v) => setCargo(v.target.value)} />
                
                <div className="mt-6">
                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateDataErrorState.estado) != 'undefined' ? "block": "none") }}>{typeof(updateDataErrorState.estado) != 'undefined' ? updateDataErrorState.estado : '' }</span>
                    <Select 
                      placeholder="Selecione um Estado"
                      // isClearable={false} 
                      // options={selectEstado}
                      options={selectEstado.map((guest, index) => {
                        return {
                          label: guest.label,
                          value: guest.value,
                          key: index
                        };
                      })}
                      onChange={(item) => {
                        setEstado(item.value);
                        setEstadoSelecionado(item);
                        definirCidades(item.value);
                      }}
                      value={estadoSelecionado}
                    />
                </div>
                
                <div className="mt-6">
                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(updateDataErrorState.cidade) != 'undefined' ? "block": "none") }}>{typeof(updateDataErrorState.cidade) != 'undefined' ? updateDataErrorState.cidade : '' }</span>
                    <Select 
                    className="mt-6"
                    placeholder="Selecione uma Cidade"
                    isClearable={false} 
                    options={selectCidade}
                    // onChange={(cidade) => setCidade(cidade)}
                    onChange={(item) => {
                      setCidade(item.value);
                      setCidadeSelecionada(item);
                    }}
                    value={cidadeSelecionada}
                    />

                    <div className='mt-5'>
                        <Checkbox
                            label={ <div className='contents w-full'>Aceito receber comunicações por e-mail</div> }
                            value={((comunicacao_email) ? true : false)}
                            onChange={handleCheckboxEmail}/>
                    </div>
                    
                    <div>
                        <Checkbox
                            label={ <div className='contents w-full'>Aceito receber comunicações por WhatsApp</div> }
                            value={((comunicacao_whatsapp) ? true : false)}
                            onChange={handleCheckboxWhatsapp}/>
                    </div>
                    
                    <div>
                        <Checkbox
                            label={ <div className='contents w-full'>Aceito receber comunicações por SMS</div> }
                            value={((comunicacao_sms) ? true : false)}
                            onChange={handleCheckboxSMS}/>
                    </div>
                </div>
                
                <FormAction handleSubmit={handleSubmit} text="Salvar" loading={loading}/>
              </div>
            </form>
            {showUpdatePass ?
                <div className='pl-6'>
                  <form className='mt-6' onSubmit={handlePassSubmit}>
                    <label className='block text-lg text-[#414141] font-medium py-3'>Atualizar Senha</label>

                    <div className='flex'>
                      <SingleInput label="Senha Atual"
                      name="senhaatual"
                      type={senhaTypeAtual}
                      value={senhaatual}
                      disabled={loading}
                      showAlert={alert && typeof(mensagemErro.senha_atual) != 'undefined' ? true: false}
                      mensagemErro={typeof(mensagemErro.senha_atual) != 'undefined' ? mensagemErro.senha_atual : ''}
                      onChange={(v) => setSenhaAtual(v.target.value)} />
                      <div className="mt-4 -ml-9 z-10">
                        <button className="btn btn-outline-primary" onClick={togglePasswordAtual}>
                          { senhaTypeAtual ==="password" ? <RiEyeCloseLine className='text-lg text-slate-600'/> : <RiEyeLine className='text-lg text-slate-600'/> }
                        </button>
                      </div>
                    </div>

                    <div className='flex'>
                      <SingleInput label="Nova Senha"
                      name="novasenha"
                      type={senhaTypeNova}
                      value={novasenha}
                      disabled={loading}
                      showAlert={alert && typeof(mensagemErro.nova_senha) != 'undefined' ? true: false}
                      mensagemErro={typeof(mensagemErro.nova_senha) != 'undefined' ? mensagemErro.nova_senha : ''}
                      onChange={(v) => setNovaSenha(v.target.value)} />
                      <div className="mt-4 -ml-9 z-10">
                        <button className="btn btn-outline-primary" onClick={togglePasswordNova}>
                          { senhaTypeNova ==="password" ? <RiEyeCloseLine className='text-lg text-slate-600'/> : <RiEyeLine className='text-lg text-slate-600'/> }
                        </button>
                      </div>
                    </div>
                    
                    <div className='flex'>
                      <SingleInput label="Confirmar Senha"
                      name="confirmarsenha"
                      type={senhaTypeConfirmar}
                      value={confirmarsenha}
                      disabled={loading}
                      onChange={(v) => setConfirmarSenha(v.target.value)} />
                      <div className="mt-4 -ml-9 z-10">
                        <button className="btn btn-outline-primary" onClick={togglePasswordConfirmar}>
                          { senhaTypeConfirmar ==="password" ? <RiEyeCloseLine className='text-lg text-slate-600'/> : <RiEyeLine className='text-lg text-slate-600'/> }
                        </button>
                      </div>
                    </div>

                    <FormAction handleSubmit={handlePassSubmit} loading={loading} text="Salvar" />
                  </form>
                </div>
              : ""}
              <div className='meudados-table-empresas pl-6'>
                <label className='block text-lg text-[#414141] font-medium py-3 mt-6'>Suas empresas cadastradas</label>
                <EmpresaDoUsuario showAddCompany="true" />
              </div>
              <div>
                <ToastContainer />
              </div>
          </div>
        </div>
        
      </div>
    </>
  )
}