const selectAcoesLogs = [
    { value: '0', label: 'Todos' },
    { value: '1', label: 'Acessar página' },
    { value: '2', label: 'Atualizar dados' },
    { value: '3', label: 'Atualizar senha' },
    { value: '4', label: 'Download boleto' },
    { value: '5', label: 'Download NF' },
    { value: '12', label: 'Download material' },
    { value: '6', label: 'Editar empresa' },
    { value: '7', label: 'Editar usuário' },
    { value: '8', label: 'Erro' },
    { value: '9', label: 'Login' },
    { value: '11', label: 'Remover vínculo' },
    { value: '10', label: 'Víncular empresa' },
  ]

export {selectAcoesLogs}