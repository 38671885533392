import React, {useState} from 'react'
import InfoTopBorder from '../Alert/InfoTopBorder'
import Modal from '../Modal'
import FormVincularEmpresa from '../Form/FormVincularEmpresa';
// import GetRssSolucoes from '../RSS/BlogSolucoes'
// import DashSlider from '../DashSlider';
// import DashCardsGM from '../DashCardsGM';
// import DashCardsSolucoes from '../DashCardsSolucoes';
import DashCarouselEmpresas from '../DashCarouselEmpresas'
// import DashMapaMassa from '../DashMapaMassa'
// import DashMapaMassaFM from '../DashMapaMassaFM'
// import DashCarouselProj from '../DashCarouselProj'
import DashFullSlider from '../DashFullSlider'

export default function Dashboard() {
    const [showModal, setOpenModal] = useState(false);
    const openModal = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }

  return (
    <>
      <div>
        {(localStorage.getItem("empresas_vinculadas") === 'false') ? 
          <>
          <InfoTopBorder 
          titulo="Seu usuário está sem vínculo com uma Empresa." 
          texto="Para acessar todos os recursos e funcionalidades disponíveis, é necessário vincular-se, por favor, clique no botão abaixo e preencha as informações necessárias."
          textoBotao="Vincular Empresa"
          actionBotao={openModal}
          />{showModal && <Modal closeModal={closeModal} conteudo={FormVincularEmpresa}/>}
          </>
          : ''
          }
      </div>
      <div className='p-5'>
        <DashFullSlider />
      </div>
      <div className='p-5'>
        <DashCarouselEmpresas />
      </div>
    </> 
  )
}