import React, { useState } from "react"
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { formatarCnpj } from "./helper";
// import Checkbox from '../Checkbox'
import { ToastContainer, toast } from 'react-toastify';

const selectTipoEmpresa = [
    { value: 'nacional', label: 'Nacional' },
    { value: 'internacional', label: 'Internacional' },
]

const selectAgencia = [
    { value: 'sim', label: 'Sim, é agência' },
    { value: 'nao', label: 'Não, é um cliente' },
]

const selectCidade = [];

export default function FormAdminCliente(){
    const [tipoEmpresa, setTipoEmpresa] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorState, setErrorState]=useState({});
    const [mensagemErro,setMensagemErroState]=useState({});
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState(false);

    const [cliente, setCliente] = useState("");
    const [agencia, setAgencia] = useState("");

    // Constantes apenas para não clientes
    const [companies, setCompanies] = useState({});
    const [inputSearch, setInputSearch] = useState("");
    const [afterSearch, setAfterSearch] = useState(false);

    const [ipUser, setIPUser] = useState(() => {
        setLoading(true);
        fetch('http://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            if(typeof(data.ip) != 'undefined'){
                setIPUser(data.ip);
            } else{
                setIPUser('');
            }
        })
        .catch((err) => {
            setIPUser('');
        })
        .finally(() => {
            setLoading(false);
        })
    });

    function handleSearch(e){
        if(inputSearch !== ""){
            setAlert(false);
            setLoading(true);
            setAfterSearch(false);
            setCompanies({});
            const token = localStorage.getItem("token");
            const postData = {
                id_usuario: window.location.href.split('/')[6],
                search: inputSearch,
            };
            let tipoBusca = ((cliente === 'sim') ? 'client' : 'agency');
            fetch(process.env.REACT_APP_API_BACK_URL + '/api/search-'+tipoBusca, {
                method: 'POST',
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(postData)
            }).then(response => 
                response.json().then(response => ({ response })
            )).then(response => {
                if(response.response.data.success){
                    setAfterSearch(true);
                    setCompanies(response.response.data.empresas);
                } else{
                    toast.error('Nenhum cliente foi encontrado.', {autoClose:3000})
                }
                
            })
            .catch((error) => {
                toast.error('Não foi possível realizar essa ação no momento.', {autoClose:3000})
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }

    const navigate = useNavigate();
    const reload=()=>window.location.reload();

    const handleSubmit=(e)=>{
        e.preventDefault();
        vincularAdminCliente();
    }

    const vincularAdminCliente = (e) => {
        const idUser = window.location.href.split('/')[6];
        setLoading(true);
        setAlert(false);
        const token = localStorage.getItem("token");
        const postData = {
                id_usuario: idUser,
                cliente: cliente,
                agencia: agencia,
                cliente_internacional: (tipoEmpresa === 'internacional') ? true : false,
                cnpj: cnpj,
                user_agent: window.navigator.userAgent,
                ip: ipUser,
            };

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/vincular-admin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setAlert(true);
            if(res.response.success === true){
                setAlertStatus(true);
                setAlertMsg(res.response.message);
                setTipoEmpresa("");
                setCnpj("");
                localStorage.setItem("info_vinculo", false);
                window.location.reload();
                setCliente("");
                setAgencia("");
            } else{
                setAlertStatus(false);
                errorState['cnpj'] = '';
                
                setMensagemErroState(errorState);
                setMensagemErroState(res.response.data);
                setAlertMsg(res.response.message);
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return(
        <>
            <form onSubmit={handleSubmit}>
                <span className="block mb-3">Para definir esse usuário como um administrador de alguma empresa, informe os seguintes dados:</span>
                
                <Select 
                    className="mb-8"
                    placeholder="É uma agência?"
                    isClearable={false} 
                    options={selectAgencia}
                    onChange={(agencia) => {((agencia.value === 'nao') ? setCliente('sim'): setCliente('nao'));setAgencia(agencia.value)}}
                />

                {(cliente !== "" || agencia !== "") ? 
                    <>
                        <Select 
                            className="mb-8"
                            placeholder="Selecione o Tipo de Empresa"
                            isClearable={false} 
                            options={selectTipoEmpresa}
                            onChange={(tipoEmpresa) => setTipoEmpresa(tipoEmpresa.value)}
                            required
                        />

                        <div class="relative w-full">
                            <input type="text" id="buscar_cliente" class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-md border appearance-none text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer border-gray-300 border-gray-600" placeholder="Buscar por CNPJ, razão social ou nome fantasia" value={inputSearch} onChange={(v) => setInputSearch(v.target.value)} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} />
                            <button type="button" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={e => {
                                handleSearch(e);
                            }}>
                                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                                <span class="sr-only">Buscar</span>
                            </button>
                        </div>

                        {(companies && Object.keys(companies).length > 0) ? (
                            <Select 
                                className="mb-8 mt-2"
                                placeholder={(loading) ? "Carregando opções..." : (companies.length > 0) ? "Selecione" : "Nenhum cliente encontrado"}
                                isClearable={false} 
                                options={(companies.length > 0) ? companies.map((item, index) => {
                                    return {
                                        label: item.cnpj + ' - ' +item.nome,
                                        value: item.cnpj,
                                        key: index
                                    };
                                    }) : null}
                                onChange={(v) => setCnpj(formatarCnpj(v.value))}
                                disabled={(companies.length > 0) ? "false" : "true"}
                            />
                        ) : 
                        (afterSearch && inputSearch !== "") ? 
                            <p id="helper-text-explanation" class="px-2.5 mt-2 text-sm text-gray-500">Nenhum resultado encontrado para sua pesquisa.</p>
                        : 
                            ''
                        }

                        <SingleInput 
                            label="CNPJ"
                            name="cnpj"
                            type="text"
                            value={cnpj}
                            onChange={(v) => setCnpj(formatarCnpj(v.target.value))} 
                        />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.cnpj) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.cnpj) != 'undefined' ? mensagemErro.cnpj : '' }</span>

                        <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                        <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                    </>
                :
                ""}
            </form>
            <div>
                <ToastContainer />
            </div>
        </>
    )
}