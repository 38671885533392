import React, { useState } from 'react';
import Tabela, { StatusTag, GrupoUsuario, formatarDataPtBr} from './Tabela/Tabela'
import { confirmAlert } from 'react-confirm-alert';
import { ToastContainer, toast } from 'react-toastify';

// const getData = () => {
//     const data = [
//       {
//         nome: "BKJHBJNKLJN",
//         cargo: "OPEC",
//         datacadastro: "10/03/2019",
//         status: "Ativo",
//         permissao: "Usuário",
//       },
//       {
//         nome: "Whfvuirhf",
//         cargo: "Assistente de Marketing",
//         datacadastro: "15/07/2011",
//         status: "Inativo",
//         permissao: "Usuário",
//       },
//       {
//         nome: "Camila Silva",
//         cargo: "Desenvolvedora Front-End Jr",
//         datacadastro: "26/08/2020",
//         status: "Ativo",
//         permissao: "Administrador",
//       },
//       {
//         nome: "Ricardo Silveira",
//         cargo: "Desenvolvedor FullStack Senior",
//         datacadastro: "01/01/2018",
//         status: "Ativo",
//         permissao: "Administrador",
//       },
//       {
//         nome: "Jamilson Bine",
//         cargo: "Desenvolvedor FullStack Pleno",
//         datacadastro: "04/11/2016",
//         status: "Ativo",
//         permissao: "Administrador",
//       },
//     ]
//     return [...data,...data,...data, ]
//   }

  export default function UsuariosDaEmpresa({ conteudo, hiddenColumnsTable = [], cnpj }){
    const [loading, setLoading] = useState(false);
    const alertRemoverVinculo = (e) => {
      confirmAlert({
          title: 'Remover vínculo',
          message: 'Essa é uma ação irreversível, tem certeza disso?',
          buttons: [
            {
              label: 'Sim',
              onClick: () => removerVinculo(e)
            },
            {
              label: 'Não',
            }
          ]
      });
    };

    const removerVinculo = (e) => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const id_usuario = e.target.dataset.usuario;
      const tipo_empresa = e.target.dataset.tipoempresa;
      
      const postData = {
          cnpj: cnpj,
          id_usuario: id_usuario,
          tipo_empresa:tipo_empresa,
      };

      e.target.textContent = 'Aguarde...';
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/remover-vinculo', {
          method: 'POST',
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify(postData)
      }).then(response => 
          response.json().then(response => ({ response })
      )).then(response => {
          if(response.response.data.success){
              toast.success('Vínculo removido com sucesso.', {autoClose:3000});
              window.location.reload();
          } else{
              toast.error('Não foi possível remover o vínculo.', {autoClose:3000});
          }
      })
      .catch((error) => {
          toast.error('Não foi realizar essa ação no momento.', {autoClose:3000})
      })
      .finally(() => {
          e.target.textContent = 'Remover vínculo';
          setLoading(false);
      });
    };

    const columns = React.useMemo(
        () => [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Cargo",
            accessor: "cargo",
          },
          {
            Header: "Cadastrado desde",
            accessor: "created_at",
            Cell: formatarDataPtBr,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: StatusTag,
          },
          {
            Header: "Nível",
            accessor: "group",
            Cell: GrupoUsuario,
          },
          {
            Header: () => null,
            id: "remover_vinculo",
            isVisible: false,
            Cell: ({ row }) => (
              
              <button onClick={alertRemoverVinculo} className='text-red-700 font-semibold py-1 px-1 rounded text-sm' title="Remover vínculo" data-row={row.id} data-empresa={(window.location.href.split('/')[6]) ? window.location.href.split('/')[6] : 'self'} data-usuario={row.original.id} data-tipoempresa={window.location.href.split('/')[5]} >
                  Remover vínculo
              </button>
            )
          }
          // {
          //   Header: "Permissão",
          //   accessor: "permissao",
          //   Filter: Filtro,
          //   filter: 'includes',
          // },
        ], []);
      const data = conteudo;
      // const data = React.useMemo(() => getData(), []);
    return(
    <div className="">
        <main className="mx-auto px-4 sm:px-6 lg:px-8 pt-4">
            <div className="mt-4">
                <Tabela columns={columns} data={data} hiddenColumnsTable={hiddenColumnsTable} />
            </div>
            <div>
                <ToastContainer />
            </div>
        </main>
    </div>
    )
  }