import { React, useState } from 'react';
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from './components/Pages/LoginPage';
import CadastroPage from './components/Pages/CadastroPage';
import Portal from './components/DashInicio';
import GoogleCallback from './components/GoogleCallback';
import LinkedinCallback from './components/LinkedinCallback';
import Logout from './components/Logout';
import EsqueceuSenha from './components/Pages/EsqueceuSenha';
import ResetarSenha from './components/Pages/ResetarSenha';
import MeusDadosATT from './components/Pages/MeusDadosATT';
import './css/app.css';
import EmailConfirmado from './components/EmailConfirmado';
// import PortalRotas from './components/Portal';
import Layout from "./components/Layout";
import LayoutExterno from "./components/LayoutExterno";
import BoletoNF from './components/Pages/BoletoNF';
import GestaoEmpresas from "./components/Pages/GestaoEmpresas";
import SolucoesNegocios from "./components/Pages/SolucoesNegocios"
import GestaoClientes from "./components/Pages/GestaoClientes";
import GestaoAgencias from "./components/Pages/GestaoAgencias";
import InternaEmpresa from "./components/Pages/InternaEmpresa";
import InternaAgencia from "./components/Pages/InternaAgencia";
import VisualizarCliente from "./components/Pages/VisualizarCliente";
import GestaoUsuario from "./components/Pages/GestaoUsuario";
import ComprovantesdeVeiculacao from './components/Pages/ComprovantesdeVeiculacao';
import InternaGestaoUsuario from "./components/Pages/InternaGestaoUsuario";
import Estatisticas from "./components/Pages/Estatisticas";
import Protected from './components/Protected'
import HistoricoCompra from "./components/HistoricoCompra"
 
export default function App(){
    const [loading, setLoading] = useState(false);
    // const [loggedIn, setLoggedIn] = useState(false);
    // const [isLoggedIn, setIsLoggedIn] = useState(
    //     () => localStorage.getItem('token') ? true : false
    //   );

    // useEffect(() => {
    //     setLoading(true);
    //     const token = localStorage.getItem('token');
    //     if(!token) {
    //         setIsSignedIn(false);
    //     } else{
    //         fetch(process.env.REACT_APP_API_BACK_URL + '/api/user',{
    //             headers : {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': "Bearer " + token
    //             }
    //         })
    //         .then((response) => {
    //             return response.json();
    //         })
    //         .then((data) => {
    //             if(typeof(data.success) != 'undefined'){
    //                 setIsSignedIn(data.success);
    //             } else{
    //                 setIsSignedIn(false);
    //             }
    //         })
    //         .catch((err) => {
    //             // Erro
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    //     }
    // }, []);

    return(
        (!loading) ?
        <BrowserRouter>
            <Routes>
                <Route element={<LayoutExterno regua="true" />}>
                    <Route
                        exact path="/"
                        element={<LoginPage />}
                    />
                    
                    <Route
                        exact path="/login"
                        element={<LoginPage/>}
                    />
                    <Route path='/cadastro' element={<CadastroPage/>} />
                </Route>
                <Route path='/esqueceu-a-senha' element={<EsqueceuSenha/>} />
                <Route path='/redefinir-senha/*' element={<ResetarSenha/>} />
                <Route path='/verificar-email/*' element={<EmailConfirmado/>} />
                <Route
                    path="/logout"
                    element={<Logout />}
                />

                <Route path='/auth/google' element={<GoogleCallback/>} />
                <Route path='/auth/linkedin' element={<LinkedinCallback/>} />
                {/* <Route path='/historico-de-compras' element={<Historico/>} /> */}
                
                <Route element={<Layout />}>
                    {/* <Route
                        path="/dash"
                        element={isLoggedIn
                            ? <Portal />
                            : <Navigate to="/" replace />
                        }
                    /> */}
                    <Route
                        path="/dash"
                        element={
                        <Protected>
                            <Portal />
                        </Protected>
                        }
                    />
                    <Route
                        path="/meus-dados"
                        element={<Protected>
                                    <MeusDadosATT />
                                </Protected>
                        }
                    />
                    <Route
                        path="/financeiro"
                        element={<Protected>
                                    <BoletoNF />
                                </Protected>
                        }
                    />
                    <Route
                        path="/historico-de-compras"
                        element={<Protected>
                                    <HistoricoCompra />
                                </Protected>
                        }
                    />
                    <Route
                        path="/solucoes-de-negocios"
                        element={<Protected>
                                    <SolucoesNegocios />
                                </Protected>
                        }
                    />
                    <Route
                        path="/comprovantes-de-veiculacao"
                        element={<Protected>
                                    <ComprovantesdeVeiculacao />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/gestao-de-empresas"
                        element={<Protected>
                                    <GestaoEmpresas />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/gestao-de-clientes"
                        element={<Protected>
                                    <GestaoClientes />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/gestao-de-agencias"
                        element={<Protected>
                                    <GestaoAgencias />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/visualizar/cliente/:id"
                        element={<Protected>
                                    <VisualizarCliente />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/editar/empresa/:id"
                        element={<Protected>
                                    <InternaEmpresa />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/visualizar/agencia/:id"
                        element={<Protected>
                                    <InternaAgencia />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/gestao-de-usuarios"
                        element={<Protected>
                                    <GestaoUsuario />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/editar/usuario/:id"
                        element={<Protected>
                                    <InternaGestaoUsuario />
                                </Protected>
                        }
                    />
                    <Route
                        path="/configuracoes/estatisticas"
                        element={<Protected>
                                    <Estatisticas />
                                </Protected>
                        }
                    />
                </Route>
            </Routes>
            
        </BrowserRouter>
        : 'Carregando...'
    );
}

if(document.getElementById('root')){
    createRoot(document.getElementById('root')).render(<App />)
}