import React from "react"
import BannerContagemRegressiva from '../BannerContagemRegressiva'
import CardListSolucoesNegocios from "../CardListSolucoesNegocios"

export default function SolucoesNegocios(){
    return(
        <>
            <div className="p-5">
                <BannerContagemRegressiva />
            </div>
            <div>
                <CardListSolucoesNegocios />
            </div>
        </>
    )
}