import React from "react";

export default function InfoTopBorder(props) {
    return (
        <div class="bg-amber-100 border-t-4 border-amber-500 rounded-b text-amber-900 px-4 py-3 shadow-md"
            role="alert">
            <div class="flex">
                <div class="py-1">
                    <svg class="fill-current h-6 w-6 text-amber-500 mr-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                    >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                </div>
                <div>
                    <p class="font-bold">{props.titulo}</p>
                    <p class="text-sm">
                        {props.texto}
                    </p>
                    <button
                        className="group relative w-2/5 flex justify-center py-2 px-4 border border-amber-700 duration-200 text-sm font-medium rounded-md text-amber-700 bg-transparent hover:bg-amber-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-700 mt-2"
                        onClick={props.actionBotao}
                    >{props.textoBotao}
                    </button>

                </div>
            </div>
        </div>
    );
}
