import React, {useState} from "react"
import SingleInput from "../SingleInput"
import FormAction from "../FormAction"
import Select from 'react-select'
import { selectEstado } from '../../components/constants/estados'
import { useNavigate } from "react-router-dom";
import { formatarTelefone, formatarCnpj } from "./helper";
import Checkbox from '../Checkbox'

const selectTipoEmpresa = [
    { value: 'nacional', label: 'Nacional' },
    { value: 'internacional', label: 'Internacional' },
  ]

const selectCliente = [
    { value: 'sim', label: 'Sim, é um cliente do Grupo Massa' },
    { value: 'nao', label: 'Não é um cliente do Grupo Massa' },
]

const selectAgencia = [
    { value: 'sim', label: 'Sim, é agência' },
    { value: 'nao', label: 'Não é agência' },
]

const selectCidade = [];

function formatarValor(valor){
        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g,''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ",$1");

        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        return valor;

}

export default function FormVincularEmpresa(){
    const [tipoEmpresa, setTipoEmpresa] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [numNf, setNumNf] = useState("");
    const [dataEmissao, setDataEmissao] = useState("");
    const [valorTotal, setValorTotal] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorState, setErrorState]=useState({});
    const [mensagemErro,setMensagemErroState]=useState({});
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState(false);

    const [cliente, setCliente] = useState("");
    const [agencia, setAgencia] = useState("");

    // Constantes apenas para não clientes
    const [cnpjNovo, setCnpjNovo] = useState("");
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [estadoSelecionado, setEstadoSelecionado] = useState("");
    const [cidadeSelecionada, setCidadeSelecionada] = useState("");
    const [comunicacao_email, setComunicacaoEmail] = useState(true);
    const [comunicacao_whatsapp, setComunicacaoWhatsapp] = useState(true);
    const [comunicacao_sms, setComunicacaoSMS] = useState(true);

    const navigate = useNavigate();
    const reload=()=>window.location.reload();

    const handleSubmit=(e)=>{
        e.preventDefault();
        vincularEmpresa()
    }

    const handleCheckboxEmail = () => {
        setComunicacaoEmail(!comunicacao_email);
    }

    const handleCheckboxWhatsapp = () => {
        setComunicacaoWhatsapp(!comunicacao_whatsapp);
    }

    const handleCheckboxSMS = () => {
        setComunicacaoSMS(!comunicacao_sms);
    }
    const vincularEmpresa=(e)=>{
        setLoading(true);
        setAlert(false);
        const token = localStorage.getItem("token");
        const postData = (cliente === 'sim' || agencia === 'sim') ?
            //Vincular usuário à empresa
            {
                cliente: cliente,
                agencia: agencia,
                cliente_internacional:(tipoEmpresa === 'internacional') ? true : false,
                cnpj:(agencia === 'sim') ? cnpjNovo : cnpj,
                numero_nota:numNf,
                data_emissao:dataEmissao,
                valor:valorTotal,
            }
        :
            // Criar empresa para prospect
            {
                cliente: cliente,
                cnpj: cnpjNovo,
                cliente_internacional:(tipoEmpresa === 'internacional') ? true : false,
                nome_fantasia:nomeFantasia,
                razao_social:razaoSocial,
                estado:estado,
                cidade:cidade,
                telefone:telefone,
                email:email,
                comunicacao_email:comunicacao_email,
                comunicacao_whatsapp:comunicacao_whatsapp,
                comunicacao_sms:comunicacao_sms,
            };
        

        fetch(process.env.REACT_APP_API_BACK_URL + '/api/autenticar-cliente', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(postData)
        }).then(response => 
            response.json().then(response => ({ response })
        ).then(res => {
            setAlert(true);
            if(res.response.success === true){
                setAlertStatus(true);
                setAlertMsg(res.response.message);
                setTipoEmpresa("");
                setCnpj("");
                setNumNf("");
                setDataEmissao("");
                setValorTotal("");
                localStorage.setItem("info_vinculo", false);
                if (window.location.href.indexOf("meus-dados") > -1) {
                    setCliente("");
                    window.location.reload();
                } else{
                    setCliente("");
                    if(cliente === 'sim'){
                        window.location.reload();
                    } else{
                        window.location.href = '/meus-dados#empresas-usuario';
                    }
                }
            } else{
                setAlertStatus(false);
                errorState['cnpj'] = '';
                errorState['numero_nota'] = '';
                errorState['data_emissao'] = '';
                errorState['valor'] = '';
                
                setMensagemErroState(errorState);
                setMensagemErroState(res.response.data);
                setAlertMsg(res.response.message);
            }
        })).catch((err) => {
            setAlert(true);
            setAlertMsg("Error in connection.");
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const definirCidades = (estado, buscar = '') => {
        if(estado !== ''){
            setLoading(true);
            setCidade('');
            setCidadeSelecionada('');
            let response = fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+estado+'/municipios',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                while(selectCidade.length > 0) {
                    selectCidade.pop();
                }
                data.map((items =>
                    selectCidade.push({value:items.nome, label:items.nome})
                ));
                if(buscar !== ''){
                    const itemCidade = selectCidade.find(({ value: v }) => v === buscar ); 
                    //setCidadeSelecionada(itemCidade);
                }
            }).catch((err) => {
                setAlert(true);
                setAlertMsg("Error in connection.");
            })
            .finally(() => {
                setLoading(false);
            });
        }
    };
    
    return(
        <>
            <form id="adicionar-empresa" onSubmit={handleSubmit} >
                <span className="block mb-3">Para realizar o vínculo de seu usuário com uma empresa, informe os seguintes dados:</span>
                <Select 
                    className={(cliente === 'sim') ? "hidden" : "mb-8"}
                    placeholder="É uma agência?"
                    isClearable={false} 
                    options={selectAgencia}
                    onChange={(agencia) => setAgencia(agencia.value)}
                />
                <Select 
                    className={(agencia === 'sim' || agencia ==='') ? "hidden" : "mb-8"}
                    placeholder="Já é um cliente do Grupo Massa?"
                    isClearable={false} 
                    options={selectCliente}
                    onChange={(cliente) => setCliente(cliente.value)}
                    // required
                />
                {(cliente !== "" || agencia !== "") ? 
                    (cliente === 'sim') ?
                        <>
                            <Select 
                                className="mb-8"
                                placeholder="Selecione o Tipo de Empresa"
                                isClearable={false} 
                                options={selectTipoEmpresa}
                                onChange={(tipoEmpresa) => setTipoEmpresa(tipoEmpresa.value)}
                                // required
                            />

                            <SingleInput label="CNPJ"
                            name="cnpj"
                            type="text"
                            value={cnpj}
                            onChange={(v) => setCnpj(formatarCnpj(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.cnpj) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.cnpj) != 'undefined' ? mensagemErro.cnpj : '' }</span>
                            <SingleInput label="Nº Nota Fiscal"
                            name="numNf"
                            type="text"
                            value={numNf}
                            onChange={(v) => setNumNf(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.numero_nota) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.numero_nota) != 'undefined' ? mensagemErro.numero_nota : '' }</span>
                            <SingleInput label="Data de Emissão"
                            name="dataEmissao"
                            type="date"
                            value={dataEmissao}
                            onChange={(v) => setDataEmissao(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.data_emissao) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.data_emissao) != 'undefined' ? mensagemErro.data_emissao : '' }</span>
                            <SingleInput label="Valor Total"
                            name="valorTotal"
                            type="string"
                            value={valorTotal}
                            onChange={(v) => setValorTotal(formatarValor(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.valor) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.valor) != 'undefined' ? mensagemErro.valor : '' }</span>
                            <span className="text-xs">* Serão aceitas apenas dados de notas de no máximo 60 dias.</span>
                            <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                            <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                        </>
                    :
                    <>
                        <Select 
                            className="mb-8"
                            placeholder="Selecione o Tipo de Empresa"
                            isClearable={false} 
                            options={selectTipoEmpresa}
                            onChange={(tipoEmpresa) => setTipoEmpresa(tipoEmpresa.value)}
                            // required
                        />
                        <SingleInput 
                            label="CNPJ"
                            name="cnpj"
                            type="text"
                            value={cnpjNovo}
                            onChange={(v) => setCnpjNovo(formatarCnpj(v.target.value))} 
                            // required
                        />
                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && mensagemErro.cnpj && typeof(mensagemErro.cnpj) != 'undefined' ? "block": "none") }}>{ (mensagemErro.cnpj && typeof(mensagemErro.cnpj) != 'undefined') ? mensagemErro.cnpj : '' }</span>
                        {(agencia === 'nao') ?
                            <>
                            <SingleInput label="Razão social"
                            name="razao_social"
                            type="text"
                            value={razaoSocial}
                            onChange={(v) => setRazaoSocial(v.target.value)} 
                            // required
                            />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.razao_social) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.razao_social) != 'undefined' ? mensagemErro.razao_social : '' }</span>
                            <SingleInput 
                                label="Nome fantasia"
                                name="nome_fantasia"
                                type="text"
                                value={nomeFantasia}
                                onChange={(v) => setNomeFantasia(v.target.value)} 
                                // required
                            />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.nome_fantasia) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.nome_fantasia) != 'undefined' ? mensagemErro.nome_fantasia : '' }</span>
                            {(tipoEmpresa === 'nacional') ? 
                                <>
                                    <Select 
                                        className="mt-6"
                                        placeholder="Selecione um Estado"
                                        options={selectEstado.map((guest, index) => {
                                        return {
                                            label: guest.label,
                                            value: guest.value,
                                            key: index
                                        };
                                        })}
                                        onChange={(item) => {
                                            setEstado(item.value);
                                            setEstadoSelecionado(item);
                                            definirCidades(item.value);
                                        }}
                                        value={estadoSelecionado}
                                    />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.estado) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.estado) != 'undefined' ? mensagemErro.estado : ''}</span>

                                    <Select 
                                        className="mt-6"
                                        placeholder="Selecione uma Cidade"
                                        isClearable={false} 
                                        options={selectCidade}
                                        onChange={(item) => {
                                            setCidade(item.value);
                                            setCidadeSelecionada(item);
                                        }}
                                        value={cidadeSelecionada}
                                    />
                                    <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.cidade) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.cidade) != 'undefined' ? mensagemErro.cidade : ''}</span>

                                    </>
                                    :
                                    <>
                                        <SingleInput label="Estado"
                                        name="estado"
                                        type="text"
                                        customClass={(alert && typeof(mensagemErro.estado) != 'undefined' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={estado}
                                        onChange={(v) => setEstado(v.target.value)} />
                                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.estado) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.estado) != 'undefined' ? mensagemErro.estado : ''}</span>

                                        <SingleInput label="Cidade"
                                        name="cidade"
                                        type="text"
                                        customClass={(alert && typeof(mensagemErro.cidade) != 'undefined' ? "border-red-300 border-red-600": "border-gray-300 border-gray-600")}
                                        value={cidade}
                                        onChange={(v) => setCidade(v.target.value)} />
                                        <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.cidade) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.cidade) != 'undefined' ? mensagemErro.cidade : ''}</span>
                                    </>
                                }
                            <SingleInput label="Telefone"
                            name="telefone"
                            type="text"
                            value={telefone}
                            onChange={(v) => setTelefone(formatarTelefone(v.target.value))} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.telefone) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.telefone) != 'undefined' ? mensagemErro.telefone : '' }</span>
                            <SingleInput label="E-mail de contato da empresa"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(v) => setEmail(v.target.value)} />
                            <span className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-1" style={{ display:(alert && typeof(mensagemErro.email) != 'undefined' ? "block": "none") }}>{typeof(mensagemErro.email) != 'undefined' ? mensagemErro.email : '' }</span>

                            <div className="mt-6">
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por e-mail</div> }
                                    value={comunicacao_email}
                                    onChange={handleCheckboxEmail}/>
                            </div>
                            
                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por WhatsApp</div> }
                                    value={comunicacao_whatsapp}
                                    onChange={handleCheckboxWhatsapp}/>
                            </div>
                            
                            <div>
                                <Checkbox
                                    label={ <div className='contents w-full'>Aceito receber comunicações por SMS</div> }
                                    value={comunicacao_sms}
                                    onChange={handleCheckboxSMS}/>
                            </div>
                            </>
                            : ''
                        }

                        <div style={{display:(alert? "block": "none")}} className={((alertStatus) ? 'mt-3 bg-green-100 border-l-4 border-green-500 text-green-700 p-4' : 'bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4')} role="alert">{alertMsg}</div>
                        <FormAction handleSubmit={handleSubmit} text="Vincular" loading={loading} />
                    </>
                :
                ""}
            </form>
        </>
    )
}