import React, {useState, useEffect} from "react"

export default function GetRssSolucoes(){
    const mediumRssFeed = "https://solucoesmassa.com.br/feed/json"

    const MAX_ARTICLES = 4;
    const [articles, setArticles] = useState();

    useEffect(() => {
        const loadArticles = async () => {
            fetch(mediumRssFeed, { headers: { Accept:"application/json" } })
            .then((res) => res.json())
            .then((data) => data.items.filter((item) => item.title.length > 0))
            .then((newArticles) => Array.from(newArticles).slice(0, MAX_ARTICLES))
            .then((articles) => setArticles(articles))
            .catch((error) => console.log(error));
        };

        loadArticles();

    }, [MAX_ARTICLES]);

    return (
        <>
        {articles
            ? 
            <>
            <div class="grid gap-3 pt-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                {articles.map((item, index) => (
                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow flex flex-1 flex-col justify-between">
                        <div>
                            <a href="/#">
                                <img class="rounded-t-lg" src={item.image} alt="" />
                            </a>
                            <div class="px-5 pt-5 pb-1">
                                <a href="/#" title={item.title}>
                                    <h5 class="mb-2 text-md font-500 tracking-tight text-gray-900 line-clamp-2">{item.title}</h5>
                                </a>
                                <p class="mb-3 text-xs font-300 text-gray-600 line-clamp-5">{item.content_text.substring(0, 120)}...</p>
                            </div>
                        </div>
                        <div class="pb-5 pt-0 px-5">
                            <a href={item.url} class="w-full inline-flex justify-center items-center mt-2 px-[37px] py-[10px] bg-[#2785DD] rounded-md text-white font-light text-sm" target="_blank" rel="noopener noreferrer">
                                Ler matéria
                            </a>
                        </div>
                    </div>
                
            // <a
            //     className="link"
            //     href={item.link}
            //     target="_blank"
            //     rel="nofollow noopener noreferrer"
            //     title={item.title}
            //     aria-label={item.link}
            //     key={item.link}
            // >
            // <Card className={classes.root}>
            // <CardActionArea>
            // <CardMedia
            // className={classes.media}
            // image={item.thumbnail}
            // title={item.title}
            // />
            // <CardContent>
            // <Typography gutterBottom variant="h5" component="h2">
            // {item.title}
            // </Typography>
            // <Typography
            // variant="body2"
            // color="textSecondary"
            // component="p"
            // >
            // {parseDate(item.pubDate)}
            // </Typography>
            // </CardContent>
            // </CardActionArea>
            // <CardActions>
            // <FacebookShareButton url={item.link}>
            // <FacebookIcon size={32} round={true} />
            // </FacebookShareButton>
            // <Button size="small" color="primary">
            // Learn More
            // </Button>
            // </CardActions>
            // </Card>
            // </a>
            )) }
            </div>
            </>
            : "no article shown"}
        </>
    )
}