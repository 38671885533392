import React from 'react'

export default function RodapeLogin() {
    return(
        <div className="flex items-center justify-between ">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 text-[blue-900] focus:ring-blue-700 border-gray-300 rounded"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm lembrar-senha-check">
            Lembrar senha
          </label>
        </div>
        <div className="text-sm">
          <a href="/esqueceu-a-senha" className="font-medium text-link-gm">
            Esqueceu sua senha?
          </a>
        </div>
      </div>
    );
}
