import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/*
react-dom.development.js:86 
Warning: You are calling ReactDOMClient.createRoot() on a container that has already been passed to createRoot() before. 
Instead, call root.render() on the existing root instead if you want to update it.
*/
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <App />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
