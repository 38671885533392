import React from "react"

export default function CardNegocio({link, imagem, titulo}){  
    return(
      <div className="text-center w-[189px] h-[240px] snap-start overflow-hidden rounded-md mb-3">
        <a href={link} className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0 rounded-md">
          <img src={imagem} alt={titulo} className="w-full" />
        </a>
      </div>
    )
}