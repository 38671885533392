import React, { useState, useEffect } from 'react'
import ComprovantesVeiculacao from "../ComprovantesVeiculacao"
import Pagination from '../Tabela/Pagination'

export default function ComprovantesdeVeiculacao(runexecuted = false) {
  const [loading, setLoading] = useState(false);
  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItens, setTotalItens] = useState('');
  const [beginSearch, setBeginSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [campanhas, setCampanhas] = useState(null);
  
  useEffect(() => {
      // const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const abortController = new AbortController();
        fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page=1',{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': "Bearer " + token
                }
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setTotalItens(data.data.lists.total);
            setCampanhas(data.data.lists.data);
        })
        .catch((err) => {
            // Erro
        })
        .finally(() => {
            setLoading(false);
        }); 
        return () => {
          abortController.abort()
        }
     } catch (error) {
       console.log(error);
     }
    // fetchData();
  }, []);  

  // useEffect(() => {
  //   setLoading(true);
  //   setBeginSearch(false);
  //   setCampanhas({});
  //   const token = localStorage.getItem("token");

  //   fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page='+currentPage,{
  //           headers : {
  //               'Content-Type': 'application/json',
  //               'Accept': 'application/json',
  //               'Authorization': "Bearer " + token
  //           }
  //   })
  //   .then((response) => {
  //       return response.json();
  //   })
  //   .then((data) => {
  //       setTotalItens(data.data.lists.total);
  //       setCampanhas(data.data.lists.data);
  //       return data.data.lists.data;
  //   })
  //   .catch((err) => {
  //       // Erro
  //   })
  //   .finally(() => {
  //       setLoading(false);
  //   });
  // }, [currentPage]);

  // useEffect(() => {
  //   setLoading(true);
  //   setBeginSearch(true);
  //   setCampanhas({});
    
  //   const token = localStorage.getItem("token");
  //   fetch(process.env.REACT_APP_API_BACK_URL + '/api/list/campaigns/'+limitPage+'?page='+currentPage+'&search='+search,{
  //       headers : {
  //           'Content-Type': 'application/json',
  //           'Accept': 'application/json',
  //           'Authorization': "Bearer " + token
  //       }
  //   })
  //   .then((response) => {
  //       return response.json();
  //   })
  //   .then((data) => {
  //       setTotalItens(data.data.lists.total);
  //       setCampanhas(data.data.lists.data);
  //       return data.data.lists.data;
  //   })
  //   .catch((err) => {
  //       // Erro
  //   })
  //   .finally(() => {
  //       setLoading(false);
  //   });
  // }, [search, limitPage]);
  return (
    <>      
      {campanhas && Object.keys(campanhas).length > 0 ? (
        <>
          <ComprovantesVeiculacao 
            data={campanhas} 
            limitSize={limitPage}
          />
          {/* <Pagination
              totalRows={totalItens}
              pageChangeHandler={setCurrentPage}
              pageLimitChangeHandler={setLimitPage}
              rowsPerPage={limitPage}
              page={currentPage}
              searchInput={beginSearch}
          /> */}
        </>
      ) : (loading) ?
        <span>
            Carregando informações...
        </span>
      :
      (campanhas && Object.keys(campanhas).length === 0)
      ?
        <span>
            Nenhuma informação foi encontrada
        </span>
      :
        <span>
          Sem vínculo
        </span>
      }
    </>

  )
}
