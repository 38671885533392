import React, { useState, useEffect } from 'react'
import Avatar  from '../Avatar'
import SingleInput from "../SingleInput"
import UsuariosDaEmpresa from '../UsuariosDaEmpresa'
// import { selectEstado } from '../../components/constants/estados'
import { formatarCnpj, formatarTelefone } from '../Form/helper'
import Modal from '../Modal'
import FormVincularUsuario from '../Form/FormVincularUsuario';

// const selectTipoEmpresa = [
//   { value: '1', label: 'Afiliada' },
//   { value: '2', label: 'Agência' },
//   { value: '3', label: 'Cliente' },
//   { value: '4', label: 'Fornecedor' },
//   { value: '5', label: 'Prospect' },
// ]
  
export default function VisualizarCliente() {
  const [showModal, setOpenModal] = useState(false);
  const openModal = () => {
      setOpenModal(true);
  }
  const closeModal = () => {
      setOpenModal(false);
  }
  const [rsocial, setRsocial] = useState("");
  const [nomefantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");

  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [bairro, setBairro] = useState("");
  const [endereco, setEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  // const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [usuarios,setUsuarios]=useState({});

  useEffect(() => {
      setLoading(true);
      const idClient = window.location.href.split('/')[6];
      const token = localStorage.getItem("token");
      fetch(process.env.REACT_APP_API_BACK_URL + '/api/client/' + idClient,{
          headers : {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': "Bearer " + token
          }
      })
      .then((response) => {
          return response.json();
      })
      .then((data) => {
          if(!data.success){
              window.location.href = '/dash';
          }
          setRsocial(data.data.client.nome);
          setCnpj(formatarCnpj(data.data.client.cpf_cnpj));
          setEmail(data.data.client.email);
          setNomeFantasia(data.data.client.fantasia);
          setTelefone(formatarTelefone(data.data.client.fone_1));
          setEstado(data.data.client.estado);
          setCidade(data.data.client.cidade);
          // setIdTipoEmpresa(3);
          setTitulo(data.data.client.fantasia);
          setUsuarios(data.data.client.vinculos);
          setEndereco(data.data.client.endereco);
          setCep(data.data.client.cep);
          setBairro(data.data.client.bairro);
          setComplemento(data.data.client.complemento);
          setNumero(data.data.client.numero);

          // const itemTipoEmpresa = selectTipoEmpresa.find(({ value: v }) => v === data.data.company.id_tipo_empresa);

        //   const itemEstado = selectEstado.find(({ value: v }) => v === data.data.company.estado ); 
        //   setEstadoSelecionado(itemEstado);
          
        //   definirCidades(data.data.company.estado, data.data.company.cidade).then((r)=>{
        //     if(selectCidade.length > 0){
        //       const itemCidade = selectCidade.find(({ value: v }) => v === data.data.company.cidade ); 
        //       setCidadeSelecionada(itemCidade);
        //     } else{
        //       console.log(data.data.company.cidade);
        //     }
        //   });
      })
      .catch((err) => {
          // Erro
      })
      .finally(() => {
          setLoading(false);
      });

  }, []);

  return (
    <>
      <div className='
      h-56 
      w-full 
      bg-[url("https://images.unsplash.com/photo-1485727373771-86ef43f2cbeb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80")] 
      bg-no-repeat
      bg-cover
      rounded-lg
      '>
      </div>
      <div className=' mx-10 bg-white -mt-20 rounded-lg p-5'>
        <div className='grid grid-cols-[100px_minmax(800px,_1fr)_10px]'>
          <Avatar width='20' height='20' size='4' color='white'/>
          <div className='flex items-center'>
            <div>
              <span className='block text-xl text-slate-800 font-semibold'>{titulo}</span>
            </div>
          </div>
          <span></span>
        </div>
        
        <div className="w-auto">
          <div className='grid grid-cols-2 h-auto gap-6 auto-cols-max divide-x divide-slate-400/25'>
            <form className="mt-6 col-span-2">
              <label className='block text-xl text-slate-800 font-semibold py-6'>Dados do Cliente</label>
              <div className='grid grid-cols-2'>
              <div className=''>
                <SingleInput label="Razão Social"
                name="rsocial"
                type="text"
                value={rsocial}
                onChange={(v) => setRsocial(v.target.value)}
                disabled />
                <SingleInput label="Nome Fantasia"
                name="nomefantasia"
                type="text"
                value={nomefantasia}
                onChange={(v) => setNomeFantasia(v.target.value)} 
                disabled />
                <SingleInput label="CNPJ"
                name="cnpj"
                type="text"
                value={cnpj}
                disabled />
                <SingleInput label="E-mail"
                name="email"
                type="email"
                value={email}
                disabled />
                <SingleInput label="Telefone"
                name="telefone"
                type="tel"
                value={telefone}
                disabled />
              </div>
              <div className='pl-6'>
              <SingleInput label="CEP"
                name="cep"
                type="text"
                value={cep}
                disabled />
                <SingleInput label="Endereço"
                name="endereco"
                type="text"
                value={endereco}
                disabled />
                <SingleInput label="Número"
                name="numero"
                type="text"
                value={numero}
                disabled />
                <SingleInput label="Complemento"
                name="complemento"
                type="text"
                value={complemento}
                disabled />
                <SingleInput label="Bairro"
                name="bairro"
                type="text"
                value={bairro}
                disabled />
                <SingleInput label="Estado"
                name="estado"
                type="text"
                value={estado}
                disabled />
                <SingleInput label="Cidade"
                name="cidade"
                type="text"
                value={cidade}
                disabled />
              </div>
              </div>
            </form>
            
          </div>
          <button
              className="my-5 group relative w-2/5 flex justify-center py-2 px-4 border border-blue-700 duration-200 text-sm font-medium rounded-md text-blue-700 bg-transparent hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2"
              onClick={openModal}
          >
              Vincular usuário a essa empresa
          </button>
          {showModal && <Modal closeModal={closeModal} conteudo={FormVincularUsuario}/>}
        </div>
        {(usuarios && usuarios.length > 0) ? (
            <UsuariosDaEmpresa conteudo={usuarios} cnpj={cnpj}/>
        ) : (loading) ? 
            'Carregando...' : 
            'Nenhum usuário vinculado à essa empresa.'
        }
      </div>
    </>
    
  )
}