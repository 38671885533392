import React from "react"
import EmpresasCadastradas from "../EmpresasCadastradas"

export default function Empresas(){
    return(
        <>
            <div className="grid grid-cols-1 gap-6 mx-20 bg-white rounded-lg p-5">
                <div className="bg-white">
                    <span className='block text-xl text-slate-800 font-semibold'>Empresas Cadastradas</span>
                    <span className='block text-sm mb-8'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pretium finibus euismod. Praesent mollis ex ut est facilisis malesuada. </span>
                </div>
                <div>
                    <EmpresasCadastradas />
                </div>
            </div>
        </>
    )
}